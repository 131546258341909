import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Svg from '../Svg';
import { Tab } from 'semantic-ui-react';
import { CarouselWrapper, ArrowContainer, TabContainer } from './Carousel.style';

const propTypes = {
    children: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const Carousel = ({
    children
}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);
    const handlePrev = () => setActiveIndex(activeIndex - 1);
    const handleNext = () => setActiveIndex(activeIndex + 1);

    const panes = children.map((item, i) => ({
        menuItem: {
            key: i,
            content: <Svg type="dot-hover" />
        },
        render: () => <Tab.Pane attached={false}>{item}</Tab.Pane>
    }));

    return <CarouselWrapper>
        <ArrowContainer>
            {activeIndex > 0
                ? <div onClick={handlePrev}><Svg type="chevron-left" /></div>
                : null}
        </ArrowContainer>
        <TabContainer>
            <Tab
                menu={{ text: true, attached: 'bottom' }}
                panes={panes}
                activeIndex={activeIndex}
                onTabChange={handleTabChange}
            />
        </TabContainer>
        <ArrowContainer>
            {activeIndex < children.length - 1
                ? <div onClick={handleNext}><Svg type="chevron-right" /></div>
                : null}
        </ArrowContainer>
    </CarouselWrapper>;
};

Carousel.propTypes = propTypes;