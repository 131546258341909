import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

export default class ReservationWarningModal extends React.Component {

    render() {
        return (
            <Modal open={this.props.open} size='small'
                closeOnDimmerClick={true} onClose={this.props.onToggleWarningModal}>
                <Modal.Header>
                    Попередження
                </Modal.Header>
                <Modal.Content>
                    <div className='ui message'>
                        Для початку роботи з даною транспортною компанією, будь-ласка, зверніться до служби підтримки
                        <a href="mailto:contact@pooling.me" className="header__email-link">contact@pooling.me</a>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.props.onToggleWarningModal}>Закрити</Button>
                </Modal.Actions>
            </Modal>
        );
    }
};