import styled from 'styled-components';

export const FooterWrapper = styled.div`
    background-color: white;
    width: 100%;
    position: relative;
    flex-shrink: 0;
    border-top: 1px solid #C4D6E9;
    display: flex;
    font-size: 1rem;
    color: black;
`;

export const Block = styled.div`
    padding: 20px 40px;
    height: 110px;
`;

export const ContactBlock = styled(Block)`
    padding: 17px 40px;
    font-size: 1.07rem;
    & a{
        color: inherit;
        text-decoration: none;
    }
    & a:hover{
        color: #135CA9;
    }
`;

export const Phone = styled.div`
    font-size: 1.3rem;
    margin-bottom: 15px;
`;

export const RightMenu = styled.div`
    margin-left: auto;
    display: flex;
    text-align: right;
`;

export const LogoBlock = styled(ContactBlock)`
    padding: 25px 40px;
    font-size: 1rem;
`;

export const Logo = styled.img`
    width: 100px;
    max-height: 34px;
    margin-bottom: 13px;
`;