import React from 'react';
import styled from 'styled-components';


const getRotateByDirection = (direction) => {
    switch (direction) {
        case 'up':
            return 180;
        case 'left':
            return 90;
        case 'right':
            return 270;
        case 'down':
        default:
            return 0;
    }
};

const Arrow = ({
    direction = 'up',
    width = 14, height = 10,
    color = '#135CA9'
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 14 10"
            fill="none"
            transform={`rotate(${getRotateByDirection(direction)})`}
        >
            <path
                d="M13.6675 1.14054L12.9936 0.376684C12.7601 0.125585 12.4874 0 12.1761 0C11.8584 0 11.5889 0.125585 11.3672 0.376684L7.00003 5.32596L2.63286 0.376826C2.41122 0.125728 2.14168 0.000142863 1.82408 0.000142863C1.51262 0.000142863 1.23996 0.125728 1.00643 0.376826L0.341457 1.14068C0.113777 1.39852 0 1.70751 0 2.06735C0 2.43391 0.113903 2.7394 0.341425 2.98376L6.19123 9.61308C6.40698 9.87096 6.67642 10 7 10C7.31751 10 7.59013 9.87099 7.81759 9.61308L13.6674 2.98376C13.8891 2.73252 14 2.42706 14 2.06735C14 1.71421 13.8891 1.4054 13.6675 1.14054Z"
                fill={color}
            />
        </svg>
    );
};


const Dot = ({
    width = 11, height = 11,
    color = '#475566', ...rest
}) => {
    return (
        <svg {...rest}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 13 13"
            fill="none"
        >
            <circle
                cx="6.5"
                cy="6.5"
                r="6.5"
                fill={color}
            />
        </svg>
    );
};

const HoverDot = styled(({ ...rest }) => <Dot {...rest} color="#C4D6E9" />)` 
    &:hover > circle {
        fill: #135CA9;
    }
    .item.active & > circle{
        fill: #135CA9;
    }
`;

const CheckMark = ({
    width = 15, height = 13,
    color = '#135CA9'
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 15 13"
            fill="none"
        >
            <path
                d="M0.173077 7.07693C0.0576923 6.96154 0 6.78847 0 6.67308C0 6.5577 0.0576923 6.38462 0.173077 6.26924L0.980769 5.46154C1.21154 5.23077 1.55769 5.23077 1.78846 5.46154L1.84615 5.51924L5.01923 8.92308C5.13462 9.03847 5.30769 9.03847 5.42308 8.92308L13.1538 0.903851H13.2115C13.4423 0.673082 13.7885 0.673082 14.0192 0.903851L14.8269 1.71154C15.0577 1.94231 15.0577 2.28847 14.8269 2.51924L5.59615 12.0962C5.48077 12.2115 5.36538 12.2692 5.19231 12.2692C5.01923 12.2692 4.90385 12.2115 4.78846 12.0962L0.288462 7.25L0.173077 7.07693Z"
                fill={color}
            />
        </svg>
    );
};

const Cross = ({
    width = 20, height = 20,
    color = '#135CA9'
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.77471 4.12483L4.12479 5.77475L8.24958 9.89954L4.12479 14.0243L5.7747 15.6742L9.89949 11.5495L14.0243 15.6742L15.6742 14.0243L11.5494 9.89954L15.6742 5.77475L14.0243 4.12483L9.8995 8.24962L5.77471 4.12483Z"
                fill={color}
            />
        </svg>
    );
};

const Minus = ({
    width = 14, height = 4,
    color = '#135CA9'
}) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 14 4'
            fill='none'
        >
            <path
                d='M14 0.833314V3.16665L0 3.16665L1.01993e-07 0.833313L14 0.833314Z'
                fill={color}
            />
        </svg>
    );
};

const Plus = ({
    width = 14, height = 14,
    color = '#135CA9'
}) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 14 14'
            fill='none'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.16667 0H5.83333V5.83333H0V8.16667H5.83333V14H8.16667V8.16667H14V5.83333H8.16667V0Z'
                fill={color}
            />
        </svg>
    );
};

const Loader = ({
    width = 10, height = 10,
    color = '#135CA9'
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 10 10"
            fill="none"
        >
            <path
                d="M10 5.16131C9.98713 4.48736 9.84091 3.81553 9.57086 3.20122C9.30171 2.58637 8.91105 2.02941 8.43146 1.56916C7.95215 1.10857 7.38373 0.744452 6.76851 0.504248C6.15362 0.263115 5.49246 0.147928 4.83931 0.161901C4.18606 0.174828 3.53572 0.316857 2.941 0.578736C2.34583 0.839764 1.80665 1.21849 1.36121 1.68321C0.915459 2.14769 0.56332 2.69829 0.331224 3.2939C0.0982577 3.88919 -0.012768 4.52881 0.00116581 5.16131C0.014132 5.79391 0.15198 6.42275 0.405693 6.99785C0.658573 7.57336 1.02534 8.09478 1.47523 8.52537C1.92487 8.95631 2.45769 9.29649 3.03366 9.52042C3.60938 9.74522 4.22752 9.85205 4.83931 9.83817C5.45124 9.82519 6.05854 9.69148 6.61408 9.44593C7.16996 9.2012 7.67357 8.84641 8.08934 8.41133C8.50548 7.97649 8.83366 7.4615 9.04944 6.90517C9.18085 6.56791 9.26999 6.21576 9.31697 5.85893C9.32953 5.85965 9.34217 5.86015 9.35491 5.86015C9.71117 5.86015 9.99998 5.57135 9.99998 5.21507C9.99998 5.19696 9.99904 5.17906 9.99759 5.16133L10 5.16131ZM8.97499 6.87429C8.73839 7.41055 8.39558 7.89633 7.97534 8.29732C7.55533 8.69854 7.0581 9.01484 6.52144 9.22251C5.98491 9.43094 5.40977 9.52942 4.83933 9.51562C4.2688 9.50252 3.70442 9.37717 3.18811 9.14798C2.67155 8.91951 2.20351 8.58867 1.81737 8.18328C1.43093 7.77807 1.12665 7.29865 0.927129 6.78163C0.726849 6.26468 0.632524 5.71102 0.646284 5.16127C0.659405 4.61141 0.78061 4.06853 1.00162 3.57183C1.22193 3.07491 1.54084 2.62465 1.93141 2.25333C2.32179 1.88173 2.78343 1.58941 3.28079 1.39805C3.77809 1.20591 4.31025 1.11575 4.83933 1.12949C5.36849 1.14265 5.8899 1.2597 6.367 1.47254C6.84428 1.6847 7.27673 1.99165 7.63322 2.36742C7.99001 2.74295 8.27035 3.18683 8.4536 3.66452C8.63752 4.14222 8.72353 4.6529 8.70985 5.16129H8.71226C8.71079 5.17902 8.70985 5.19692 8.70985 5.21503C8.70985 5.54776 8.96178 5.82154 9.28524 5.85632C9.2225 6.20734 9.11863 6.54999 8.97499 6.87429Z"
                fill={color}
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 5 5"
                    to="360 5 5"
                    dur="1.5s"
                    repeatCount="indefinite"
                />
            </path>
        </svg>
    );
};

const DropdownArrow = ({
    direction = 'up',
    width = 10, height = 6,
    color = '#475566'
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 10 6"
            fill="none"
            transform={`rotate(${getRotateByDirection(direction)})`}
            style={{ 'msTransform': `rotate(${getRotateByDirection(direction)}deg)` }}
        >
            <path
                d="M9.76246 0.68432L9.28117 0.226009C9.11438 0.0753495 8.91958 -1.55955e-06 8.69722 -1.52067e-06C8.47027 -1.48099e-06 8.27779 0.0753496 8.11946 0.226009L5.00002 3.19558L1.88061 0.226095C1.7223 0.0754363 1.52977 8.54506e-05 1.30291 8.54902e-05C1.08044 8.55291e-05 0.885688 0.0754365 0.718878 0.226096L0.243898 0.684408C0.0812693 0.839112 1.7913e-07 1.0245 2.16881e-07 1.24041C2.55335e-07 1.46035 0.0813594 1.64364 0.243875 1.79025L4.4223 5.76785C4.57641 5.92257 4.76887 6 5 6C5.22679 6 5.42152 5.92259 5.58399 5.76785L9.76244 1.79025C9.92078 1.63951 10 1.45623 10 1.24041C10 1.02853 9.9208 0.84324 9.76246 0.68432Z"
                fill={color}
            />
        </svg>
    );
};

const DropdownCross = ({
    width = 10, height = 10,
    color = '#475566'
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 10 10"
            fill="none"
        >
            <path
                d="M9.28116 0.226012L9.76246 0.684323C9.9208 0.843243 10 1.02853 10 1.24041C10 1.45624 9.92077 1.63951 9.76244 1.79026L6.81313 4.59779L9.76244 7.40533C9.92078 7.55607 10 7.73935 10 7.95517C10 8.16705 9.9208 8.35234 9.76247 8.51126L9.28117 8.96957C9.11438 9.12023 8.91958 9.19558 8.69722 9.19558C8.47027 9.19558 8.27779 9.12023 8.11946 8.96957L5 6L1.88061 8.96948C1.7223 9.12014 1.52977 9.1955 1.30292 9.1955C1.08044 9.1955 0.885689 9.12014 0.718879 8.96949L0.243898 8.51117C0.0812703 8.35647 1.00741e-06 8.17108 1.01874e-06 7.95517C1.03027e-06 7.73524 0.0813609 7.55194 0.243877 7.40533L3.19317 4.59779L0.243876 1.79026C0.0813603 1.64364 4.05741e-07 1.46035 4.17278e-07 1.24042C4.28603e-07 1.02451 0.0812697 0.839115 0.243898 0.684411L0.718878 0.226099C0.885688 0.0754391 1.08044 8.83458e-05 1.30292 8.83782e-05C1.52977 8.84112e-05 1.7223 0.0754392 1.88061 0.226099L5.00002 3.19558L8.11945 0.226012C8.27779 0.0753524 8.47027 1.68446e-06 8.69722 1.71753e-06C8.91958 1.74993e-06 9.11438 0.0753525 9.28116 0.226012Z"
                fill={color}
            />
        </svg>

    );
};

const CheckboxMark = ({
    width = 10, height = 8,
    color = '#475566'
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 10 8"
            fill="none"
        >
            <path
                d="M9.81936 1.22575L8.94196 0.348344C8.82165 0.227946 8.67533 0.167725 8.50334 0.167725C8.3312 0.167725 8.18488 0.227946 8.06457 0.348344L3.83226 4.58706L1.93547 2.68381C1.81503 2.56337 1.6688 2.50321 1.49681 2.50321C1.32471 2.50321 1.17849 2.56337 1.05805 2.68381L0.180642 3.56123C0.0601989 3.68166 0 3.82793 0 4C0 4.17195 0.0601989 4.31835 0.180642 4.43875L2.51608 6.77415L3.39355 7.65155C3.51393 7.77204 3.6602 7.83219 3.83226 7.83219C4.00424 7.83219 4.15052 7.77188 4.27096 7.65155L5.14841 6.77415L9.81936 2.10322C9.93969 1.98278 10 1.83653 10 1.66445C10.0001 1.49246 9.93969 1.34619 9.81936 1.22575Z"
                fill={color}
            />
        </svg>
    );
};

const Refresh = ({
    width = 12, height = 16,
    color = '#135CA9'
}) => {

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 12 16'
            fill='none'
        >
            <path
                d='M6.00007 2.18182V0L3.09098 2.90909L6.00007 5.81818V3.63636C8.40007 3.63636 10.3637 5.6 10.3637 8C10.3637 8.72727 10.1455 9.45455 9.85461 10.0364L10.9455 11.1273C11.4546 10.1818 11.8182 9.16364 11.8182 8C11.8182 4.8 9.20007 2.18182 6.00007 2.18182ZM6.00007 12.3636C3.60007 12.3636 1.63643 10.4 1.63643 8C1.63643 7.27273 1.85461 6.54545 2.14552 5.96364L1.05461 4.87273C0.545521 5.81818 0.181885 6.83636 0.181885 8C0.181885 11.2 2.80007 13.8182 6.00007 13.8182V16L8.90916 13.0909L6.00007 10.1818V12.3636Z'
                fill={color}
            />
        </svg>
    );
};

const ButtonArrowLeft = ({
    width = 8, height = 12,
    color = '#135CA9'
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 8 12"
            fill="none"
        >
            <path
                d="M7.17881 11.715L7.72879 11.1374C7.90958 10.9373 8 10.7035 8 10.4367C8 10.1643 7.90958 9.93335 7.72879 9.74335L4.16531 6.00003L7.72868 2.25673C7.90948 2.06676 7.9999 1.83573 7.9999 1.5635C7.9999 1.29653 7.90948 1.06283 7.72868 0.862654L7.17871 0.292677C6.99307 0.0975229 6.7706 -1.22138e-07 6.5115 -1.47878e-07C6.24759 -1.74098e-07 6.02763 0.0976307 5.85169 0.29265L1.07858 5.30676C0.892911 5.49169 0.8 5.72265 0.8 6C0.8 6.27215 0.892885 6.50582 1.07858 6.70079L5.85169 11.7149C6.03259 11.9049 6.25252 12 6.5115 12C6.76577 12 6.98811 11.905 7.17881 11.715Z"
                fill={color}
            />
        </svg>
    );
};

const ButtonArrowRight = ({
    width = 8, height = 12,
    color = '#135CA9'
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 8 12"
            fill="none"
        >
            <path
                d="M7.17881 11.715L7.72879 11.1374C7.90958 10.9373 8 10.7035 8 10.4367C8 10.1643 7.90958 9.93335 7.72879 9.74335L4.16531 6.00003L7.72868 2.25673C7.90948 2.06676 7.9999 1.83573 7.9999 1.5635C7.9999 1.29653 7.90948 1.06283 7.72868 0.862654L7.17871 0.292677C6.99307 0.0975229 6.7706 -1.22138e-07 6.5115 -1.47878e-07C6.24759 -1.74098e-07 6.02763 0.0976307 5.85169 0.29265L1.07858 5.30676C0.892911 5.49169 0.8 5.72265 0.8 6C0.8 6.27215 0.892885 6.50582 1.07858 6.70079L5.85169 11.7149C6.03259 11.9049 6.25252 12 6.5115 12C6.76577 12 6.98811 11.905 7.17881 11.715Z"
                transform='rotate(180 4 6)'
                fill={color}
            />
        </svg>
    );
};

const Calendar = ({
    width = 13, height = 13,
    color = '#475566'
}) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 13 13'
            fill='none'
        >
            <g>
                <path
                    d='M4.73637 7.17198C4.73637 7.00913 4.60428 6.8772 4.4413 6.8772H3.41161C3.24882 6.8772 3.1167 7.00913 3.1167 7.17198V8.20153C3.1167 8.36459 3.24882 8.49658 3.41161 8.49658H4.4413C4.60428 8.49658 4.73637 8.36459 4.73637 8.20153V7.17198Z'
                    fill={color}
                />
                <path
                    d='M7.3097 7.17198C7.3097 7.00913 7.17758 6.8772 7.01492 6.8772H5.9851C5.8223 6.8772 5.69019 7.00913 5.69019 7.17198V8.20153C5.69019 8.36459 5.8223 8.49658 5.9851 8.49658H7.01492C7.17758 8.49658 7.3097 8.36459 7.3097 8.20153V7.17198Z'
                    fill={color}
                />
                <path
                    d='M9.8831 7.17198C9.8831 7.00913 9.75098 6.8772 9.58819 6.8772H8.5585C8.39552 6.8772 8.26343 7.00913 8.26343 7.17198V8.20153C8.26343 8.36459 8.39552 8.49658 8.5585 8.49658H9.58819C9.75098 8.49658 9.8831 8.36459 9.8831 8.20153V7.17198Z'
                    fill={color}
                />
                <path
                    d='M4.73637 9.74562C4.73637 9.58251 4.60428 9.45068 4.4413 9.45068H3.41161C3.24882 9.45068 3.1167 9.58251 3.1167 9.74562V10.775C3.1167 10.9379 3.24882 11.0699 3.41161 11.0699H4.4413C4.60428 11.0699 4.73637 10.9379 4.73637 10.775V9.74562Z'
                    fill={color}
                />
                <path
                    d='M7.3097 9.74562C7.3097 9.58251 7.17758 9.45068 7.01492 9.45068H5.9851C5.8223 9.45068 5.69019 9.58251 5.69019 9.74562V10.775C5.69019 10.9379 5.8223 11.0699 5.9851 11.0699H7.01492C7.17758 11.0699 7.3097 10.9379 7.3097 10.775V9.74562Z'
                    fill={color}
                />
                <path
                    d='M9.8831 9.74562C9.8831 9.58251 9.75098 9.45068 9.58832 9.45068H8.5585C8.39552 9.45068 8.26343 9.58251 8.26343 9.74562V10.775C8.26343 10.9379 8.39552 11.0699 8.5585 11.0699H9.58832C9.75098 11.0699 9.8831 10.9379 9.8831 10.775V9.74562Z'
                    fill={color}
                />
                <path
                    d='M11.7244 1.4473V3.01986C11.7244 3.73064 11.1478 4.30342 10.4371 4.30342H9.62502C8.91429 4.30342 8.33003 3.73064 8.33003 3.01986V1.44165H4.66986V3.01986C4.66986 3.73064 4.08563 4.30342 3.375 4.30342H2.56278C1.85213 4.30342 1.27554 3.73064 1.27554 3.01986V1.4473C0.654427 1.46602 0.144043 1.97997 0.144043 2.61169V11.8277C0.144043 12.4712 0.665692 12.9998 1.30928 12.9998H11.6906C12.3332 12.9998 12.8558 12.4702 12.8558 11.8277V2.61169C12.8558 1.97997 12.3455 1.46602 11.7244 1.4473ZM11.3472 11.2538C11.3472 11.5319 11.1217 11.7575 10.8434 11.7575H2.13426C1.85602 11.7575 1.63051 11.5319 1.63051 11.2538V6.49338C1.63051 6.21513 1.85599 5.98949 2.13426 5.98949H10.8434C11.1217 5.98949 11.3472 6.21513 11.3472 6.49338L11.3472 11.2538Z'
                    fill={color}
                />
                <path
                    d='M2.5599 3.46057H3.36314C3.60695 3.46057 3.80463 3.26318 3.80463 3.01937V0.441356C3.80463 0.197522 3.60695 0 3.36314 0H2.5599C2.31606 0 2.11841 0.197522 2.11841 0.441356V3.01937C2.11841 3.26318 2.31606 3.46057 2.5599 3.46057Z'
                    fill={color}
                />
                <path
                    d='M9.6148 3.46057H10.418C10.6617 3.46057 10.8594 3.26318 10.8594 3.01937V0.441356C10.8594 0.197522 10.6617 0 10.418 0H9.6148C9.37102 0 9.17334 0.197522 9.17334 0.441356V3.01937C9.17334 3.26318 9.37102 3.46057 9.6148 3.46057Z'
                    fill={color}
                />
            </g>
        </svg>
    );
};

const ExclamationMark = ({
    width = 32, height = 32,
    color = '#475566'
}) => {

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 32 32'
            fill='none'
        >
            <path
                d='M16 0C7.16331 0 0 7.16356 0 16C0 24.8369 7.16331 32 16 32C24.8367 32 32 24.8369 32 16C32 7.16356 24.8367 0 16 0ZM16 24C14.8954 24 14 23.105 14 22C14 20.8955 14.8954 20 16 20C17.1046 20 18 20.8955 18 22C18 23.105 17.1046 24 16 24ZM18 17H14L13 9C13 8.44775 13.4478 8 14 8H18C18.5522 8 19 8.44775 19 9L18 17Z'
                fill={color}
            />
        </svg>
    );
};

const ChevronLeft = styled(({
    width = 22, height = 40,
    color = '#C4D6E9', ...rest
}) => {
    return (
        <svg {...rest} width={width} height={height} viewBox="0 0 22 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.17062 19.999L21.2353 1.9343C21.6795 1.49001 21.6795 0.777487 21.2353 0.333206C20.791 -0.111076 20.0785 -0.111076 19.6342 0.333206L0.764794 19.2026C0.320513 19.6469 0.320513 20.3594 0.764794 20.8037L19.6342 39.6647C19.8521 39.8826 20.1455 40 20.4305 40C20.7156 40 21.0089 39.891 21.2269 39.6647C21.6712 39.2204 21.6712 38.5079 21.2269 38.0636L3.17062 19.999Z"
                fill={color} />
        </svg>
    );
})`
    &:hover path {
        fill: #135CA9;
    }
`;

const ChevronRight = styled(({
    width = 22, height = 40,
    color = '#C4D6E9', ...rest
}) => {
    return (
        <svg {...rest} width={width} height={height} viewBox="0 0 22 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.8293 20.001L0.764671 38.0657C0.320391 38.51 0.320391 39.2225 0.764671 39.6668C1.20895 40.1111 1.92148 40.1111 2.36576 39.6668L21.2351 20.7974C21.6794 20.3531 21.6794 19.6406 21.2352 19.1963L2.36576 0.335306C2.14781 0.117354 1.85442 -1.72345e-06 1.56941 -1.74836e-06C1.2844 -1.77328e-06 0.991005 0.108973 0.773056 0.335306C0.328773 0.779585 0.328773 1.49211 0.773056 1.93639L18.8293 20.001Z"
                fill={color} />
        </svg>
    );
})`
    &:hover path {
        fill: #135CA9;
    }
`;

const Svg = ({
    type = 'arrow',
    ...rest
}) => {
    switch (type) {
        case 'arrow': return <Arrow {...rest} />;
        case 'dot': return <Dot {...rest} />;
        case 'dot-hover': return <HoverDot {...rest} />;
        case 'check': return <CheckMark {...rest} />;
        case 'cross': return <Cross {...rest} />;
        case 'minus': return <Minus {...rest} />;
        case 'plus': return <Plus {...rest} />;
        case 'loader': return <Loader {...rest} />;
        case 'dropdown-arrow': return <DropdownArrow {...rest} />;
        case 'dropdown-cross': return <DropdownCross {...rest} />;
        case 'checkbox-mark': return <CheckboxMark {...rest} />;
        case 'refresh': return <Refresh {...rest} />;
        case 'button-arrow-left': return <ButtonArrowLeft {...rest} />;
        case 'button-arrow-right': return <ButtonArrowRight {...rest} />;
        case 'calendar': return <Calendar {...rest} />;
        case 'exclamation': return <ExclamationMark {...rest} />;
        case 'chevron-left': return <ChevronLeft {...rest} />;
        case 'chevron-right': return <ChevronRight {...rest} />;
        default: return null;
    }
};

export default Svg;