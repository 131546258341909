import './PersonalPage.style.css';

import React, { useState, useEffect, useContext } from 'react';
import { callApiAsync } from '../../hooks';
import { ProfileContext as Profile, UserContext as UserData } from '../../context';

import Tabs from '../../components/Tabs';

import BookingTab from './Tabs/Booking/BookingTab';
import MailingTab from './Tabs/Mailing/MailingTab';
import ReservationFormTab from './Tabs/ReservationForm/ReservationFormTab';

const useProfileData = () => {
    const [state, setState] = useState({
        name: '',
        type: '',
        company: '',
        email: '',
        phoneNumber: '',
        receiveConfirmations: false
    });

    const loadData = async () => {
        let data = await callApiAsync('api/profile/info', 'GET');
        setState(data);
    };

    useEffect(() => { loadData(); }, []);

    return [state, loadData];
};

const useWarehousesData = () => {
    const [array, setArray] = useState([]);

    const loadData = async () => {
        let data = await callApiAsync('api/profile/WarehouseList', 'POST', '');
        setArray(data);
    };

    useEffect(() => { loadData(); }, []);

    return [array, setArray];
};

const PersonalPage = () => {
    const [activeTab, setActiveTab] = useState('user');
    const [profile, updateProfile] = useProfileData();
    const [warehouses] = useWarehousesData();

    const userData = useContext(UserData);

    const renderUsers = () => {
        const warehouseList = () => {
            if (warehouses.length < 1 || userData.getRole() !== 'Вантажовідправник')
                return null;

            return (
                <>
                    <p className='subhead-text'>Склади</p>
                    {warehouses.map((w, index) => (
                        <React.Fragment key={index} >
                            <p><b>{w.name}</b></p>
                            <p>{w.address}</p>
                        </React.Fragment>
                    ))}
                </>
            );
        };

        return (
            <div className='pooling__user-tab'>
                <p className='subhead-text'>{profile.name}</p>
                <p>Роль: {profile.type}</p>
                <p>Компанія: {profile.company}</p>
                <p>Email: {profile.email}</p>
                <p>Телефон: {profile.phoneNumber}</p>
                <br />
                {warehouseList()}
            </div>
        );
    };

    return (<Profile.Provider value={{ profile: profile, updateProfile: updateProfile }}>
        <div className='white-container'>
            <p style={{ paddingLeft: '25px' }} className='head-text'>Особистий кабінет</p>
            <Tabs
                activeTab={activeTab}
                onTabChange={setActiveTab}
                style={{ padding: '0 12px' }}
            >
                <Tabs.Pane key='user' caption='Користувач'>
                    {renderUsers()}
                </Tabs.Pane>
                {userData.getRole() === 'Вантажовідправник'
                    ? <Tabs.Pane key='mailing' caption='Управління розсилкою'>
                        <MailingTab />
                    </Tabs.Pane>
                    : null
                }
                {userData.getRole() === 'Вантажовідправник' || (userData.getRole() === 'Перевізник' && userData.hasPermission('ChangeBookingRules'))
                    ? <Tabs.Pane key='booking' caption='Правила бронювання'>
                        <BookingTab />
                    </Tabs.Pane>
                    : null
                }
                {userData.getRole() === 'Вантажовідправник'
                    ? <Tabs.Pane key='reservation-form' caption='Форма бронювання'>
                        <ReservationFormTab />
                    </Tabs.Pane>
                    : null
                }
            </Tabs>
        </div>
    </Profile.Provider>);
};

export default PersonalPage;