import {
    format as _format,
    startOfDay as _startOfDay,
    getDay as _getDay,
    isToday as _isToday,
    parse as _parse,
    addHours as _addHours,
    addDays as _addDays,
    addWeeks as _addWeeks,
    subDays as _subDays,
    setDay as _setDay,
    setHours as _setHours,
    setMinutes as _setMinutes,
    startOfWeek as _startOfWeek,
    startOfHour as _startOfHour,
    endOfDay as _endOfDay,
    endOfWeek as _endOfWeek,
} from 'date-fns';

import ukLocale from 'date-fns/locale/uk';

const locale = ukLocale;

const checkDate = (date) => {
    if (date instanceof Date) return date;

    return new Date(date);
};

export const format = (date, formatString, options = {}) => {
    return date && _format(checkDate(date), formatString, { ...options, locale });
};
export const formatDateISO = date => format(date, "yyyy-MM-dd");

export const startOfHour = date => date && _startOfHour(checkDate(date));

export const startOfDay = date => date && _startOfDay(checkDate(date));
export const endOfDay = date => date && _endOfDay(checkDate(date));

export const startOfWeek = (date, options) => date && _startOfWeek(checkDate(date), { weekStartsOn: 1, ...options });
export const endOfWeek = (date, options) => date && _endOfWeek(checkDate(date), { weekStartsOn: 1, ...options });

export const setDay = (date, day, options = {}) => {
    return date && _setDay(checkDate(date), day, { weekStartsOn: 1, ...options });
};

export const getDay = date => date && _getDay(checkDate(date));

export const setHours = (date, number) => date && _setHours(checkDate(date), number);
export const setMinutes = (date, number) => date && _setMinutes(checkDate(date), number);

export const isToday = (date) => date && _isToday(checkDate(date));

export const addHours = (date, number) => date && _addHours(checkDate(date), number);

export const addDays = (date, number) => date && _addDays(checkDate(date), number);
export const subDays = (date, number) => date && _subDays(checkDate(date), number);

export const addWeeks = (date, number) => date && _addWeeks(checkDate(date), number);

export const parse = (str, format, options = {}) => {
    return str && _parse(str, format, new Date(), { locale, ...options });
};
export const parseDateISO = str =>
    parse(str, "yyyy-MM-dd");

export const isBetween = (date, start, end) => date >= start && date <= end;

export const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;

    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

export const getDayName = date => _format(checkDate(date), "eeee", { locale });

export const startOfNextHour = date => addHours(startOfHour(date), 1);
