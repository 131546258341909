import React, { useState, useEffect } from 'react';

import { FormField, Input, Popup, Select } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';

import { t } from '../utilities/utilities';

import TimeInput from '../../../components/TimeInputSemantic';
import { StyledInputContainer } from '../ReservationOrdersDetails.style';

export const AmountInput = ({
    size,
    labeled,
    label,
    name,
    error,
    value,
    onChange,
    onPaste
}) =>
    (
        <StyledInputContainer {...(size ? { size } : {})}>
            <FormField
                error={error}
            >
                {labeled && (
                    <label>{label}</label>
                )}
                <Input
                    name={name}
                    fluid
                    placeholder="0"
                    error={error}
                    value={value}
                    onChange={onChange}
                    onPaste={onPaste}
                />
            </FormField>
        </StyledInputContainer>
    );

export const PalletsCount = ({
    disable,
    overflowed,
    labeled,
    name,
    error,
    value,
    onChange
}) => {
    const defaultOpen = !!overflowed;
    const [open, setOpen] = useState(defaultOpen);

    useEffect(
        () => {
            setOpen(defaultOpen);
        },
        [overflowed]
    );

    return disable
        ? (
            <Popup
                content={overflowed
                    ? t('reservation-pallets-details-pallets-count-overflow')
                    : t('reservation-pallets-details-pallets-count-disabled')
                }
                on={['click', 'hover']}
                eventsEnabled={!defaultOpen}
                open={open}
                onClose={() => !defaultOpen && setOpen(false)}
                onOpen={() => setOpen(true)}
                {...(overflowed
                    ? {
                        className: "error",
                    } : {}
                )}
                trigger={(
                    <StyledInputContainer size="m">
                        <FormField
                            className="disabled"
                            error={error}
                        >
                            {labeled && (
                                <label>{t('reservation-pallets-details-pallets-count')}</label>
                            )}
                            <Input
                                name="palletCount"
                                fluid
                                disabled
                                placeholder="0"
                                error={error}
                                value={value}
                            />
                        </FormField>
                    </StyledInputContainer>
                )}
            />
        ) : (
            <AmountInput
                size="m"
                error={error}
                labeled={labeled}
                label={t('reservation-pallets-details-pallets-count')}
                name={name}
                value={value}
                onChange={onChange}
            />
        );
};

export const BoxesCount = ({
    disable,
    labeled,
    name,
    error,
    value,
    onChange
}) =>
    disable
        ? (
            <Popup
                content={t('reservation-pallets-details-box-count-disabled')}
                wide
                trigger={(
                    <StyledInputContainer size="m">
                        <FormField
                            error={error}
                            className="disabled"
                        >
                            {labeled && (
                                <label>{t('reservation-pallets-details-boxes-count')}</label>
                            )}
                            <Input
                                name={name}
                                fluid
                                disabled
                                placeholder="0"
                                error={error}
                                value={value}
                            />
                        </FormField>
                    </StyledInputContainer>
                )}
            />
        ) : (
            <AmountInput
                size="m"
                error={error}
                labeled={labeled}
                label={t('reservation-pallets-details-boxes-count')}
                name={name}
                value={value}
                onChange={onChange}
            />
        );

export const Cost = ({
    disable,
    labeled,
    name,
    error,
    value,
    onChange
}) =>
    disable
        ? (
            <Popup
                content={t('reservation-pallets-details-cost-disabled')}
                wide
                trigger={(
                    <StyledInputContainer size="m">
                        <FormField
                            className="disabled"
                            error={error}
                        >
                            {labeled && (
                                <label>{t('reservation-pallets-details-cost')}</label>
                            )}
                            <Input
                                name={name}
                                fluid
                                placeholder="0"
                                disabled
                                error={error}
                                value={value}
                            />
                        </FormField>
                    </StyledInputContainer>
                )}
            />
        ) : (
            <AmountInput
                size="m"
                error={error}
                labeled={labeled}
                label={t('reservation-pallets-details-cost')}
                name={name}
                value={value}
                onChange={onChange}
            />
        );

export const Weight = ({
    disable,
    labeled,
    totalOverweight,
    palletOverweight,
    name,
    error,
    value,
    onChange
}) => {
    const defaultOpen = !!(palletOverweight || totalOverweight);
    const [open, setOpen] = useState(defaultOpen);

    useEffect(
        () => {
            setOpen(defaultOpen);
        },
        [palletOverweight, totalOverweight]
    );

    const popupContent = totalOverweight || palletOverweight
        ? totalOverweight
            ? t('reservation-pallets-details-too-heavy')
            : t('reservation-pallets-details-too-heavy-pallet')
        : disable
            ? t('reservation-pallets-details-weight-disabled')
            : '';

    return disable
        ? (
            <Popup
                className={defaultOpen ? 'error' : ''}
                content={popupContent}
                wide
                on={['click', 'hover']}
                eventsEnabled={!(defaultOpen)}
                open={open}
                popperDependencies={[palletOverweight, totalOverweight]}
                onClose={() => !defaultOpen && setOpen(false)}
                onOpen={() => setOpen(true)}
                trigger={(
                    <StyledInputContainer size="m">
                        <FormField
                            className="disabled"
                            error={error}
                        >
                            {labeled && (
                                <label>{t('reservation-pallets-details-weight')}</label>
                            )}
                            <Input
                                name={name}
                                fluid
                                disabled
                                placeholder="0"
                                error={error}
                                value={value}
                            />
                        </FormField>
                    </StyledInputContainer>
                )}
            />
        ) : (
            <Popup
                className="error"
                wide
                content={popupContent}
                popperDependencies={[palletOverweight, totalOverweight]}
                open={!!(totalOverweight || palletOverweight)}
                trigger={(
                    <StyledInputContainer size="m">
                        <FormField
                            error={error}
                        >
                            {labeled && (
                                <label>{t('reservation-pallets-details-weight')}</label>
                            )}
                            <Input
                                name={name}
                                fluid
                                placeholder="0"
                                error={error}
                                value={value}
                                onChange={onChange}
                            />
                        </FormField>
                    </StyledInputContainer>
                )}
            />
        );
};

export const TimeSlot = ({ labeled, error, value, onChange }) => (
    <StyledInputContainer size="s">
        <TimeInput
            {...(labeled ? { label: t('reservation-pallets-details-time') } : {})}
            name="timeSlot"
            fluid
            error={error}
            value={value}
            onChange={onChange}
        />
    </StyledInputContainer>
);

export const Comment = ({ labeled, value, onChange }) => (
    <StyledInputContainer size="m">
        <FormField
            className="ui form reservation-pallets-details__comment"
        >
            {labeled && (
                <label>
                    {t('reservation-pallets-details-comment')}
                </label>
            )}
            <TextareaAutosize
                className="reservation-pallets-details__textarea"
                useCacheForDOMMeasurements
                name="comment"
                placeholder={t('reservation-pallets-details-comment')}
                value={value}
                onChange={onChange}
            />
        </FormField>
    </StyledInputContainer>
);

export const PalletsFromTo = ({
    labeled,
    label,
    error,
    errorFrom,
    errorTo,
    palletFrom,
    palletTo,
    onChange,
    onPaste
}) =>
    (
        <StyledInputContainer mods="palletsNumbers" {...(error ? { className: 'error' } : {})}>
            {labeled && (
                <label>
                    {label}
                </label>
            )}
            <div className="reservation-pallets-details__inputs-container">
                <StyledInputContainer size="s">
                    <Input
                        name="palletFrom"
                        fluid
                        placeholder="_"
                        icon={
                            <i className="icon input-label-icon">
                                {t('reservation-pallets-details-pallet-from')}
                            </i>
                        }
                        iconPosition="left"
                        error={errorFrom}
                        value={palletFrom}
                        onChange={onChange}
                        onPaste={onPaste}
                    />
                </StyledInputContainer>
                <StyledInputContainer size="s">
                    <Input
                        className="reservation-pallets-details__input-container"
                        name="palletTo"
                        fluid
                        icon={
                            <i className="icon input-label-icon">
                                {t('reservation-pallets-details-pallet-to')}
                            </i>
                        }
                        iconPosition="left"
                        placeholder="_"
                        error={errorTo}
                        value={palletTo}
                        onChange={onChange}
                        onPaste={onPaste}
                    />
                </StyledInputContainer>
            </div>
        </StyledInputContainer>
    );


export const OrderNumber = ({ labeled, label, error, value, onChange, onPaste }) => (
    <StyledInputContainer size="l">
        <FormField>
            {labeled && (
                <label>{label}</label>
            )}
            <Input
                name="orderNumber"
                fluid
                placeholder=""
                error={error}
                value={value}
                onChange={onChange}
                onPaste={onPaste}
            />
        </FormField>
    </StyledInputContainer>
);

export const PackingList = ({ labeled, label, error, value, onChange, onPaste }) => (
    <StyledInputContainer size="l">
        <FormField>
            {labeled && (
                <label>{label}</label>
            )}
            <Input
                name="packingList"
                fluid
                placeholder=""
                error={error}
                value={value}
                onChange={onChange}
                onPaste={onPaste}
            />
        </FormField>
    </StyledInputContainer>
);

export const TypeSelect = ({ labeled, label, value, onChange }) => (
    <StyledInputContainer size="l">
        <FormField
            {...(labeled ? { label } : {})}
            control={Select}
            selectOnBlur={false}
            noResultsMessage={t('dropdownEmpty')}
            name="type"
            fluid
            clearable
            placeholder={t('reservation-pallets-details-type-0')}
            value={value}
            options={[
                { value: 1, text: t('reservation-pallets-details-type-1') },
                { value: 2, text: t('reservation-pallets-details-type-2') },
                { value: 3, text: t('reservation-pallets-details-type-3') },
                { value: 4, text: t('reservation-pallets-details-type-4') },
                { value: 5, text: t('reservation-pallets-details-type-5') },
                { value: 6, text: t('reservation-pallets-details-type-6') },
                { value: 7, text: t('reservation-pallets-details-type-7') },
                { value: 8, text: t('reservation-pallets-details-type-8') },
                { value: 9, text: t('reservation-pallets-details-type-9') }
            ]}
            onChange={onChange}
        />
    </StyledInputContainer>
);