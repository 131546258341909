import React, { useState, useContext } from 'react';
import { Button, Modal, Tab, Message, Grid, Form } from 'semantic-ui-react';
import RegistrationForm from './components/RegistrationForm';

import { UserContext as UserData } from '../../context';
import TextButton from '../../components/Button/TextButton';


const LoginForm = ({
    noButton,
    children,
    buttonStyle
}) => {
    const userData = useContext(UserData);
    const [state, setState] = useState({
        modal: false,
        error: '',
        login: '',
        password: '',
        activeTab: 'login',

        username: '',
        userRole: '',
        permissions: []
    });

    const login = async () => {
        if (!state.login || !state.password) {
            setState(prev => ({
                ...prev,
                error: 'Необхідно ввести ім\'я користувача і пароль'
            }));

            return;
        }

        let err = await userData.onLogin(state.login, state.password);
        if (err)
            setState(prev => ({
                ...prev,
                error: 'Невірний логін і пароль'
            }));
    };

    const toggle = () => {
        setState(prev => ({
            ...prev,
            activeTab: 'login',
            modal: !prev.modal
        }));
    };

    const onKeyPress = (e) => {
        if (e.charCode === 13) {
            e.preventDefault();

            login();
        }
    };

    const onFieldChange = (field) => (e) => {
        let value = e.target.value;
        setState(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const onFocus = () => {
        setState(prev => ({
            ...prev,
            error: ''
        }));
    };

    const panes = [
        {
            menuItem: 'Увійти', render: () => <Tab.Pane attached={false}>
                <Message>
                    <p>Якщо у вас немає логіна/пароля- будь-ласка, перейдіть на вкладку <b>Реєстрація</b>.</p>
                </Message>
                <Grid>
                    <Grid.Row centered>
                        <Grid.Column width={6}>
                            <Form>
                                <Form.Input
                                    label="Логін"
                                    type='input'
                                    onFocus={onFocus}
                                    onChange={onFieldChange('login')}
                                    onKeyPress={onKeyPress}
                                />
                                <Form.Input
                                    label="Пароль"
                                    type='password'
                                    onFocus={onFocus}
                                    onChange={onFieldChange('password')}
                                    onKeyPress={onKeyPress}
                                />
                                {state.error !== ''
                                    ? <div className='error'>{state.error}</div>
                                    : null
                                }
                            </Form>
                        </Grid.Column>
                    </Grid.Row>

                </Grid>

            </Tab.Pane>
        },
        { menuItem: 'Реєстрація', render: () => <RegistrationForm /> }
    ];

    return (
        <>
            <Modal closeIcon
                className='login-modal'
                open={state.modal}
                onClose={toggle}
                trigger={<TextButton size="small" style={buttonStyle} noButton={noButton} onClick={toggle}>
                    {children}
                </TextButton>}
            >
                <Modal.Header toggle={toggle}>Вхід в особистий кабінет</Modal.Header>
                <Modal.Content>
                    <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={toggle}>Відміна</Button>
                    <Button primary
                        onClick={login}
                    >
                        Увійти
                    </Button>

                </Modal.Actions>
            </Modal>
        </>
    );
};

export default LoginForm;