import React from 'react';
import styled from 'styled-components';

import Svg from './Svg';

const Wrapper = styled.div`
    background-color: #FFFFFF;
    border-radius: 25px;
    border: 1px solid ${props => props.borderColor};
    box-sizing: border-box;
    padding: 15px;
    color: #000000;

    display: flex;
`;

const IconHolder = styled.i`
    margin-right: 10px;
`;

const Text = styled.p`
    font-family: 'ClearSans', Arial;
    font-size: 14px;
    line-height: 19px;

    color: #000000;
`;


const Message = ({
    type,
    children
}) => {
    let color;

    switch (type) {
        case 'warning':
            color = '#F2D7AF';
            break;
        case 'error':
            color = '#990202';
            break;
        default:
            color = '#475566';
            break;
    }

    return (
        <Wrapper borderColor={color}>
            <IconHolder>
                <Svg
                    type='exclamation'
                    color={color}
                />
            </IconHolder>
            <Text>
                {children}
            </Text>
        </Wrapper>
    );
};

export default Message;