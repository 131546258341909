import './Calendar.style.css';

import React from 'react';
import Filter from './components/Filter';
import { isTander } from '../../utils';
import { Grid } from 'semantic-ui-react';
import LocalStorageService from "../../utils/localStorageService";

import { callApiAsync } from '../../hooks';
import { UserContext as UserData } from '../../context';
import Results from './components/Results';


export default class Calendar extends React.Component {

    state = {
        isLoading: true,
        weekDescription: 'Завантаження…',
        header: ['Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П\'ятниця', 'Субота', 'Неділя'],

        toLeftWeekNumber: '',
        toRightWeekNumber: '',
        toLeftYear: '',
        toRightYear: '',
        toCurrentWeekNumber: '',
        toCurrentWeekYear: '',

        clients: [],
        productTypes: [],
        transporters: [],
        shippingRegions: [],
        distributionCenters: [],

        slots: [],

        filter: {
            weekNumber: null,
            year: null,
            currentDistributionCenterIds: [],
            currentType: 'tent',
            currentClientId: '',
            currentProductType: '',
            currentTransporterId: '',
            currentShippingRegionId: '',
        },
        showedType: 'tent'
    };

    filterName = 'calendarFilter';

    componentDidMount() {
        let filter = LocalStorageService.getFilter(this.filterName);
        this.updateFilterValue(filter);
    }

    search = async (filter) => {
        //what a mess. must be refactored
        this.setState({ isLoading: true });
        if (!filter || Object.keys(filter).length === 0) {
            let savedFilter = LocalStorageService.getFilter(this.filterName);
            let newFilter = {};

            if (savedFilter !== null) {
                for (let name in this.state.filter) {
                    newFilter[name] = savedFilter[name] || this.state.filter[name];
                }

                filter = newFilter;
            } else {
                filter = null;
            }

        } else {
            filter = LocalStorageService.updateFilter(this.filterName, filter);
        }

        filter = filter ||
        {
            currentDistributionCenterIds: this.state.filter.currentDistributionCenterIds,
            currentType: this.state.filter.currentType,
            currentTransporterId: this.state.filter.currentTransporterId,
            currentShippingRegionId: this.state.filter.currentShippingRegionId,
            currentClientId: this.state.filter.currentClientId,
            currentProductType: this.state.filter.currentProductType
        };

        filter.year = this.state.filter.year;
        filter.weekNumber = this.state.filter.weekNumber;

        let data = await callApiAsync('/api/calendar/slots', 'POST', filter);
        this.setState({ ...data, showedType: data.filter.currentType, isLoading: false }, () => {
            LocalStorageService.updateFilter(this.filterName, this.state.filter);
        });
    }

    updateFilterValue = (value) =>
        this.setState(prevState => {
            return { filter: { ...prevState.filter, ...value } };
        }, () => { this.search(value); });


    dayHeaderRow = (index) => {
        let thisDay = this.state.header[index].itIsThisDay ? 'this-day' : '';
        return <Grid.Column style={{ textAlign: "center" }} key={index} className={thisDay}>{this.state.header[index].name}</Grid.Column>;
    }

    onToLeft = () => {
        this.updateFilterValue({
            weekNumber: this.state.toLeftWeekNumber,
            year: this.state.toLeftYear
        });
    }

    onToRight = () => {
        this.updateFilterValue({
            weekNumber: this.state.toRightWeekNumber,
            year: this.state.toRightYear
        });
    }

    onToCurrentWeek = () => {
        this.updateFilterValue({
            weekNumber: this.state.toCurrentWeekNumber,
            year: this.state.toCurrentWeekYear
        });
    }

    onChange = (name, value) => {
        if (name === 'currentType') {
            let newVal = this.state.filter.currentType === 'ref' ? 'tent' : 'ref';
            this.updateFilterValue({ currentType: newVal });

            return;
        }

        if (name === 'currentProductType') {
            if (['Fresh', 'Alco'].includes(value) && this.state.filter.currentType !== 'ref') {
                this.updateFilterValue({
                    currentProductType: value,
                    currentType: 'ref'
                });

                return;
            }
        }

        if (name === 'currentClientId') {
            this.updateFilterValue({
                currentClientId: value,
                currentDistributionCenterIds: []
            });

            return;
        }
        this.updateFilterValue({ [name]: value });
    }

    render() {
        let { distributionCenters, filter, slots,
            weekDescription, clients, productTypes, transporters, shippingRegions, isLoading, header, showedType } = this.state;

        let distributionCentersForTable = distributionCenters.filter(x => slots.some(y => y.distributionCenterId === x.id));
        if (filter && filter.currentDistributionCenterIds && filter.currentDistributionCenterIds.length > 0)
            distributionCentersForTable = distributionCentersForTable.filter(x => filter.currentDistributionCenterIds.includes(x.id));

        const isCurrentWeek = this.state.filter.year === this.state.toCurrentWeekYear &&
            this.state.filter.weekNumber === this.state.toCurrentWeekNumber;

        const isPrevWeek = !isCurrentWeek && (
            this.state.filter.year < this.state.toCurrentWeekYear
            || this.state.filter.weekNumber < this.state.toCurrentWeekNumber);

        const isNextWeek = !isCurrentWeek && (
            this.state.filter.year > this.state.toCurrentWeekYear
            || this.state.filter.weekNumber > this.state.toCurrentWeekNumber);

        return (
            <UserData.Consumer>
                {data => (
                    <div className="calendar">
                        <Filter weekDescription={weekDescription}
                            weekNumber={filter.weekNumber}
                            onToLeft={this.onToLeft}
                            onToRight={this.onToRight}
                            onToCurrentWeek={this.onToCurrentWeek}
                            isCurrentWeek={isCurrentWeek}

                            /*Dictionaries*/
                            distributionCenters={distributionCenters}
                            clients={clients}
                            productTypes={productTypes}
                            transporters={transporters}
                            regions={shippingRegions}

                            /*Filters*/
                            currentClientId={filter.currentClientId}
                            currentProductType={filter.currentProductType}
                            currentTransporterId={filter.currentTransporterId}
                            currentShippingRegionId={filter.currentShippingRegionId}
                            currentDistributionCenterIds={filter.currentDistributionCenterIds}
                            carType={filter.currentType}

                            /*Callback*/
                            onChange={this.onChange}
                        />
                        <Results
                            user={data}
                            slots={slots}
                            isTander={isTander({ clientId: filter.currentClientId, clientName: (clients.find(x => x.id === filter.currentClientId) || {}).name })}
                            distributionCentersForTable={distributionCentersForTable}
                            isLoading={isLoading}
                            header={header}
                            showedType={showedType}
                            search={this.search}
                            isPrevWeek={isPrevWeek}
                            isNextWeek={isNextWeek}
                        />
                    </div>
                )}
            </UserData.Consumer>
        );
    }
};