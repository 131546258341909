import styled, { css } from 'styled-components';

export const StyledReservationOrdersDetails = styled.div`
    display: flex;

    ${props => props.demo ? css`
        background: #ECF2F8;
        border: 1px solid #C4D6E9;
        border-radius: 4px;
        padding: 1.42857rem 1.785714rem;
    ` : css`
        width: 100%;
    `}

    ${props => props.mass
        ? css` 
            flex-direction: row;

            .reservation-pallets-details__orders {
                flex: 7;
                margin: 0;
                margin-right: 1rem;
            }
        `
        : css`
            flex-direction: column;
            
            .reservation-pallets-details__top {
                margin-bottom: 1.42857rem;
                padding-right: 180px;

                .reservation-pallets-details__input-container {
                    min-width: 150px;
                }
            }

            .reservation-pallets-details__orders {
                border: 1px solid #C4D6E9;
                border-radius: 8px;
                padding-bottom: 0.714286rem;
            }
    `};

    .reservation-pallets-details__inputs-container {
        display: flex;
    }

    .reservation-pallets-details__comment {
        flex-grow: 2;
    }

    .ui.form textarea.reservation-pallets-details__textarea {
        resize: none;
        overflow: hidden;
        padding: 0.42857143em 1em;
        line-height: 1.5em;
    }

    .ui.form textarea:not([rows]) {
        min-height: 38px;
    }
`;

const fieldSizes = {
    s: '80',
    m: '120',
    l: '170',
};

export const StyledInputContainer = styled.div`
    min-width: ${props => props.size ? css`${fieldSizes[props.size]}px` : '80px'};
    margin: 0;
    margin-right: 1.42857rem;
    display: inline-block;
    flex-grow: 1;

    &:last-child {
        margin-right: 0;
    }

    & > .error {
        label {
            color: #990202;
        }

        input {
            border-color: #990202;
        }
    }
    
    label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    ${props => props.mods === 'palletsNumbers' ? css`
        min-width: calc(160px + 1.42857rem);

        & >.reservation-pallets-details__inputs-container > div:first-child {
            margin-right: 0.35714rem
        }

        &.error .ui.icon.input:not(.error) > .icon.input-label-icon {
            color: #202020;
        }
    ` : ''}
`;

export const StyledDetailsTableHeader = styled.div`
    font-weight: 700;
    display: flex;
    min-width: min-content;
    padding: 0.714286rem 1rem;
    margin-bottom: 0.714286rem;
    padding-right: calc(2.42857rem + ${props => props.mass ? '45px' : '70px'});
    border-bottom: 1px solid #C4D6E9;
`;

export const StyledDetailsLine = styled.div`
    display: flex;
    min-width: min-content;
    margin: 0 0 0.714286rem;
    padding: 0 1rem;

    &:last-child {
        margin-bottom: 0;
    }

    .ui.fluid.selection.dropdown {
        padding: 0.57142857em 1em;
        line-height: 1.21428571em;

        & > .text {
            vertical-align: middle;
        }
    }

    .ui.dropdown .text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
    }
`;

export const StyledDetailsLineButtons = styled.div`
    padding-top: ${props => props.mass && props.labeled ? '2rem' : '0'};
    display: flex;
    align-items: center;
    min-width: ${props => props.mass ? '45px' : '70px'};

    .ui.primary.button {
        flex-shrink: 0;
        display: flex;
        color: #135CA9;
        padding: 0;
        width: ${props => props.mass ? '20px' : '32px'};
        height: ${props => props.mass ? '20px' : '32px'};
        border-width: ${props => props.mass ? '1px' : '2px'};
        border-radius: 50%;
        margin-right: 0.3rem;

        &:hover,
        &:focus {
            color: #fff;
        }

        &:last-child {
            margin: 0;
        }
    }

    .ui.button > .icon:not(.button) {
        width: 100%;
        height: 100%;
        ${props => props.mass ? '' : css`font-size: 16px;`}
    }

    i.icon {
        font-size: 12px;
        height: 18px;
        width: 18px;
        position: relative;
        display: inline-block;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
`;