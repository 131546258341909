import styled, { css } from 'styled-components';

import { Button } from 'semantic-ui-react';

export const StyledReservationFormTab = styled.div`
    padding: 0 13px 2rem;
    overflow: auto;

    td {
        vertical-align: top;
    }

    .reservation-form-tab__title {
        font-size: 1.142857rem;
    }

    .reservation-form-tab__reset {
        display: flex;
        padding-top: 1rem;
        align-items: center;
    }

    .reservation-form-tab__form-demo {
        display: flex;
    }

    .reservation-form-tab__saved-label {
        font-size: 0.85714rem;
        color: #135CA9;
    }

    .reservation-form-tab__saved-label--error {
        color: #990202;
    }

    .ui.selection.active.dropdown .menu {
        max-height: 10rem;
    }
`;

export const StyledSavedLabel = styled.span`
    display: ${props => props.inline ? 'inline-block' : 'block'};
    margin-left: ${props => props.inline ? '1rem' : '0'};
    height: ${props => props.show ? 'auto' : '1px'};
    visibility: ${props => props.show ? 'visible' : 'hidden'};
    color: ${props => props.error ? '#990202' : '#135CA9'};
    font-size: 0.85714rem;
    line-height: 1em;
`;

export const StyledResetButton = styled(Button)`
    &.ui.button.underlined {
        font-weight: 400;
        
        ${props => props.hidden
        ? css`
            color: #aaa;
        `
        : ''}
    }
`;