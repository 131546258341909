import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { callApiAsync } from '../../hooks';
import { isTander } from '../../utils';
import moment from 'moment';

import TextareaAutosize from 'react-textarea-autosize';

import CancellationModal from './Cancellation';

import Input from '../../components/NewInput/Input';
import Accordion from '../../components/Accordion/Accordion';
import Tag from '../../components/Tag';
import Tooltip from '../../components/Tooltip';
import Dropdown from '../../components/Dropdown/Dropdown';
import Button from '../../components/Button';
import Message from '../../components/Message';

import ReactDatepicker from '../../components/ReactDatepicker/ReactDatepicker';

import ReservationOrdersDetails from '../../pages/ReservationOrdersDetails/ReservationOrdersDetails';
import { createErrorsObject } from '../../pages/ReservationOrdersDetails/utilities/validateInput';

import {
    ContentRow,
    FlexContentRow,
    ButtonRow,
    ContentField,
    TimeField,
    DateTimeField,
    TimeWarningTooltip,
    ContentFieldLabel,
    StyledDeliveryDate,
    StyledTextareaAutosize
} from './Reservation.style';

const ReservationForm = ({
    slotId,
    loadedReservation,
    slot,
    deliveryDate,
    isDemo = false,
    warehouses = [],
    setSlot,
    defaultIsEditing,
    setIsChanged,
    onDeletion
}) => {

    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [reservation, setReservation] = useState({});
    const [isEditing, setEditing] = useState(defaultIsEditing);

    const [orderDetails, setOrdersDetails] = useState(null);

    const [showErrors, setShowErros] = useState(false);

    const [formSettings, setFormSettings] = useState(null);
    const [reservationChanged, localSetReservationChanged] = useState(false);

    useEffect(() => {
        const {
            palletCount = '',
            boxCount = '',
            weight = '',
            cost = '',
            orders,
            formSettings,
            isEditing,
            ...rest
        } = loadedReservation;

        localSetReservationChanged(false);
        setReservation(rest);
        setEditing(isEditing || false);
        setFormSettings(formSettings);

        setOrdersDetails({
            palletCount,
            weight,
            cost,
            orders,
            ...(formSettings.boxType ? { boxCount } : {})
        });
    }, []);

    const setReservationChanged = (bool) => {
        localSetReservationChanged(bool);
        setIsChanged(bool);
    };

    const checkTander = useCallback(
        (clientId, clientName) => isTander({ clientId, clientName }),
        []
    );

    const tander = useMemo(() => checkTander(slot.clientId, slot.clientName), [slot.clientId, slot.clientName]);

    if (!reservation)
        return null;

    const isCarrierWarehouse = () => {
        return (warehouses.find(w => w.id === reservation.warehouseId) || {}).isCarrierWarehouse;
    };

    const onReservationChange = (field) => (value) => {
        let res = { ...reservation };

        if (field === 'date' && !value) return;

        let prevValue = res[field];
        res[field] = value;

        // Checking the time warning
        if (field === 'date' || field === 'timeFrom') {
            let now = moment();
            res.timeWarning = res.date.isSame(now, 'day') && moment(res.timeFrom, 'HH:mm').isBefore(now);
        }

        // Checking if the timeFrom > timeTo
        if ((field === 'timeFrom' || field === 'timeTo') && (res.timeFrom.length === 5 && res.timeTo && res.timeTo.length === 5)) {
            let from = moment(res.timeFrom, 'HH:mm'),
                to = moment(res.timeTo, 'HH:mm');

            if (to.isBefore(from))
                res.timeTo = res.timeFrom;
        }

        if (field === 'timeSlot') {
            res[field + 'Valid'] = value
                ? Number(value.replace(":", "")) > 0
                : true;
        }

        if (!value && (field === 'pickingUpFrom' || field === 'pickingUpTo')) {
            return;
        }

        // If we re changing pickingUpFrom date/time for today
        if (field === 'pickingUpFrom') {
            let now = moment();

            if (!prevValue.isSame(value, 'day')) {
                if (now.isSame(value, 'day')) {
                    res.pickingUpFrom = now.add(1, 'hour').minutes(0).seconds(0);
                    res.pickingUpTo = moment(res.pickingUpFrom).hours(19).minutes(0).seconds(0);

                    // Making there more strange logic...
                    if (res.pickingUpTo.isBefore(res.pickingUpFrom)) {
                        res.pickingUpTo = moment(res.pickingUpFrom);
                    }
                } else {
                    res.pickingUpFrom = res.pickingUpFrom.hours(10).minutes(0).seconds(0);
                    res.pickingUpTo = res.pickingUpTo.hours(19).minutes(0).seconds(0);
                }
            }
        }

        setReservation(res);
        setReservationChanged(true);
    };

    const handleOrderDetailsChange = ({ values }) => {
        setOrdersDetails(values);
        setReservationChanged(true);
    };

    const validateForm = () => {
        let isValid = true;

        const {
            orders,
            errors: orderDetailsErrors,
            ...rest
        } = orderDetails || {};

        const validateErrorsObject = (o, values) => {
            if (!values) return false;

            const { palletFrom, palletTo } = values;

            if (palletFrom && palletTo && palletFrom > palletTo) return false;

            if (o) return Object.keys(o).every(errorKey => !o[errorKey]);

            const errorsObject = createErrorsObject({ values, tander });

            return Object.keys(errorsObject).every(errorKey => !errorsObject[errorKey]);
        };

        // Validating Orders
        const generalFieldsValid = validateErrorsObject(orderDetailsErrors, rest);
        const ordersFieldsValid = orders && orders.every(o => validateErrorsObject(o.errors, o));

        if (!generalFieldsValid || !ordersFieldsValid) {
            isValid = false;
        };

        let res = { ...reservation };

        for (let field of ['timeFrom', 'timeTo', 'timeSlot']) {
            let valid =
                res[field] !== undefined &&
                res[field] !== null &&
                res[field].length === 5;

            if (field === 'timeTo' && isCarrierWarehouse())
                continue;

            if (field === "timeSlot" && (!res[field] || [0, 5].includes(res[field].length)))
                continue;

            if (!valid)
                isValid = false;

            res[field + 'Valid'] = valid;
        }

        // Time
        const now = moment();

        res.timeWarning = res.date.isSame(now, 'day') && moment(res.timeFrom, 'HH:mm').isBefore(now);

        if (res.timeWarning)
            isValid = false;

        setReservation(res);

        return isValid;
    };


    const onSave = async () => {
        if (!validateForm()) {
            setShowErros(true);

            return;
        }

        const {
            errors,
            onChangeErrors,
            orders = [],
            ...restDetails
        } = orderDetails;

        const formattedReservation = {
            ...reservation,
            ...restDetails,
            orders: orders.map(item => {
                const {
                    type,
                    errors,
                    onChangeErrors,
                    ...rest
                } = item;

                return { ...rest, type: type || 0 };
            })
        };

        setLoading(true);

        try {
            const data = await callApiAsync('api/reservations', 'POST', {
                ...formattedReservation,
                slotId
            });

            setError(data.error);

            if (!data.error) {
                const {
                    palletCount,
                    boxCount,
                    weight,
                    cost,
                    orders,
                    formSettings,
                    isEditing,
                    date,
                    ...rest
                } = data.slot.reservations[0];

                setReservation({ ...rest, date: moment(date) });

                setFormSettings(formSettings);

                setOrdersDetails({
                    palletCount,
                    weight,
                    cost,
                    orders,
                    ...(formSettings.boxType ? { boxCount } : {})
                });

                setShowErros(false);

                setSlot(data.slot, true);
                setReservationChanged(false);
                setEditing(true);
            }
        }
        catch (err) {
            setError(err.message);
        }
        finally {
            setLoading(false);
        }
    };

    const onCancel = async () => {
        const data = await callApiAsync(`api/reservations/${reservation.id}`, 'DELETE', undefined);

        setError(data.error);

        if (!data.error) {
            setReservationChanged(false);
            setReservation(null);
            setOrdersDetails(null);
            onDeletion();
        }
    };

    let selectedWh = warehouses.find(w => w.id === reservation.warehouseId) || {};
    let selectedWhName = selectedWh.name || "";
    let today = moment().startOf('day');

    let tagItem = !isEditing
        ? <Tag
            color='#B8E3B1'
            content='Створення'
        />
        : reservationChanged
            ? <Tag
                color='#F2D7AF'
                content='Зміна'
            />
            : <Tag
                color='#C4D6E9'
                content='Заброньовано'
            />;

    const palletCountLabel = (orderDetails && orderDetails.palletCount) || 0;

    return <Accordion
        noBorderRadius
        defaultOpen={!isEditing}
        style={{ position: 'relative' }}
        contentSidePadding='25px'
        title={
            `${selectedWhName.toLowerCase().includes("склад") ? "" : "Склад "}` +
            `${selectedWhName}, палет: ${palletCountLabel}`
        }
        additionElement={tagItem}
    >
        {isEditing
            ? <Tag
                color='#C4D6E9'
                content={'№ ' + reservation.number}
                style={{ top: 60 }}
            />
            : null
        }
        <FlexContentRow>
            {orderDetails && formSettings && (
                <ReservationOrdersDetails
                    mode="default"
                    isTander={tander}
                    defaultValues={orderDetails}
                    settings={formSettings}
                    showFieldsErrors={showErrors}
                    onChange={handleOrderDetailsChange}
                />
            )}
        </FlexContentRow>

        <ContentRow>
            <ContentField>
                <ContentFieldLabel>Склад відвантаження</ContentFieldLabel>
                <Tooltip
                    fluidContainer
                    textAlign='left'
                    marginBottom='5px'
                    content={<>
                        Выберите зклад, з которого будет отправлятьзя товар:
                        <br />
                        <b>зклад конзолидации</b> — в злучае замозтоятельной дозтавки на зклад конзолидации,
                        <br />
                        <b>ваш зклад</b> — в злучае забора груза транзпортной компанией у ваз
                    </>}
                >
                    <Dropdown
                        fluid
                        noResultsMessage="Нічого не знайдено"
                        value={reservation.warehouseId}
                        onChange={onReservationChange('warehouseId')}
                        options={warehouses.map(w => ({ value: w.id, text: `${w.name}: ${w.address}` }))}
                    />
                </Tooltip>
            </ContentField>
        </ContentRow>
        <FlexContentRow>
            {isCarrierWarehouse()
                ? <>
                    <DateTimeField>
                        <ContentFieldLabel>
                            Дата та час доставки на склад консолiдацiї
                        </ContentFieldLabel>
                        <ReactDatepicker
                            momentFallback
                            name="date"
                            minDate={today}
                            maxDate={slot.consolidationDate}
                            value={reservation.date || null}
                            onChange={onReservationChange('date')}
                        />
                        {reservation.timeWarning
                            ? <TimeWarningTooltip
                                error
                                fixed
                                delay='0s'
                                content={(<>
                                    Вкажіть час не раніше поточного
                                </>)}
                            >
                                <Input.Time
                                    width='100px'
                                    error={reservation.timeFromValid === false}
                                    value={reservation.timeFrom}
                                    onChange={onReservationChange('timeFrom')}
                                />
                            </TimeWarningTooltip>
                            : <Input.Time
                                width='100px'
                                error={reservation.timeFromValid === false}
                                value={reservation.timeFrom}
                                onChange={onReservationChange('timeFrom')}
                            />
                        }
                    </DateTimeField>
                </>
                : <>
                    <ContentField>
                        <ContentFieldLabel>
                            Дата відвантаження
                        </ContentFieldLabel>
                        <ReactDatepicker
                            name="date"
                            momentFallback
                            minDate={today}
                            maxDate={slot.consolidationDate}
                            value={reservation.date}
                            onChange={onReservationChange('date')}
                        />
                    </ContentField>
                    <TimeField>
                        <ContentFieldLabel>
                            Час відвантаження
                        </ContentFieldLabel>
                        {reservation.timeWarning
                            ? <TimeWarningTooltip
                                error
                                fixed
                                delay='0s'
                                content={(<>
                                    Вкажіть час не раніше поточного
                                </>)}
                            >
                                <Input.Time
                                    width='100px'
                                    error={reservation.timeFromValid === false}
                                    value={reservation.timeFrom}
                                    onChange={onReservationChange('timeFrom')}
                                />
                            </TimeWarningTooltip>
                            : <Input.Time
                                width='100px'
                                error={reservation.timeFromValid === false}
                                value={reservation.timeFrom}
                                onChange={onReservationChange('timeFrom')}
                            />
                        }
                        <Input.Time
                            width='100px'
                            error={reservation.timeToValid === false}
                            value={reservation.timeTo}
                            onChange={onReservationChange('timeTo')}
                        />
                    </TimeField>
                </>
            }
            <ContentField style={{ marginLeft: "auto" }}>
                <ContentFieldLabel>
                    День доставки
                </ContentFieldLabel>
                <StyledDeliveryDate>
                    {deliveryDate}
                </StyledDeliveryDate>
            </ContentField>
            <ContentField>
                <ContentFieldLabel>Авізація</ContentFieldLabel>
                <Input.Time
                    width='100px'
                    error={reservation.timeSlotValid === false}
                    value={reservation.timeSlot}
                    onChange={onReservationChange('timeSlot')}
                />
            </ContentField>
        </FlexContentRow>
        <ContentRow>
            <ContentFieldLabel>
                Коментар
            </ContentFieldLabel>
            <StyledTextareaAutosize>
                <TextareaAutosize
                    name="comment"
                    placeholder="Додаткова iнформацiя..."
                    maxRows={6}
                    value={reservation.comment || ''}
                    onChange={(e) => onReservationChange('comment')(e.target.value)}
                />
            </StyledTextareaAutosize>
        </ContentRow>
        <ContentRow>
            {error
                && <Message type='error'>
                    {error}
                </Message>
            }
            {isDemo
                && <Message type='warning'>
                    Ви не можете бронювати слот, тому що у Вас демо акаунт.
                </Message>
            }

        </ContentRow>
        <ButtonRow>
            {isEditing
                ? <>
                    <CancellationModal
                        onYes={onCancel}
                    >
                        <Button.Text
                            error
                            content='Відмінити бронювання'
                        />
                    </CancellationModal>
                    {reservationChanged
                        && <Button.Text
                            disabled={isLoading}
                            content='Зберегти бронювання'
                            onClick={onSave}
                        />
                    }
                </>
                : <Button.Text
                    content='Забронювати'
                    disabled={isLoading || isDemo}
                    onClick={onSave}
                />
            }
        </ButtonRow>
    </Accordion >;

};

export default ReservationForm;
