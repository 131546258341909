import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import RuleReservation from "../../RuleReservation/RuleReservation";

export default class RuleReservationModal extends React.Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
    }

    state = {
        modal: false
    };


    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {

        return (
            <div>
                <Modal closeIcon open={this.state.modal} onClose={this.toggle} className="rule-reservation-modal"
                    trigger={<a className="ruleReservationBtn" onClick={() => { this.toggle(); }}>
                        Правила розміщення замовлення
                    </a>}>
                    <Modal.Header>Правила розміщення замовлення</Modal.Header>
                    <Modal.Content>
                        <RuleReservation />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.toggle}>Закрити</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
};