const defaultSettings = {
    palletType: 0,
    boxType: 0,
    costType: 0,
    weightType: 0,
    orderType: 1,
};

let settings;

const getOrderErrors = (values = {}) => {
    const {
        palletFrom,
        palletTo,
        palletCount,
        boxCount,
        cost,
        weight,
        orderNumber,
        packingList
    } = values;

    const {
        palletType,
        boxType,
        costType,
        weightType
    } = settings;

    return {
        palletFrom: palletType === 0 && !palletFrom,
        palletTo: palletType === 0 && !palletTo,
        palletCount: palletType === 1 && !palletCount,
        boxCount: boxType === 2 && !boxCount,
        cost: costType === 1 && !cost,
        weight: weightType === 1 && (weight === undefined || weight === ''),
        orderNumber: !orderNumber,
        packingList: !packingList,
        type: false,
    };
};

export const getEmptyLine = (props) => {
    const {
        palletFrom = 1,
        palletTo = 1,
        palletCount,
        type
    } = props || {};

    const {
        palletType,
        boxType
    } = settings;

    const result = {
        orderNumber: '',
        packingList: '',
        weight: '',
        cost: '',
        type: type || '',
        errors: getOrderErrors({ palletFrom, palletTo, palletCount }),
        onChangeErrors: {}
    };

    if (palletType === 0) {
        result.palletFrom = palletFrom;
        result.palletTo = palletTo;
    }

    if (palletType === 1) {
        result.palletCount = palletCount || '';
    }

    if (boxType !== 0) {
        result.boxCount = '';
    }

    return result;
};

export const getInitialState = (_settings, defaultValues) => {
    settings = _settings || defaultSettings;

    const { boxType } = settings;

    const getErrors = (values = {}) => {

        const {
            palletCount,
            boxCount,
            cost,
            weight,
        } = values;

        return {
            palletCount: !palletCount,
            boxCount: !!boxType && !boxCount,
            cost: !cost,
            weight: !weight,
        };
    };

    if (!defaultValues) return ({
        palletCount: '',
        cost: '',
        weight: '',
        orders: [
            getEmptyLine()
        ],
        timeSlot: '',
        comment: '',
        errors: getErrors(),
        onChangeErrors: {},
        ...(boxType !== 0
            ? {
                boxCount: ''
            }
            : {}
        )
    });

    const { orders, ...rest } = defaultValues;

    return {
        ...rest,
        errors: getErrors(rest),
        onChangeErrors: {},
        orders: orders.map(o => ({
            ...o,
            type: o.type || '',
            errors: getOrderErrors(o),
            onChangeErrors: {}
        }))
    };
};

export default getInitialState;