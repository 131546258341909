import React from 'react';
import styled, { css } from 'styled-components';
import { firstByType } from '../../utils';


const Wrapper = styled.div`
    position: relative;
    padding-top: 10px;

    ${props => props.inline && css`
        display:inline-block;
    `}

    ${props => props.link && css`
        &:hover {

        & > div:not(:first-child) {
            color: #135CA9 !important;

        }
    `}

    &:not(:last-child) {
        margin-bottom: 30px;
    }
`;

const Divider = styled.div`
    width: 30px;
    position: absolute;
    top: 0;
    left: 0;
    
    height: 2px;
    background-color: #C4D6E9;
`;

const HeadWrapper = styled.div`
    color: #000000;

    ${props => props.link && css`
        color: #135CA9;
    }
    `}
`;

const Header = styled.p`
    font-size: 22px;
    margin-bottom: 8px;
`;

Header.displayName = 'SegmentHeader';

const Body = styled.div`
    font-family: 'ClearSans';
    font-size: 1rem;
    color: #000000;
`;

Body.displayName = 'SegmentBody';


const Segment = ({
    divider = false,
    children,
    inline = false,
    link = false
}) => {
    let header = firstByType(children, 'SegmentHeader');
    let body = firstByType(children, 'SegmentBody');

    return (
        <Wrapper inline link>
            <HeadWrapper link>
                {divider && <Divider />}
                {header}
            </HeadWrapper>
            {body}
        </Wrapper>
    );
};

Segment.Header = Header;
Segment.Body = Body;


export default Segment;