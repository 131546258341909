import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { Exclusions } from './MapModal.addresses.js';

export default class MapModal extends React.Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

    state = {
        modal: false,
        mapState: null,
        label: 'Завантаження...'
    };

    toggle() {
        if (this.state.modal === false) {
            let found = Exclusions.find(e => e.address === this.props.address);
            if (found) {
                let coords = found.pos.split(' ');

                this.setState({ mapState: { center: [coords[0], coords[1]], zoom: 10 } });
            } else {
                fetch('https://geocode-maps.yandex.ru/1.x/?format=json&geocode=' + this.props.address).then((data) => {
                    return data.json();
                }).then((resp) => {
                    try {
                        let coords = resp.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(' ');

                        this.setState({ mapState: { center: [coords[1], coords[0]], zoom: 10 } });
                    } catch (e) {
                        this.setState({ label: 'Не вдалось розпізнати адресу' });
                    }
                });
            }
        } else {
            this.setState({ mapState: null });
        }

        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        return (
            <>

                <Modal size="tiny" closeIcon open={this.state.modal} onClose={this.toggle} className="reservation-modal"
                    trigger={<a onClick={() => { this.toggle(); }} className={this.props.linkClass}>
                        {this.props.children}
                    </a>}>
                    <Modal.Header>Карта</Modal.Header>
                    <Modal.Content>
                        <div className="map-container">
                            {this.state.mapState
                                ? <YMaps>
                                    <Map
                                        state={this.state.mapState}
                                        style={{ width: '100%', height: '315px' }}
                                    >

                                        <Placemark
                                            geometry={this.state.mapState.center}
                                        />
                                    </Map></YMaps>
                                : <div className="label">{this.state.label}</div>
                            }
                        </div>
                        <p>{this.props.address}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.toggle}>Закрити</Button>
                    </Modal.Actions>
                </Modal>
            </>
        );
    }
};