//IE polyfill
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'semantic-ui-less/semantic.less';
import './index.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import moment from "moment-timezone";
import 'moment/locale/uk';


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
moment.locale('uk');
moment.tz.setDefault('Europe/Kiev');

// Define Production Environment
window.isProduction = window.location.href.indexOf('http://ua.calendar.pooling.me/') === 0;

// Client App version
window.version = '2.4.0';

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <App />
    </BrowserRouter>,
    rootElement);

unregister();
