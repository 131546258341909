import styled, { css } from 'styled-components';


export const HeaderWrapper = styled.div`
    width: 100%;
    background-color: white;
    display: flex;
    border-bottom: 1px solid #C4D6E9;
    margin:0;
    user-select: none;
    flex-shrink: 0;
`;

export const MenuItem = styled.div`
    cursor: pointer;
    padding: 17px 0;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-size: 1rem;
    color: #222;
    text-decoration: none !important;
    border-bottom: 2px solid transparent;
    ${props => props.notHoverable || css`
        &:hover {
            color: #135CA9;
        }
        &:hover > * > div {
            color: #222;
        }
    `}
    ${props => props.isActive && css`
        color: #135CA9 !important;
        border-bottom: 2px solid #135CA9;
    `}
`;

export const MenuItemExternal = styled(MenuItem)`
    padding: 0 !important;
`;

export const Logo = styled.img`
    margin: -13px 20px;
    width: auto;
`;

export const RightMenu = styled.div`
    position:relative;
    margin-left: auto;
    display: flex;
    margin-right: 20px;
    margin-top: -0.20rem;

    ${MenuItem} {
        margin: 0 15px;
        justify-content: left;
        text-align: left;
    }
`;

export const MenuIcon = styled.div`
    width: 28px;
    height: 28px;
    position: relative;
    background: url(${props => props.img}) no-repeat center center;
    background-size: contain;
    &:hover, ${MenuItem}.open-sub-menu &{
        background: url(${props => props.hoverImg}) no-repeat center center;;
        background-size:contain;
    }
    &::after{
        content: url(${props => props.hoverImg});
        display: none;
    }
`;

export const SubMenu = styled.div`
    position: absolute;
    z-index: 10;
    top: 70px;
    right: 0;
    background: white;
    border: 1px solid #C4D6E9;
    border-radius: 1.35rem;
    overflow:hidden;
    min-width:200px;
    min-height:100px;
    display: none;

    ${MenuItem} {
        padding: 10px 0;
        height: auto;
        margin: 0 !important;
    }
`;

export const UserMenuHeader = styled.div`
    background: rgba(240, 245, 248, 0.7);
    border-radius: 1.55rem 1.55rem 0 0;
    overflow:hidden;
    padding: 10px 15px 0 15px;
`;

export const SubMenuBody = styled.div`
     padding: 10px 0;
    ${MenuItem} {
        padding: 10px 15px;
        white-space: nowrap;
        &:hover{
            background: rgba(240, 245, 248, 0.7);
        }
    }
`;

export const UserName = styled.div`
    font-size: 1.1rem;
    margin-bottom: 0.2rem;
`;
export const UserRole = styled.div`
    font-size: 0.9rem;
    margin-bottom: 0.2rem;
`;
export const ExitButton = styled(MenuItem)`
    &:hover{
        color: #135CA9 !important;
    }
`;

export const MessageWrapper = styled(HeaderWrapper)`
    background-color: #135CA9;
    color: #fff;
    height: auto;
    padding: 8px 40px 9px;
    user-select: auto;

    .ui.button {
        background: none;
        border: none;
        color: #fff;
        box-shadow: none;
        padding: 0.7rem 0 0.4rem;
        margin: 0 0 0.3rem;
        border-bottom: 1px solid transparent;
        border-radius: 0;

        &:hover {
            background: none;
            border-color: #fff;
            color: #fff;
        }
    }
`;

export const MessageText = styled.div`
    margin:0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
`;

export const MessageButton = styled.div`
    margin-left: auto;
    padding-left: 40px;
`;

export const ModalHeader = styled.h2`
    font-size: 1.75rem;
    color: #135CA9;
    padding: 10px !important;
    user-select: none;
`;
export const ModalContent = styled.div`
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Text = styled.div`
    ${props => props.align = "center" && css`
        text-align: center;
    `}
`;
export const Flex = styled.div`
    display: flex;
    flex-grow: 1;
`;


