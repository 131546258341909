import React from 'react';
import { Button, Dropdown, Dimmer, Loader } from 'semantic-ui-react';

import { callApiAsync } from '../../hooks';

import DatePicker from '../../components/ReactDatepicker/ReactDatepicker';

import { sortClients } from '../../utils';
import { format, addDays, subDays } from '../../utils/dateUtils';

const formatDateForTable = date => date && format(date, 'dd.MM.yyyy');

export default class Prolongations extends React.Component {
    state = {
        isLoading: false,

        dayNames: ['Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П\'ятниця', 'Субота', 'Неділя'],

        prolongations: [],
        totalCount: 0,

        consignorCompanies: [],
        clients: [],
        distributionCenters: [],
        shippingRegions: [],
        productTypes: [],

        filter: {
            clientId: ' ',
            shippingRegionId: ' ',
            distributionCenterIds: [],
            consignorCompanyId: ' ',
            productType: ' ',
            forDate: null,
            deliveryDate: null
        }
    };

    componentDidMount() {
        this.init();
    }

    init = async () => {
        const clients = await callApiAsync('/api/definitions/clients', 'GET');
        const sortedClients = sortClients(clients);

        const filter = {
            clientId: ((sortedClients || [])[0] || {}).id || '',
            shippingRegionId: ' ',
            distributionCenterIds: [],
            consignorCompanyId: ' ',
            productType: ' ',
            forDate: null,
            deliveryDate: null
        };

        this.setState({ filter }, this.reload);
    }

    reload = async () => {
        const { filter } = this.state;

        const model = {
            clientId: filter.clientId,
            distributionCenterIds: filter.distributionCenterIds,
            consignorCompanyId: filter.consignorCompanyId,
            forDate: filter.forDate ? format(filter.forDate, "yyyy-MM-dd'T'HH:mm:ss") : null,
            deliveryDate: filter.deliveryDate ? format(filter.deliveryDate, "yyyy-MM-dd'T'HH:mm:ss") : null
        };

        const data = await callApiAsync('api/prolongation/list', 'POST', model);

        if (data) {
            this.setState({
                ...data,
                clients: sortClients(data.clients),
            });
        }
    }

    updateFilterValue = (value) => {
        this.setState({
            filter: {
                ...this.state.filter,
                ...value
            }
        }, this.reload);
    }

    onChangeFilterDate = (e, { name, value }) => {
        this.updateFilterValue({ [name]: value });
    }

    handleFilterChange = (obj) => {
        let field = obj.name;
        let value = obj.value;

        if (this.state.filter[field] === value)
            return;

        this.updateFilterValue({ [field]: value });
    }

    handleProlongationChange = (index, obj) => {
        let field = obj.name;
        let value = obj.value;

        let prolongations = this.state.prolongations;

        prolongations[index][field] = value;

        this.setState({
            prolongations: prolongations
        });
    }

    handleProlongationDateChange = (index, value, name) => {
        const { prolongations } = this.state;

        prolongations[index][name] = value;

        this.setState({
            prolongations
        });
    }

    updateProlongation = async (index) => {
        let prolongations = [...this.state.prolongations];
        let prolongation = prolongations[index];

        if (prolongation.prolongationDate) {
            this.setState({ isLoading: true });

            let data = await callApiAsync('api/prolongation/Save', 'POST', prolongation);
            prolongation.id = data.id;

            this.setState({
                prolongations: prolongations,
                isLoading: false
            });
        } else {
            if (prolongation.id) {
                this.setState({ isLoading: true });

                let data = await callApiAsync('api/prolongation/delete', 'POST', prolongation.id);
                prolongation.id = data.id;

                this.setState({
                    prolongations: prolongations,
                    isLoading: false
                });
            }
        }
    }

    calculateDayDelivary = (index) => {
        let prolongation = this.state.prolongations[index];
        let numberDayDelivery = (prolongation.dayForDelivery + prolongation.dayNumber) % 7;
        return this.state.dayNames[numberDayDelivery];
    }

    prolongations = () => {
        return this.state.prolongations.map((prolongation, index) => {

            return (
                <tr key={index}>
                    <td>
                        {this.state.shippingRegions.filter(x => x.id === prolongation.shippingRegionId).map(x => x.name)}
                    </td>
                    <td>
                        {this.state.distributionCenters.filter(x => x.id === prolongation.distributionCenterId).map(x => x.name)}
                    </td>
                    <td>
                        {this.state.productTypes.filter(x => x.id === prolongation.productType).map(x => x.name)}
                    </td>
                    <td>
                        {formatDateForTable(prolongation.forDate)}
                    </td>
                    <td>
                        {formatDateForTable(prolongation.deliveryDate)}
                    </td>
                    <td>
                        <div>
                            <DatePicker
                                fluid
                                minDate={new Date()}
                                maxDate={addDays(prolongation.forDate, 6)}
                                showTimeSelect
                                timeIntervals={60}
                                dateFormat="DD.MM.YYYY HH:mm"
                                placeholderText="дд.мм.рррр"
                                timeFormat="HH:mm"
                                timeCaption=""
                                name="prolongationDate"
                                value={prolongation.prolongationDate || null}
                                onChange={(e, { name, value }) => this.handleProlongationDateChange(index, value, name)}
                            />
                        </div>
                    </td>
                    <td>
                        <Button primary onClick={() => this.updateProlongation(index)}>Продовжити</Button>
                    </td>
                </tr>
            );
        });
    }

    render() {
        const {
            isLoading,
            filter
        } = this.state;

        return (
            <div className="container">
                <Dimmer inverted active={isLoading}>
                    <Loader inverted />
                </Dimmer>

                <div className="ui form row">
                    <div className="col-3">
                        <label>Вантажовідправник</label>
                        <Dropdown search fluid selection name='consignorCompanyId'
                            noResultsMessage="Нічого не знайдено"
                            value={filter.consignorCompanyId}
                            onChange={(e, obj) => this.handleFilterChange(obj)}
                            options={this.state.consignorCompanies.map(x => {
                                return { text: x.name, value: x.id };
                            })} />
                    </div>
                    <div className="col-3">
                        <label>Клієнт</label>
                        <Dropdown search fluid selection name='clientId'
                            noResultsMessage="Нічого не знайдено"
                            value={filter.clientId}
                            onChange={(e, obj) => this.handleFilterChange(obj)}
                            options={this.state.clients.map(x => {
                                return { text: x.name, value: x.id };
                            })} />
                    </div>
                    <div className="col-6">
                        <label>РЦ</label>
                        <Dropdown search selection name='distributionCenterIds' placeholder='Виберіть РЦ...'
                            fluid multiple noResultsMessage='Нічого не знайдено'
                            onChange={(e, obj) => this.handleFilterChange(obj)}
                            value={filter.distributionCenterIds}
                            options={this.state.distributionCenters.filter(x => x.clientId === filter.clientId).map(x => {
                                return { key: x.id, text: x.name, value: x.id };
                            })} />
                    </div>
                    <div className="col-2">
                        <label>День&nbsp;консолидации</label>
                        <DatePicker
                            className="prolongation-date"
                            dateFormat="DD.MM.YY"
                            placeholderText="дд.мм.рр"
                            minDate={subDays(new Date(), 6)}
                            name="forDate"
                            value={filter.forDate || null}
                            onChange={this.onChangeFilterDate}
                        />
                    </div>
                    <div className="col-2">
                        <label>День доставки</label>
                        <DatePicker
                            className="prolongation-date"
                            dateFormat="DD.MM.YY"
                            placeholderText="дд.мм.рр"
                            name="deliveryDate"
                            value={filter.deliveryDate || null}
                            onChange={this.onChangeFilterDate}
                        />
                    </div>
                </div>

                <table className="table ui form">
                    <thead>
                        <tr>
                            <th className="w-20">Регіон</th>
                            <th className="w-20">РЦ</th>
                            <th className="w-10">Тип продукту</th>
                            <th className="w-10">Дата консолидації</th>
                            <th className="w-10">Дата доставки</th>
                            <th className="w-20">Продовження до</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.prolongations()}
                    </tbody>
                </table>
            </div>
        );
    }
};