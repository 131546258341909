import "../DateManageModal/DateManageModal.style.css";
import React from "react";
import { Modal, Button, Message, Form, Dropdown } from "semantic-ui-react";

import axios from "axios";
import { format, addDays } from '../../utils/dateUtils';

import DatePicker from '../../components/ReactDatepicker/ReactDatepicker';
import ExportButton from '../../components/Button/ExportButton';

const formatDate = date => date && format(date, 'yyyy-MM-dd');

export default class PrintFormModal extends React.Component {
    state = {
        modal: false,
        error: '',
        carriers: [],
        currentClientId: '',
        startDate: new Date(),
        endDate: addDays(new Date(), 7)
    };

    toggle = () => {
        if (!this.state.modal) {
            this.setState({
                error: null
            });

            this.getCarriers();
        }

        this.setState({ modal: !this.state.modal });
    }

    getCarriers = () => {
        axios.get("/api/printForm",
            {
                headers: {
                    'Content-Type': "application/json; charset=utf-8"
                }
            })
            .then((response) => {
                let clientId = '';

                if (response.data && response.data.length > 0) {
                    clientId = response.data[0].id;
                }

                this.setState(state => ({
                    ...state,
                    error: null,
                    carriers: (response.data),
                    currentClientId: clientId
                }));
            })
            .catch((ex) => {
                if (ex.response && ex.response.data) {
                    this.setState({ error: ex.response.data });
                }
            });
    }

    handleDateChange = (e, { name, value }) => {
        this.setState(state => ({
            ...state,
            [name]: value
        }));
    }

    render() {
        return (
            <Modal open={this.state.modal} size="tiny"
                closeOnDimmerClick onClose={this.toggle}
                trigger={<div style={this.props.style} onClick={this.toggle}>Завантажити друковані форми</div>}
            >
                <Modal.Header>
                    Завантаження друкованих форм
                </Modal.Header>
                <Modal.Content>
                    <Form style={{ height: '200px' }}>
                        <Form.Group style={{ height: '50%' }}>
                            <Form.Field width={8}>
                                <div className="text-right" style={{ paddingTop: '8px' }}>
                                    {'Період по даті доставки:'}
                                </div>
                            </Form.Field>
                            <Form.Field width={4}>
                                <DatePicker
                                    fluid
                                    dateFormat="DD.MM.YY"
                                    placeholderText="дд.мм.рр"
                                    name="startDate"
                                    value={this.state.startDate}
                                    onChange={this.handleDateChange}
                                />
                            </Form.Field>
                            <Form.Field width={4}>
                                <DatePicker
                                    fluid
                                    dateFormat="DD.MM.YY"
                                    placeholderText="дд.мм.рр"
                                    name="endDate"
                                    value={this.state.endDate}
                                    onChange={this.handleDateChange}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={10}>
                                <Dropdown
                                    fluid
                                    selection
                                    value={this.state.currentClientId}
                                    noResultsMessage="Нічого не знайдено"
                                    options={this.state.carriers.map(c => {
                                        return {
                                            value: c.id,
                                            text: c.name
                                        };
                                    })}
                                />
                            </Form.Field>
                            <Form.Field width={6}>
                                <ExportButton
                                    style={{
                                        margin: 0,
                                        width: '100%'
                                    }}
                                    disabled={!this.state.currentClientId}
                                    reportType='printForm'
                                    fileName='Друкована форма і план поставок'
                                    params={{
                                        from: formatDate(this.state.startDate),
                                        to: formatDate(this.state.endDate),
                                        carrierId: this.state.currentClientId
                                    }}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    {this.state.error
                        ? <Message error
                            content={this.state.error}
                        />
                        : null}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.toggle}>Закрити</Button>
                </Modal.Actions>
            </Modal>
        );


    }
};