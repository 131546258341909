import React, { useState, useEffect } from 'react';

import CircularProgress from '../../../components/CircularProgress';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Button from '../../../components/Button';

import {
    FilterName,
    Block,
    BlockName,
    BlockRow,
    BlockColumn,
    NumberInput
} from './TimelyDeliveryPage.style';

//TODO add prop-types

const AdminView = ({
    data,
    carrierName,
    onChange,
    onSave,
    onDelete
}) => {
    const [editAllowed, setEditAllowed] = useState(false);

    useEffect(() => {
        setEditAllowed(false);
    }, [data.clientId, data.carrierId]);

    const changeToDate = (v) => {
        if (!editAllowed)
            setEditAllowed(true);

        onChange({
            ...data,
            toDate: v
        });
    };

    const changeToMinute = (v) => {
        if (!editAllowed)
            setEditAllowed(true);

        onChange({
            ...data,
            toMinute: v
        });
    };

    const toggleApproved = () => {
        if (!editAllowed)
            setEditAllowed(true);

        onChange({
            ...data,
            clientApproved: !data.clientApproved
        });
    };

    const saveData = () => {
        if (data.toDate === null || data.toDate === 0
            || data.toMinute === null || data.toMinute === 0)
            return;

        setEditAllowed(false);
        onSave();
    };

    const clearData = () => {
        onDelete();

        onChange({
            ...data,
            id: '',
            toDate: null,
            toMinute: null,
            clientApproved: false
        });
    };


    let isEditable = !data.id || editAllowed;

    return (
        <>
            <Block>
                <BlockName>
                    Показатели своевременности доставок в {carrierName}
                </BlockName>
                <BlockRow minHeight='105px'>
                    <BlockColumn marginRight='66px'>
                        <FilterName>
                            «День в день»
                        </FilterName>
                        <NumberInput
                            disabled={!isEditable}
                            minValue={0}
                            maxValue={100}
                            value={data.toDate || 0}
                            onChange={changeToDate}
                        />
                    </BlockColumn>
                    {data.id
                        && <BlockColumn>
                            <CircularProgress
                                percent={data.toDate || 0}
                            />
                        </BlockColumn>
                    }
                </BlockRow>
                <BlockRow minHeight='105px'>
                    <BlockColumn marginRight='30px'>
                        <FilterName>
                            «Минута в минуту»
                        </FilterName>
                        <NumberInput
                            disabled={!isEditable}
                            minValue={0}
                            maxValue={100}
                            value={data.toMinute || 0}
                            onChange={changeToMinute}
                        />
                    </BlockColumn>
                    {data.id
                        && <BlockColumn>
                            <CircularProgress
                                percent={data.toMinute || 0}
                            />
                        </BlockColumn>
                    }
                </BlockRow>
                <BlockRow>
                    <BlockColumn>
                        <Checkbox
                            description='Подтверждено клиентом'
                            disabled={!isEditable}
                            checked={data.clientApproved}
                            onChange={toggleApproved}
                        />
                    </BlockColumn>
                </BlockRow>
                <BlockRow>
                    <BlockColumn
                        width='125px'
                        marginRight='30px'
                    >
                        {isEditable
                            ? <Button.Text
                                size='small'
                                content='Сохранить'
                                onClick={saveData}
                            />
                            : <Button.Text
                                size='small'
                                content='Изменить'
                                onClick={() => setEditAllowed(!editAllowed)}
                            />
                        }
                    </BlockColumn>
                    <BlockColumn width='125px'>
                        <Button.Text
                            size='small'
                            content='Очистить'
                            onClick={clearData}
                        />
                    </BlockColumn>
                </BlockRow>
            </Block>
        </>
    );
};

export default AdminView;