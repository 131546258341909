import React from 'react';
import { Modal, Button, Message } from 'semantic-ui-react';
import axios from 'axios';

export default class ImportAdvisingData extends React.Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.import = this.import.bind(this);
    }

    state = {
        modal: false,
        error: '',
        file: {},
        fileSelected: false,
        uploading: false
    };

    toggle() {
        if (!this.state.modal) {
            this.setState({
                error: null,
                file: {},
                fileSelected: false
            });
        }

        this.setState({ modal: !this.state.modal });
    }

    import() {
        if (!this.state.fileSelected)
            return;

        let form = new FormData();
        form.append('file', this.state.file);

        this.setState({ uploading: true }, () => {
            axios.post(`/api/advisingReport/import/${this.props.type}`, form,
                {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    }
                })
                .then(() => {
                    this.toggle();
                    this.setState({ error: null, uploading: false });

                    if (window.location.href.includes('advisingReport')) {
                        window.location.reload();
                    }
                })
                .catch((ex) => {
                    if (ex.response && ex.response.data) {
                        this.setState({ error: ex.response.data, uploading: false });
                    }
                });
        });
    }

    onFileChange(e) {
        let files = e.target.files;

        this.setState({
            error: null,
            file: files[0],
            fileSelected: true
        });

    }

    render() {
        return (
            <Modal open={this.state.modal} size='tiny'
                closeOnDimmerClick onClose={this.toggle}
                trigger={<div style={this.props.style} onClick={this.toggle}>Загрузить данные {this.props.type === 'timeSlot' ? 'по тайм-слот' : 'для авизации'}</div>}
            >
                <Modal.Header>Загрузка данных
                </Modal.Header>
                <Modal.Content>
                    <input type='file' name='file' onChange={this.onFileChange} />
                    {this.state.error
                        ? <Message error
                            content={this.state.error}
                        />
                        : null}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.toggle}>Закрыть</Button>
                    <Button disabled={!this.state.fileSelected || this.state.uploading} loading={this.state.uploading} primary onClick={this.import}>Импортировать</Button>
                </Modal.Actions>
            </Modal>
        );


    }
};