export default {

    getObject: function (name) {
        let item = localStorage.getItem(name);
        if (item) {
            return JSON.parse(item);
        }
        return null;
    },

    setObject: function (name, value) {
        localStorage.setItem(name, JSON.stringify(value));
        return value;
    },

    getFilter: function (filterName) {
        let item = localStorage.getItem(filterName);
        if (item) {
            return JSON.parse(item);
        }

        return null;
    },

    updateFilter: function (filterName, newVal) {

        let filter = this.getFilter(filterName);
        if (filter == null) filter = {};

        const keys = Object.keys(newVal);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            filter[key] = newVal[key];
        }

        localStorage.setItem(filterName, JSON.stringify(filter));

        return filter;
    }
};