import React, { useState, useEffect, useCallback, useRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import _validateInput from './utilities/validateInput';
import { getInitialState, getEmptyLine } from './utilities/initialValues';
import { splitMultiline } from './utilities/utilities';

import ReservationOrdersDetailsLine from './ReservationOrdersLine';
import { StyledReservationOrdersDetails } from './ReservationOrdersDetails.style';

import {
    PalletsCount,
    BoxesCount,
    Cost,
    Weight,
    TimeSlot,
    Comment,
} from './components/Inputs';

const strOrNum = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

const propTypes = {
    mode: PropTypes.oneOf(['default', 'demo', 'mass']),
    defaultValues: PropTypes.shape({
        palletCount: strOrNum,
        boxCount: strOrNum,
        cost: strOrNum,
        weight: strOrNum,
        orders: PropTypes.arrayOf(
            PropTypes.shape({
                palletFrom: strOrNum,
                palletTo: strOrNum,
                palletCount: strOrNum,
                weight: strOrNum,
                cost: strOrNum,
                boxCount: strOrNum,
                orderNumber: strOrNum,
                packingList: strOrNum,
                type: strOrNum,
            })
        )
    }),
    settings: PropTypes.shape({
        palletType: PropTypes.number,
        boxType: PropTypes.number,
        costType: PropTypes.number,
        weightType: PropTypes.number,
        orderType: PropTypes.number,
    }),
    isTander: PropTypes.bool,
    itemId: PropTypes.string,
    showFieldsErrors: PropTypes.bool,
    onChange: PropTypes.func,
};

const ReservationOrdersDetails = (props) => {
    const {
        mode = 'default',
        defaultValues,
        settings,
        itemId = '',
        showFieldsErrors = false,
        isTander = false,
        isFirst = false,
        onChange = () => { }
    } = props;

    const validateInput = useMemo(() => _validateInput(isTander), [isTander]);

    const [state, setState] = useState(getInitialState(settings, defaultValues));

    // TODO refactor: skip onChange call on initial state update
    const [isInitialState, setIsInitialState] = useState(true);

    const handleChange = useCallback(
        (e, { name, value: v }) => {
            const { value, error } = validateInput(name, v);

            setState(state => ({
                ...state,
                [name]: value,
                errors: {
                    ...state.errors,
                    [name]: error
                },
                onChangeErrors: {
                    ...state.onChangeErrors,
                    [name]: error
                }
            }));
        }, [settings],
    );

    const calcTotal = useCallback(({ name, orders }) => {
        let total;

        switch (name) {
            case ('palletCount'):
                total = '' + orders.reduce((sum, item) => sum + +item.palletCount, 0);
                break;

            case ('boxCount'):
                total = '' + orders.reduce((sum, item) => sum + +item.boxCount, 0);
                break;

            case ('cost'):
                total = '' + (orders.reduce((sum, item) => (sum + parseFloat(item.cost || 0) || 0), 0) || 0);
                break;

            case ('weight'):
                total = '' + orders.reduce((sum, item) => sum + +item.weight, 0);
                break;

            default:
                break;
        };

        if (total === undefined) return null;

        return {
            name,
            ...validateInput(name, total)
        };
    }, []);

    const handleLineItemChange = useCallback(
        (lineId, name, v) => {
            const splitted = (name === 'type' || v === '' || v.length < 3)
                ? []
                : splitMultiline(v);

            const updateLine = (currentLine, o) => ({
                ...currentLine,
                [name]: o.value,
                errors: {
                    ...(currentLine.errors || {}),
                    [name]: o.error
                },
                onChangeErrors: {
                    ...(currentLine.onChangeErrors || {}),
                    [name]: o.error
                }
            });

            // Multiline
            if (splitted.length > 1) {
                setState(state => {
                    const { orders } = state;

                    const ordersToUpdate = orders.slice(lineId);
                    const validatedInputs = splitted.map(item => validateInput(name, item.trim()));
                    const firstLine = orders[lineId];

                    const updatedOrders = validatedInputs.length > ordersToUpdate.length
                        ? validatedInputs.map((o) => {
                            const currentLine = orders[lineId];

                            let errors = { ...currentLine.errors };
                            let valuesToClone = {};

                            if (currentLine.palletFrom !== undefined) {
                                valuesToClone.palletFrom = currentLine.palletFrom;
                            }

                            if (currentLine.palletTo !== undefined) {
                                valuesToClone.palletTo = currentLine.palletTo;
                            }

                            if (currentLine.palletCount !== undefined) {
                                valuesToClone.palletCount = currentLine.palletCount;
                            }

                            if (currentLine.boxCount !== undefined) {
                                valuesToClone.boxCount = '';
                                errors.boxCount = true;
                            }

                            if (currentLine.cost !== undefined) {
                                valuesToClone.cost = '';
                                errors.cost = true;
                            }

                            if (currentLine.weight !== undefined) {
                                valuesToClone.weight = '';
                                errors.weight = true;
                            }

                            if (currentLine.type !== undefined) {
                                valuesToClone.type = currentLine.type;
                            }

                            valuesToClone.errors = errors;

                            const result = updateLine(valuesToClone, o);

                            if (result.orderNumber === undefined) {
                                result.orderNumber = '';
                                result.errors.orderNumber = true;
                            }

                            if (result.packingList === undefined) {
                                result.packingList = '';
                                result.errors.packingList = true;
                            }

                            return result;
                        })
                        : ordersToUpdate.map((currentLine, index) => {
                            const {
                                palletFrom,
                                palletTo,
                                palletCount,
                                type
                            } = currentLine;

                            const o = validatedInputs[index] || getEmptyLine({ palletFrom, palletTo, palletCount, type });

                            const result = updateLine(currentLine, o);

                            if (result.orderNumber === undefined) {
                                result.orderNumber = '';
                            }

                            if (result.packingList === undefined) {
                                result.packingList = '';
                            }

                            return result;
                        });

                    updatedOrders[0] = updateLine(firstLine, validatedInputs[0]);

                    const newOrders = [
                        ...orders.slice(0, lineId),
                        ...updatedOrders
                    ];

                    const total = calcTotal({ name, orders: newOrders });

                    const palletCountTotal = name !== 'palletCount' && firstLine.palletCount !== undefined
                        ? calcTotal({ name: 'palletCount', orders: newOrders })
                        : null;

                    const result = {
                        ...state,
                        orders: newOrders,
                        ...(palletCountTotal ? {
                            [palletCountTotal.name]: palletCountTotal.value,
                            errors: {
                                ...state.errors,
                                [palletCountTotal.name]: palletCountTotal.error
                            },
                            onChangeErrors: {
                                ...state.onChangeErrors,
                                [palletCountTotal.name]: palletCountTotal.error
                            }
                        } : {})
                    };

                    return ({
                        ...result,
                        ...(total ? {
                            [total.name]: total.value,
                            errors: {
                                ...result.errors,
                                [total.name]: total.error
                            },
                            onChangeErrors: {
                                ...result.onChangeErrors,
                                [total.name]: total.error
                            }
                        } : {}),
                    });
                });

                return;
            };

            // Default
            setState(state => {
                const { orders } = state;
                const line = orders[lineId];

                const newOrder = updateLine(line, validateInput(name, v));

                const newOrders = [
                    ...orders.slice(0, lineId),
                    newOrder,
                    ...orders.slice(lineId + 1)
                ];

                const total = calcTotal({ name, orders: newOrders });

                return ({
                    ...state,
                    orders: newOrders,

                    ...(total ? {
                        [total.name]: total.value,
                        errors: {
                            ...state.errors,
                            [total.name]: total.error
                        },
                        onChangeErrors: {
                            ...state.onChangeErrors,
                            [total.name]: total.error
                        }
                    } : {})
                });
            });
        }, [settings]
    );

    const handleCommentChange = useCallback(
        (e) => {
            const name = 'comment';
            const { value: v } = e.target;

            const { value, error } = validateInput(name, v);

            setState(state => ({
                ...state,
                [name]: value,
                errors: {
                    ...state.errors,
                    [name]: error
                }
            }));
        },
        []
    );

    const handleAddLine = useCallback(
        () => {
            setState(state => {
                const { palletCount, palletFrom, palletTo, type } = state.orders[state.orders.length - 1];

                const newOrder = {
                    ...getEmptyLine({ palletFrom, palletTo, palletCount, type }),
                    palletFrom,
                    palletTo
                };

                const newOrders = state.orders.concat(newOrder);

                const palletCountTotal = settings && settings.palletType === 1 && palletCount !== undefined
                    ? calcTotal({ name: 'palletCount', orders: newOrders })
                    : null;

                return ({
                    ...state,
                    orders: newOrders,
                    ...(palletCountTotal ? {
                        [palletCountTotal.name]: palletCountTotal.value,
                        errors: {
                            ...state.errors,
                            [palletCountTotal.name]: palletCountTotal.error
                        },
                        onChangeErrors: {
                            ...state.onChangeErrors,
                            [palletCountTotal.name]: palletCountTotal.error
                        }
                    } : {})
                });
            });
        }, []
    );

    const handleRemoveLine = useCallback(
        (lineId) => {
            setState(state => {
                const { orders } = state;

                const newOrders = [...orders.slice(0, lineId), ...orders.slice(lineId + 1)];

                const newState = {
                    ...state,
                    orders: newOrders
                };

                let errors = { ...state.errors };
                let onChangeErrors = { ...state.onChangeErrors };

                const addTotal = (name) => {
                    const total = calcTotal({ name, orders: newOrders });

                    newState[total.name] = total.value;
                    errors[total.name] = total.error;
                    onChangeErrors[total.name] = total.error;
                };

                if (settings.palletType === 1) {
                    addTotal('palletCount');
                }

                if (settings.boxType === 2) {
                    addTotal('boxCount');
                }

                if (settings.costType === 1) {
                    addTotal('cost');
                }

                if (settings.weightType === 1) {
                    addTotal('weight');
                }

                newState.errors = errors;
                newState.onChangeErrors = onChangeErrors;

                return newState;
            });
        }, [settings],
    );

    useEffect(
        () => {
            // TODO refactor
            if (isInitialState) {
                setIsInitialState(false);
            } else {
                onChange({ itemId, values: state });
            }
        },
        [state]
    );

    useEffect(() => {
        if (isFirst && inputEl && inputEl.current) {
            inputEl.current.focus();
        }
    }, [isFirst]);

    useEffect(() => {
        if (mode !== 'demo') return;

        setState(getInitialState(settings));
    }, [settings]);

    const inputEl = useRef(null);

    const {
        palletCount,
        boxCount,
        cost,
        weight,
        orders,
        timeSlot,
        comment,
        errors,
        onChangeErrors
    } = state;

    const mass = mode === 'mass';

    return (
        <StyledReservationOrdersDetails
            demo={mode === 'demo'}
            mass={mass}
        >
            {settings && (
                <>
                    <div className="reservation-pallets-details__top">
                        <PalletsCount
                            labeled
                            error={onChangeErrors.palletCount || (showFieldsErrors && errors.palletCount)}
                            name="palletCount"
                            value={palletCount}
                            overflowed={palletCount && palletCount > 100}
                            disable={settings.palletType === 1}
                            onChange={handleChange}
                        />
                        {settings.boxType !== 0 && (
                            <BoxesCount
                                labeled
                                error={onChangeErrors.boxCount || (showFieldsErrors && errors.boxCount)}
                                name="boxCount"
                                disable={settings.boxType === 2}
                                value={boxCount}
                                onChange={handleChange}
                            />
                        )}
                        <Cost
                            labeled
                            error={onChangeErrors.cost || (showFieldsErrors && errors.cost)}
                            name="cost"
                            value={cost}
                            disable={settings.costType === 1}
                            onChange={handleChange}
                        />
                        <Weight
                            labeled
                            error={onChangeErrors.weight || (showFieldsErrors && errors.weight)}
                            name="weight"
                            value={weight}
                            totalOverweight={weight > 60000}
                            palletOverweight={!!(palletCount && weight && (weight / palletCount > 650))}
                            disable={settings.weightType === 1}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="reservation-pallets-details__orders">
                        {orders && orders.length && orders.map((subitem, index) => (
                            <ReservationOrdersDetailsLine
                                {...subitem}
                                mass={mass}
                                key={index}
                                id={index}
                                showFieldsErrors={showFieldsErrors}
                                firstLine={index === 0}
                                labeled={index === 0}
                                settings={settings}
                                onLineItemChange={handleLineItemChange}
                                onRemoveLine={() => (orders.length === 1
                                    ? null
                                    : handleRemoveLine(index)
                                )}
                                onAddLine={handleAddLine}
                            />
                        ))}
                    </div>
                    {mass && (
                        <>
                            <TimeSlot
                                labeled
                                value={timeSlot}
                                error={onChangeErrors.timeSlot || (showFieldsErrors && errors.timeSlot)}
                                onChange={handleChange}
                            />
                            <Comment
                                labeled
                                value={comment}
                                onChange={handleCommentChange}
                            />
                        </>
                    )}
                </>
            )}
        </StyledReservationOrdersDetails >
    );
};

ReservationOrdersDetails.propTypes = propTypes;
export default memo(ReservationOrdersDetails);