import React from 'react';
import { Dropdown, Dimmer, Loader, Table } from 'semantic-ui-react';
import ExportButton from '../../components/Button/ExportButton';
import { callApiAsync } from '../../hooks';
import { sortClients } from '../../utils';

export default class NonActiveConsignorStats extends React.Component {
    state = {
        isLoading: false,

        consignors: [],
        totalCount: 0,

        clients: [],
        years: [],
        weekNumbers: [],

        filter: {
            clientId: '',
            year: 0,
            weekNumberFrom: 0
        },

        exportEnabled: false
    };

    componentDidMount() {
        this.init();
    }

    init = async () => {
        const clients = await callApiAsync("/api/definitions/clients", "GET");
        const sortedClients = sortClients(clients);

        const filter = {
            clientId: ((sortedClients || [])[0] || {}).id || '',
            year: 0,
            weekNumberFrom: 0
        };

        this.setState({ filter }, this.reload);
    }

    reload = async () => {
        this.setState({ exportEnabled: false });
        let model = {
            clientId: this.state.filter.clientId,
            year: this.state.filter.year,
            weekNumberFrom: this.state.filter.weekNumberFrom
        };

        let data = await callApiAsync('api/stats/nonActiveConsignorList', 'POST', model);
        this.setState({
            ...data,
            clients: sortClients(data.clients),
            exportEnabled: true
        });
    }

    updateFilterValue(value) {
        const updatedFilter = Object.assign({}, this.state.filter, value);

        this.setState({
            filter: updatedFilter
        }, this.reload);
    }

    handleFilterChange(obj) {
        let field = obj.name;
        let value = obj.value;

        if (this.state.filter[field] === value)
            return;

        this.updateFilterValue({ [field]: value });
    }

    result() {

        return this.state.consignors.map((consignor, index) => {

            return (
                <Table.Row key={index}>
                    <Table.Cell>
                        {consignor}
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    render() {
        return (
            <div className="container">
                <Dimmer inverted active={this.state.isLoading}>
                    <Loader inverted />
                </Dimmer>
                <div className="row">

                    <div className="col-3">
                        <label>Клиент</label>
                        <Dropdown fluid selection name='clientId'
                            noResultsMessage="Ничего не найдено"
                            onChange={(e, obj) => this.handleFilterChange(obj)} value={this.state.filter.clientId}
                            options={(this.state.clients.map(x => {
                                return { text: x.name, value: x.id };
                            }))} />
                    </div>

                    <div className="col-3">
                        <label>Год</label>
                        <Dropdown fluid selection name='year'
                            noResultsMessage="Ничего не найдено"
                            onChange={(e, obj) => this.handleFilterChange(obj)} value={this.state.filter.year}
                            options={(this.state.years.map(x => {
                                return { text: x, value: x };
                            }))} />
                    </div>

                    <div className="col-3">
                        <label>Неделя</label>
                        <Dropdown fluid selection name='weekNumberFrom'
                            noResultsMessage="Ничего не найдено"
                            onChange={(e, obj) => this.handleFilterChange(obj)} value={this.state.filter.weekNumberFrom}
                            options={(this.state.weekNumbers.map(x => {
                                return { text: x, value: x };
                            }))} />
                    </div>
                    <div className="col-9">
                        <div style={{ padding: 26 }}>
                            <ExportButton
                                disabled={!this.state.exportEnabled}
                                reportType='consignorNotActiveStat'
                                params={{
                                    weekFrom: this.state.filter.weekNumberFrom,
                                    weekTo: this.state.filter.weekNumberTo,
                                    year: this.state.filter.year,
                                    clientId: this.state.filter.clientId
                                }}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className="table-bordered table-sm w-25">
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell>
                                Поставщик
                            </Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {this.result()}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }
};