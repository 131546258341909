import './MailingTab.style.css';

import React, { useState, useEffect, useContext } from 'react';
import { callApiAsync } from '../../../../hooks';
import { ProfileContext as Profile } from '../../../../context';

import Accordion from '../../../../components/Accordion/Accordion';
import Button from '../../../../components/Button';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import PoolingCheckbox from '../../../../components/Checkbox/Checkbox';
import Svg from '../../../../components/Svg';
import { Modal, Checkbox } from 'semantic-ui-react';
import { sortClients } from '../../../../utils';

const useOptions = () => {
    const [regionOptions, setRegionOptions] = useState([]);
    const [regionId, setRegionId] = useState('');
    const loadRegions = async () => {
        let data = await callApiAsync('api/regions', 'GET');

        setRegionOptions(data);
        setRegionId(data[0].id);
    };
    useEffect(() => { loadRegions(); }, []);


    const [clientOptions, setClientOptions] = useState([]);
    const loadClients = async () => {
        let data = await callApiAsync('api/notification/options/clients', 'GET');

        setClientOptions(sortClients(data));
    };
    useEffect(() => { loadClients(); }, []);


    const [carrierOptions, setCarrierOptions] = useState([]);
    const loadCarriers = async () => {
        let data = await callApiAsync('api/notification/options/carriers', 'GET');

        setCarrierOptions(data);
    };
    useEffect(() => { loadCarriers(); }, []);


    const [dcOptions, setDcOptions] = useState([]);
    const loadDcs = async () => {
        let data = await callApiAsync('api/notification/options/dcs', 'GET');

        setDcOptions(data);
    };
    useEffect(() => { loadDcs(); }, []);


    return [regionOptions, regionId, setRegionId, clientOptions, carrierOptions, dcOptions];
};

const useAlmostPoolingRules = () => {
    const [rules, setRules] = useState([]);

    const validateRule = (index, field) => {
        let array = [...rules];
        let rule = array[index];

        rule[field + 'Valid'] = rule[field].length > 0;

        setRules(array);
    };

    const onChange = async (id, field, value) => {
        let array = [...rules];
        let index = array.findIndex(r => r.id === id);
        let rule = array[index];

        // Validating checkboxes
        if (field === 'carTypes' || field === 'days') {
            rule[field + 'Valid'] = value.length > 0;

            setTimeout(() => validateRule(index, field), 2000);
        }

        if (rule.carTypesValid && rule.daysValid) {
            if (field === 'clientId' && rule[field] !== value)
                rule.distributionCenterIds = [];

            rule[field] = value;
            await onSave(rule);
        }

        setRules(array);
    };

    const loadAsync = async () => {
        let data = await callApiAsync('/api/notification/almost-pooling', 'GET', undefined);
        for (let i = 0; i < data.length; i++) {
            data[i].carTypesValid = true;
            data[i].daysValid = true;
        }

        setRules(data);
    };

    useEffect(() => { loadAsync(); }, []);

    const onAdd = async (rule) => {
        await callApiAsync(`/api/notification/almost-pooling`, 'POST', rule);
        await loadAsync();
    };

    const onDelete = async (id) => {
        await callApiAsync(`/api/notification/almost-pooling/${id}`, 'DELETE', undefined);
        await loadAsync();
    };

    const onSave = async (rule) => {
        await callApiAsync('/api/notification/almost-pooling', 'POST', rule);
    };


    return [rules, setRules, onChange, onAdd, onDelete];
};

const useLoaders = (fields, onChangeCallback) => {
    const initialState = fields.map(f => ({ type: f, state: 'none' }));
    const [loaders, setLoaders] = useState(initialState);

    const isFieldStateEquals = (field, state) => loaders.find(l => l.type === field).state === state;
    const reset = () => setLoaders(initialState);

    const onLoaderChange = (field, newState) => {
        let array = [...loaders];
        array.find(l => l.type === field).state = newState;

        setLoaders(array);
    };

    const onFieldChange = async (id, field, value) => {
        // Setting up state to 'SENT' as we sending request
        onLoaderChange(field, 'sent');

        // If request continues more than a second setting up 'LOADING'
        setTimeout(() => {
            if (isFieldStateEquals(field, 'sent'))
                onLoaderChange(field, 'loading');
        }, 1000);

        await onChangeCallback(id, field, value);

        // After response come setting up 'NONE'
        onLoaderChange(field, 'none');
    };

    return [onFieldChange, isFieldStateEquals, reset];
};

const MailingTab = () => {
    // Modal
    const [open, setOpen] = useState(false);

    // Profile data for controlling Profile.ReceiveEmails toggle
    const { profile, updateProfile } = useContext(Profile);
    const toggleConfirmations = async () => {
        await callApiAsync('api/profile/toggle', 'GET', undefined);
        await updateProfile();
    };

    // Almost Pooling options for Dropdowns
    const [regionOptions, regionId, setRegionId, clientOptions, carrierOptions, dcOptions] = useOptions();

    // Almost Pooling Rules data
    const [rules, setRules, onRuleChange, onRuleAdd, onRuleDelete] = useAlmostPoolingRules();

    // Loaders
    const [onRuleChangeWithLoading, isLoaderStateEquals, resetLoaders] = useLoaders(
        ['carrierId', 'clientId', 'carTypes', 'days', 'distributionCenterIds'],
        onRuleChange
    );


    const onArrayChange = async (id, field, value) => {
        let rule = rules.find(r => r.id === id);
        let array = [...rule[field]];

        let valueIndex = array.indexOf(value);
        if (valueIndex > -1) {
            array.splice(valueIndex, 1);
        } else {
            array.push(value);
        }

        await onRuleChangeWithLoading(id, field, array);
    };

    let regionRules = rules.filter(r => r.regionId === regionId);

    const addNewRule = () => {
        let rule = {
            active: true,
            regionId: regionId,
            clientId: clientOptions[0].id,
            carrierId: carrierOptions[0].id,
            distributionCenterIds: [],
            productType: '',
            carTypes: ['Ref', 'Tent'],
            carTypesValid: true,
            days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            daysValid: true
        };

        // Setting rule before posting to server for faster rendering
        setRules([...rules, rule]);

        onRuleAdd(rule);
    };

    return (
        <>
            <Accordion title='Повідомлення про майже набраний пулінг'>
                <div id='region-selection'>
                    <label>Регіон відвантаження</label>
                    <Dropdown
                        noResultsMessage="Нічого не знайдено"
                        fluid
                        placeholder='Вибрати регіон'
                        value={regionId}
                        onChange={(v) => {
                            setRegionId(v);
                            resetLoaders();
                        }}
                        options={regionOptions.map(o => { return { value: o.id, text: o.name }; })}
                    />
                </div>
                {regionRules.length === 0
                    ? <>
                        <div style={{ marginBottom: '30px' }}>
                            <p>Ви ще не отримуєте повідомлення про набраний пулінг для даного регіону?</p>
                            <p>Щоб отримувати повідомлення, натистіть кнопку "Додати перевезення" і вкажіть параметри перевезень</p>
                        </div>
                        <Button.Text
                            size='large'
                            content='Додати перевезення'
                            onClick={addNewRule}
                        />
                    </>
                    : regionRules.map((rule, index) => {
                        return (<React.Fragment key={rule.id}>
                            <div className='pooling__segment mailing-grid-wrapper'>
                                <div className='carrier'>
                                    <label>
                                        Транспортна компанія
                                        {isLoaderStateEquals('carrierId', 'loading') ? <Svg type='loader' /> : ''}
                                    </label>
                                    <Dropdown
                                        noResultsMessage="Нічого не знайдено"
                                        fluid
                                        placeholder='Виберіть ТК'
                                        value={rule.carrierId}
                                        onChange={(v) => onRuleChangeWithLoading(rule.id, 'carrierId', v)}
                                        options={carrierOptions.map(o => { return { value: o.id, text: o.name }; })}
                                    />
                                </div>
                                <div className='client'>
                                    <label>
                                        Клієнт
                                        {isLoaderStateEquals('clientId', 'loading') ? <Svg type='loader' /> : ''}
                                    </label>
                                    <Dropdown
                                        noResultsMessage="Нічого не знайдено"
                                        fluid
                                        placeholder='Вибрати мережу'
                                        value={rule.clientId}
                                        onChange={(v) => { onRuleChangeWithLoading(rule.id, 'clientId', v); }}
                                        options={clientOptions.map(o => { return { value: o.id, text: o.name }; })}
                                    />
                                </div>
                                <div className='car-type'>
                                    {!rule.carTypesValid
                                        ? <label
                                            className='error description'
                                            style={{ marginTop: '-1em' }}
                                        >
                                            Виберіть хоча б один варіант
                                        </label>
                                        : null
                                    }
                                    <label className={!rule.carTypesValid ? 'error' : ''}>
                                        Кузов
                                        {isLoaderStateEquals('carTypes', 'loading') ? <Svg type='loader' /> : ''}
                                    </label>
                                    <div>
                                        <PoolingCheckbox
                                            description='Реф'
                                            checked={rule.carTypes.includes('Ref')}
                                            onChange={() => onArrayChange(rule.id, 'carTypes', 'Ref')}
                                        />
                                        <PoolingCheckbox
                                            description='Тент'
                                            checked={rule.carTypes.includes('Tent')}
                                            onChange={() => onArrayChange(rule.id, 'carTypes', 'Tent')}
                                        />
                                    </div>
                                </div>
                                <div className='product-type'>
                                    <label>Вантаж</label>
                                    <label style={{ color: '#135CA9', margin: '11px 0' }}>
                                        {rule.productType}
                                    </label>
                                </div>
                                <div className='days'>
                                    {!rule.daysValid
                                        ? <label
                                            className='error description'
                                            style={{ marginTop: '-1em' }}
                                        >
                                            Виберіть хоча б один варіант
                                        </label>
                                        : null
                                    }
                                    <label className={!rule.daysValid ? 'error' : ''}>
                                        Дні доставки по графіку ТК
                                        {isLoaderStateEquals('days', 'loading') ? <Svg type='loader' /> : ''}
                                    </label>
                                    <div>
                                        <PoolingCheckbox
                                            description='Пн'
                                            checked={rule.days.includes('Monday')}
                                            onChange={() => onArrayChange(rule.id, 'days', 'Monday')}
                                        />
                                        <PoolingCheckbox
                                            description='Вт'
                                            checked={rule.days.includes('Tuesday')}
                                            onChange={() => onArrayChange(rule.id, 'days', 'Tuesday')}
                                        />
                                        <PoolingCheckbox
                                            description='Ср'
                                            checked={rule.days.includes('Wednesday')}
                                            onChange={() => onArrayChange(rule.id, 'days', 'Wednesday')}
                                        />
                                        <PoolingCheckbox
                                            description='Чт'
                                            checked={rule.days.includes('Thursday')}
                                            onChange={() => onArrayChange(rule.id, 'days', 'Thursday')}
                                        />
                                        <PoolingCheckbox
                                            description='Пт'
                                            checked={rule.days.includes('Friday')}
                                            onChange={() => onArrayChange(rule.id, 'days', 'Friday')}
                                        />
                                        <PoolingCheckbox
                                            description='Сб'
                                            checked={rule.days.includes('Saturday')}
                                            onChange={() => onArrayChange(rule.id, 'days', 'Saturday')}
                                        />
                                        <PoolingCheckbox
                                            description='Нд'
                                            checked={rule.days.includes('Sunday')}
                                            onChange={() => onArrayChange(rule.id, 'days', 'Sunday')}
                                        />
                                    </div>
                                </div>
                                <div className='distribution'>
                                    <label>
                                        Розподільчі центри
                                        {isLoaderStateEquals('distributionCenterIds', 'loading') ? <Svg type='loader' /> : ''}
                                    </label>
                                    <Dropdown
                                        noResultsMessage="Нічого не знайдено"
                                        multiple
                                        search
                                        fluid
                                        value={rule.distributionCenterIds}
                                        onChange={(v) => onRuleChangeWithLoading(rule.id, 'distributionCenterIds', v)}
                                        placeholder='Введіть РЦ'
                                        options={
                                            dcOptions.filter(dc => dc.clientId === rule.clientId)
                                                .map(o => ({ value: o.id, text: o.name }))
                                                .sort((a, b) => a.text.localeCompare(b.text))
                                        }
                                    />
                                </div>
                                <div className='toggle'>
                                    <Checkbox
                                        toggle
                                        checked={rule.active}
                                        onChange={() => onRuleChange(rule.id, 'active', !rule.active)}
                                        label='Отримувати повідомлення по цим перевезенням'
                                    />
                                </div>
                                <div
                                    className='delete'
                                    onClick={() => setOpen(true)}
                                >
                                    <Svg
                                        type='cross'
                                        width={30}
                                        height={30}
                                    />
                                </div>
                            </div>
                            <Modal
                                size='mini'
                                open={open}
                                onClose={() => setOpen(false)}
                            >
                                <Modal.Content>
                                    <div className='modal-headtext'>Видалити вибране правило?</div>
                                    <div className='modal-buttons'>
                                        <Button.Text
                                            size='small'
                                            content='Видалити'
                                            onClick={() => {
                                                onRuleDelete(rule.id);
                                                setOpen(false);
                                            }}
                                        />
                                        <Button.Text
                                            size='small'
                                            content='Відміна'
                                            onClick={() => setOpen(false)}
                                        />
                                    </div>
                                </Modal.Content>
                            </Modal>
                        </React.Fragment>);
                    })
                }
            </Accordion>
            <Accordion
                defaultOpen
                title='Повідомити про підтвердження бронювання'
            >
                <Checkbox
                    toggle
                    checked={profile.receiveConfirmations}
                    onChange={toggleConfirmations}
                    label='Отримувати повідомлення про підтверждення бронювання на E-mail'
                />
            </Accordion>
        </>
    );
};

export default MailingTab;