import React from 'react';

import CircularProgress from '../../../components/CircularProgress';

import {
    FilterName,
    Block,
    BlockName,
    BlockRow,
    BlockColumn,
    ProgressWrapper,
    ProgressBar,
    ProgressName,
} from './TimelyDeliveryPage.style';

//TODO add prop-types

const UsualView = ({
    data,
    clientName, carrierName
}) => {

    // No Data View
    if (!data.id) {
        return (
            <Block>
                <BlockName>
                    Показники своєчасності доставок в {carrierName}
                </BlockName>
                <BlockRow>
                    <FilterName>
                        Для вказаного клієнта немає даних щодо своєчасності доставки
                    </FilterName>
                </BlockRow>
            </Block>
        );
    }

    return (
        <>
            <Block>
                <BlockName>
                    Показники своєчасності доставок в {carrierName}
                </BlockName>
                <BlockRow>
                    <BlockColumn>
                        <ProgressWrapper>
                            <ProgressBar>
                                <CircularProgress
                                    percent={data.toDate}
                                />
                            </ProgressBar>
                            <ProgressName>
                                "День в день"
                            </ProgressName>
                        </ProgressWrapper>
                    </BlockColumn>
                    <BlockColumn>
                        <ProgressWrapper>
                            <ProgressBar>
                                <CircularProgress
                                    percent={data.toMinute}
                                />
                            </ProgressBar>
                            <ProgressName>
                                "Хвилина в хвилину"
                            </ProgressName>
                        </ProgressWrapper>
                    </BlockColumn>
                </BlockRow>
            </Block>
            {data.clientApproved
                && <Block>
                    <BlockName>
                        Підтверджено {clientName}
                    </BlockName>
                    <BlockRow>
                        {data.clientLogoName
                            ? <img
                                style={{ maxWidth: 120 }}
                                alt=''
                                src={data.clientLogoName}
                            />
                            : null}
                    </BlockRow>
                </Block>
            }

        </>
    );
};

export default UsualView;