import axios from 'axios';
import tokenService from './tokenService';
import { callApiAsync } from '../hooks';

export const updateAccessToken = async (setUser) => {
    try {
        let data = await refresh();
        if (data && data.userData) {
            setUser(data.userData);
            setupAxios(() => setUser(undefined));
        }
    }
    catch { }
};

export const login = async (email, pass, setUser) => {
    try {
        let data = await callApiAsync('api/identity/login', 'POST', { username: email, password: pass });
        tokenService.saveTokens(data);
        setupAxios(() => setUser(undefined));
        if (data && data.userData) {
            setUser(data.userData);
            return undefined;
        }
        return data;
    }
    catch (err) {
        tokenService.clear();
        return err && err.message;
    }
};

export const logout = (setUser) => {
    let refreshToken = tokenService.getRefreshToken();
    if (refreshToken)
        try {
            callApiAsync('/api/identity/logout', "POST", refreshToken);
        }
        catch{ }
    tokenService.clear();
    setUser(undefined);
};

const refresh = async (dropUser) => {
    let refreshToken = tokenService.getRefreshToken();
    if (refreshToken && !tokenService.isRefreshTokenExpired()) {
        try {
            let data = await callApiAsync('/api/identity/refresh', "POST", refreshToken);
            tokenService.saveTokens(data);
            return data;
        }
        catch (err) {
            if (err && err.response && err.response.status === 401) {
                if (dropUser !== undefined)
                    dropUser();
                tokenService.clear();
            }
            throw err;
        }
    }
    tokenService.clear();
    if (dropUser !== undefined)
        dropUser();
    throw new Error();
};

const setupAxios = (dropUser) => {
    axios.interceptors.response.handlers = [];
    axios.interceptors.request.handlers = [];
    createResponseInterceptor(axios, refresh, dropUser);
    createRequestInterceptor(axios, refresh, dropUser);
};

const statusCodes = [401];
const exceptions = ["identity/refresh", "identity/login"];

const createResponseInterceptor = (axios, refreshTokenCall, dropUser) => {
    const id = axios.interceptors.response.use(res => res, async error => {
        // Remove the interceptor to prevent a loop
        axios.interceptors.response.eject(id);
        axios.interceptors.response.handlers = [];

        if (!error.response
            || (error.response.status && statusCodes.indexOf(+error.response.status) === -1)
            || exceptions.some(x => error.request.responseURL.includes(x))) {
            createResponseInterceptor(axios, refreshTokenCall, dropUser);
            throw error;
        }

        const refreshCall = refreshTokenCall(dropUser);

        // Create interceptor that will bind all the others requests
        // until refreshTokenCall is resolved
        const requestQueueInterceptorId = axios.interceptors
            .request
            .use(request => refreshCall.then(() => request));

        try {
            const data = await refreshCall;
            error.response.config.headers['Authorization'] = 'Bearer ' + data.accessToken;
            createResponseInterceptor(axios, refreshTokenCall, dropUser);
        }
        catch (err) { }
        finally {
            axios.interceptors.request.eject(requestQueueInterceptorId);
            return axios(error.response.config);
        }
    });
    return axios;
};

//variable for checking is refresh is going
let refreshCall = undefined;

const createRequestInterceptor = (axios, refreshTokenCall, dropUser) => {
    axios.interceptors.request.use(async request => {
        if (exceptions.some(x => request.url.includes(x)))
            return request;
        //wait till refresh ends
        if (refreshCall !== undefined)
            await refreshCall;

        const accessToken = tokenService.getAccessToken();
        if (accessToken)
            if (!tokenService.isAccessTokenExpired()) {
                request.headers['Authorization'] = "Bearer " + accessToken;
            }
            else {
                try {
                    refreshCall = refreshTokenCall(dropUser);
                    const data = await refreshCall;
                    request.headers['Authorization'] = 'Bearer ' + data.accessToken;
                }
                catch (err) { }
                finally {
                    refreshCall = undefined;
                }
            }
        return request;
    });
    return axios;
};
