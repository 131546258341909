import React, { useEffect, useRef } from 'react';
import { Grid, Ref } from 'semantic-ui-react';
import RuleReservationModal from "../modals/RuleReservationModal";
import Map from '../../../components/Map/MapModal';
import Slot from './Slot';
import { throttle } from 'throttle-debounce';

const areEqual = (prevProps, nextProps) =>
    prevProps.slots.length === nextProps.slots.length &&
    (prevProps.slots[0] || {}).id === (nextProps.slots[0] || {}).id &&
    prevProps.header[0] === nextProps.header[0];

const Results = React.memo(props => {
    return <BaseResults {...props} />;
}, areEqual);

const BaseResults = ({
    user,
    slots,
    isTander,
    distributionCentersForTable,
    isLoading,
    header,
    showedType,
    search,
    isPrevWeek,
    isNextWeek
}) => {
    const headerRef = useRef();
    const fixedHeaderRef = useRef();

    const handleScroll = (event) => {
        if (!headerRef.current || !fixedHeaderRef.current)
            return;
        const headerPosition = headerRef.current.getBoundingClientRect();
        if (headerPosition.top < 0) {
            fixedHeaderRef.current.classList.add('shown');
        }
        else {
            fixedHeaderRef.current.classList.remove('shown');
        }
        if (headerPosition.left < 15)
            fixedHeaderRef.current.style.left = headerPosition.left + "px";
        else if (fixedHeaderRef.current.style.left)
            fixedHeaderRef.current.removeAttribute("style");
    };

    const throttleHandleScroll = throttle(40, handleScroll);

    useEffect(() => {
        document.addEventListener('scroll', throttleHandleScroll, true);
        return function cleanup() {
            document.removeEventListener('scroll', throttleHandleScroll, true);
        };
    });

    const dayHeaderRow = (index) => {
        let thisDay = header[index].itIsThisDay ? 'this-day' : '';
        return <Grid.Column style={{ textAlign: "center" }} key={index} className={thisDay}>{header[index].name}</Grid.Column>;
    };

    const currentDayNumber = (new Date()).getDay();

    const checkForPrevDay = (i) => {
        if (isPrevWeek) return true;
        if (isNextWeek
            || currentDayNumber === 1 // no previous days if it's monday
            || i === 6 // Sunday can't be a previous day
        ) return false;

        return i + 1 < currentDayNumber;
    };

    return <>
        <Grid celled style={{ background: "white" }}>
            <Grid.Row>
                <Grid.Column width={16} style={{ textAlign: "center" }}>
                    {user.isAuthorized() && isTander && user.getRole() === 'Вантажо відправник'
                        ? <a style={{ position: "absolute", left: 20 }}
                            href="https://magnit-service.ru/qlikview/" target="_blank"
                            rel="noopener noreferrer" >
                            <img style={{ height: 45 }} src="/img/magnit-service.png" alt="Ресурс онлайн відстеження доставки товарів в мережу" />
                        </a>
                        : null
                    }
                    <h3 style={{ margin: 0 }}>
                        Дні консолідації
                    </h3>
                    <RuleReservationModal />
                </Grid.Column>
            </Grid.Row>
            <Ref innerRef={headerRef}>
                <Grid.Row columns='equal'>
                    <Grid.Column />
                    {[0, 1, 2, 3, 4, 5, 6].map(i => dayHeaderRow(i))}
                </Grid.Row>
            </Ref>
            {distributionCentersForTable.length > 0
                ? distributionCentersForTable.map(distributionCenter => {
                    let localSlots = slots.filter(x =>
                        x.distributionCenterId === distributionCenter.id
                    );
                    return <Grid.Row columns='equal' key={distributionCenter.id + "row"}>
                        <Grid.Column>
                            <div className="dc-info" key={distributionCenter.id}>
                                <div>
                                    {distributionCenter.clientCompanyLogoName
                                        ? <i
                                            className='logo-company'
                                            style={{ background: `url(${distributionCenter.clientCompanyLogoName}) no-repeat left center` }}
                                        />
                                        : null}
                                    <h5>{distributionCenter.name}</h5>
                                    <Map address={distributionCenter.address}>
                                        {distributionCenter.address}
                                    </Map>
                                </div>
                            </div>
                        </Grid.Column>
                        {[0, 1, 2, 3, 4, 5, 6].map(i => {
                            const slot = localSlots.find(x => x.dayNumber === i);
                            return <Slot
                                key={(slot || {}).id || i}
                                slot={slot}
                                className={header[i].itIsThisDay ? 'this-day' : ''}
                                isAuthorized={user.isAuthorized()}
                                userRole={user.getRole()}
                                showedType={showedType}
                                isPrevDay={checkForPrevDay(i)}
                                onClose={search}
                            />;
                        }
                        )}
                    </Grid.Row>;
                })
                : <Grid.Row>
                    <Grid.Column width={16}>
                        {isLoading ? "Завантаження..." : "Немає слотів"}
                    </Grid.Column>
                </Grid.Row>
            }
        </Grid>
        <Ref innerRef={fixedHeaderRef}>
            <Grid celled className="fixed-header">
                <Grid.Row columns='equal'>
                    <Grid.Column />
                    {[0, 1, 2, 3, 4, 5, 6].map(i => dayHeaderRow(i))}
                </Grid.Row>
            </Grid>
        </Ref>

    </>;
};
export default Results;