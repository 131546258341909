import React from 'react';
import { Header } from 'semantic-ui-react';

export default class ruleReservation extends React.Component {

    render() {

        return (
            <div>
                <Header size='small' content='Загальні правила:' className='lvl one' />
                <p className='lvl two'>
                    1. У святкові дні, розклад розміщення заявок може змінюватись. Дані зміни анонсуються окремо.
                    <br />
                    2. Для дня консолідації в ПН, останній день розміщення заявки — ПТ.
                </p>
                <Header size='small' content='Food/Non-Food:' className='lvl one' />
                <p className='lvl two'>
                    Бронювання:
                    <br />
                    &bull; До 11 годин дня консолідації, у випадку самостійної доставки вантажу на склад консолідації.
                    <br />
                    &bull; До 11 годин дня консолідації, у випадку забору вантажу Перевізником з вашого складу.
                </p>
                <p className='lvl two'>
                    Зміна броні: до 12 дня доставки на склад консолідації, до 11 дня отгрузки с вашего склада.
                </p>
            </div>
        );
    }
};