import dictionary from '../dictionary.json';

const lang = "ru";

export const t = (key) => {
    const v = dictionary[key];

    if (!v) {
        console.error('Check lang key', key);

        return key;
    }

    return v[lang] || key;
};

export const strToNum = (str, accuracy = 0) => {
    if (str === '') return str;

    if (str === undefined) return '';

    if (typeof str === 'number') {
        return accuracy
            ? +str.toFixed(accuracy)
            : Math.floor(str);
    };

    if (!accuracy) return +str.replace(/[^0-9]/gi, '');

    const pre = str.replace(/[^0-9,.]/gi, '').replace(/,/g, '.').replace(/\.+/gi, '.');

    if (pre === '.' || pre === '') return pre;

    if (pre === 0) return +pre;

    const dotIndex = pre.indexOf('.');

    if (dotIndex === -1) return +pre;
    if (dotIndex === pre.length - 1) return pre;

    const secondDotIndex = pre.indexOf('.', dotIndex + 1);

    return +(pre.slice(0, (secondDotIndex === -1) ? (dotIndex + 1 + accuracy) : secondDotIndex));
};

export const splitMultiline = str => str.trim().split(/\t|\n|[,;]/);
export const splitRows = str => str.trim().split(/\n|[,;]/);
export const splitCols = str => str.trim().split(/\t/);