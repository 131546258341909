import React from 'react';
import {
    Wrapper,
    PreText,
    Input
} from './Input.style';


const splitMultipleValues = (value) => {
    let separators = ['\t', '\n', ',', ';'];
    let trimmed = value.trim();

    for (let sep of separators) {
        if (trimmed.indexOf(sep) > -1) {
            return trimmed.split(sep).map(v => v.trim());
        }
    }

    return [trimmed];
};

const Text = ({
    className = '',
    disabled = false,
    fluid = false,
    error = false,
    textAlign = 'left',
    placeholder = '',
    preText = '',
    prePlaceholder = '',
    width = undefined,
    value = '',
    onChange,
    onMultiInsert = undefined,
    onBlur = undefined,
    size = 'normal',
    style
}) => {
    const applyRule = (e) => {
        let values = splitMultipleValues(e.clipboardData.getData('Text'));
        if (!onMultiInsert || values.length === 1) {
            //onChange(values[0]);
            return;
        }

        e.preventDefault();
        onMultiInsert(values);
    };

    return (
        <Wrapper
            className={className}
            width={width}
            error={error}
            size={size}
            style={style}
        >
            {preText && (
                <PreText
                    active={value !== ''}
                >
                    {preText}
                </PreText>
            )}
            <Input
                fluid={fluid}

                placeholder={preText ? prePlaceholder : placeholder}
                disabled={disabled}
                textAlign={textAlign}
                value={value || ''}
                onChange={({ target }) => onChange(target.value)}
                onPaste={applyRule}
                onBlur={onBlur}
            />
        </Wrapper>
    );
};

const Time = ({
    disabled = false,
    error = false,
    className = '',
    width = undefined,
    value = '',
    onChange
}) => {
    const autofillValue = (v) => {
        if (/^\d$/g.test(v) && Number(v) > 2) {
            validateValue('0' + v + ':');
        } else if (/^$|^\d$|^\d:\d$|^\d:\d\d$|^\d\d$|^\d\d:$|^\d\d:\d$|^\d\d:\d\d$/g.test(v)) {
            validateValue(v);
        } else if (/^\d:$/g.test(v)) {
            validateValue('0' + v);
        } else if (/^\d\d\d$/g.test(v)) {
            if (Number(v.slice(0, 2)) > 23) {
                validateValue('0' + v.slice(0, 1) + ':' + v.slice(1, 3));
            }
            else if (Number(v.slice(1, 3)) > 59) {
                validateValue(v.slice(0, 2) + ':' + v.slice(2, 3));
            } else {
                validateValue(v);
            }
        } else if (/^\d\d\d\d$/g.test(v)) {
            validateValue(v.slice(0, 2) + ':' + v.slice(2, 4));
        }
    };

    const validateValue = (v) => {
        if (/^\d\d:$/g.test(v)) {
            if (Number(v.slice(0, 2)) < 24) {
                onChange(v);
            }
        } else if (/^\d\d:\d\d$/g.test(v)) {
            if (Number(v.slice(0, 2)) < 24 && Number(v.slice(3, 5)) < 60) {
                onChange(v);
            }
        } else {
            onChange(v);
        }
    };

    const checkOnBlur = () => {
        if (/^\d\d\d$/g.test(value)) {
            onChange('0' + value.slice(0, 1) + ':' + value.slice(1, 3));
        }
    };

    return <Text
        fluid
        className={className}
        disabled={disabled}
        error={error}
        placeholder='00:00'
        textAlign='center'
        width={width}
        value={value}
        onChange={autofillValue}
        onBlur={checkOnBlur}
    />;
};

const Integer = ({
    disabled = false,
    error = false,
    className = '',
    placeholder = '',
    preText = '',
    prePlaceholder = '',
    width = undefined,
    minValue = undefined,
    maxValue = undefined,
    value = '',
    onChange
}) => {

    const validateInteger = (v) => {
        return /^\d*$/g.test(v);
    };

    const checkOnChange = (v) => {
        if (v === '' || v === null || v === undefined) {
            onChange(v);
            return;
        }

        if (validateInteger(v)) {
            let number = Number(v);

            if (minValue && validateInteger(minValue)) {
                if (number < Number(minValue))
                    return;
            }

            if (maxValue && validateInteger(maxValue)) {
                if (number > Number(maxValue))
                    return;
            }

            onChange(Number(v));
        }
    };

    return <Text
        className={className}
        disabled={disabled}
        error={error}
        placeholder={placeholder}
        preText={preText}
        prePlaceholder={prePlaceholder}
        textAlign='left'
        width={width}
        value={value}
        onChange={checkOnChange}
    />;
};

const Float = ({
    disabled = false,
    error = false,
    className = '',
    placeholder = '',
    preText = '',
    prePlaceholder = '',
    width = undefined,
    minValue = undefined,
    maxValue = undefined,
    value = '',
    onChange
}) => {

    const getFloat = (v) => parseFloat(v.replace(',', '.'));

    const validateFloat = (v) => {
        return /^\d+$|^\d*[.|,]\d+$/g.test(v);
    };

    const checkOnChange = (v) => {
        if (v === '' || v === null || v === undefined) {
            onChange(v);
            return;
        }

        if (validateFloat(v)) {
            let number = getFloat(v);

            if (minValue && validateFloat(minValue)) {
                if (number < getFloat(minValue))
                    return;
            }

            if (maxValue && validateFloat(maxValue)) {
                if (number > getFloat(maxValue))
                    return;
            }

            onChange(getFloat(v));
        } else if (/^[.|,]\d*$/g.test(v)) {
            // Need to format partically float number here
            onChange('0' + v);
        } else if (/^\d*[.|,]\d*$/g.test(v)) {
            onChange(v);
        }
    };

    const checkOnBlur = () => {
        if (/^[.|,]\d*$/g.test(value)) {
            onChange('0' + value);
        }

        if (/^\d+[.|,]$/g.test(value)) {
            onChange(value + '00');
        }
    };

    return <Text
        className={className}
        disabled={disabled}
        error={error}
        placeholder={placeholder}
        preText={preText}
        prePlaceholder={prePlaceholder}
        textAlign='left'
        width={width}
        value={value}
        onChange={checkOnChange}
        onBlur={checkOnBlur}
    />;
};


export default {
    Text: Text,
    Time: Time,
    Integer: Integer,
    Float: Float
};