const accessTokenName = "AccessToken";
const accessTokenValidUntil = "AccessTokenValidUntil";
const refreshTokenName = "RefreshToken";
const refreshTokenValidUntil = "RefreshTokenValidUntil";
const getCurrentDateSeconds = () => Math.round(+new Date() / 1000);

const set = (accessToken, accessTokenLifeTimeSeconds, refreshToken, refreshTokenLifeTimeSeconds) => {
    localStorage.setItem(accessTokenName, accessToken);
    localStorage.setItem(accessTokenValidUntil, getCurrentDateSeconds() + accessTokenLifeTimeSeconds);
    localStorage.setItem(refreshTokenName, refreshToken);
    localStorage.setItem(refreshTokenValidUntil, getCurrentDateSeconds() + refreshTokenLifeTimeSeconds);
};

export default {
    getAccessToken: () => localStorage.getItem(accessTokenName),
    getAccessTokenValidUntil: () => +localStorage.getItem(accessTokenValidUntil),
    isAccessTokenExpired: () => getCurrentDateSeconds() + 5 >= +localStorage.getItem(accessTokenValidUntil),
    getRefreshToken: () => localStorage.getItem(refreshTokenName),
    getRefreshTokenValidUntil: () => +localStorage.getItem(refreshTokenValidUntil),
    isRefreshTokenExpired: () => getCurrentDateSeconds() + 5 >= +localStorage.getItem(refreshTokenValidUntil),
    set,

    clear: () => {
        localStorage.removeItem(accessTokenName);
        localStorage.removeItem(accessTokenValidUntil);
        localStorage.removeItem(refreshTokenName);
        localStorage.removeItem(refreshTokenValidUntil);
    },

    saveTokens: ({ accessToken, accessTokenLifeTimeSeconds, refreshToken, refreshTokenLifeTimeSeconds }) =>
        set(accessToken, accessTokenLifeTimeSeconds, refreshToken, refreshTokenLifeTimeSeconds)
};