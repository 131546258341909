import { callApiAsync } from '../../../../hooks';
import { toast } from 'react-toastify';

export const loadSettings = async () => {
    return await callApiAsync('/api/Profile/GetReservationFormSettings');
};

export const saveSettings = async (params) => {
    try {
        const result = await callApiAsync('/api/Profile/SaveReservationFormSettings', 'POST', params);

        if (result.error) {
            toast.error('Не удалось внести изменения. Попробуйте позже');
        }

        return result;
    } catch (e) {
        toast.error('Не удалось внести изменения. Попробуйте позже');

        return { error: true };
    }
};

export const resetSettings = async () => {
    try {
        const result = await callApiAsync('/api/Profile/ResetReservationFormSettings', 'POST');

        if (result.error) {
            toast.error('Не удалось восстановить настройки. Сервис временно недоступен');

            return false;
        }

        return await loadSettings();
    } catch (e) {
        toast.error('Не удалось внести изменения. Попробуйте позже');

        return { error: true };
    }
};