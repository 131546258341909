import React from 'react';
import styled from 'styled-components';
import { filterByType } from '../utils';

const TagWrapper = styled.div`
    background-color: ${props => props.color};
    border-radius: 20px 0px 0px 20px;

    height: 40px;
    min-width: 145px;

    padding-left: 20px;

    position: absolute;
    top: 10px;
    right: 0;
`;

const TagContent = styled.p`
    font-family: 'ClearSans', Arial;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;

    color: #000000;
`;

const TagGroupWrapper = styled.div`
    /* Self positioning */
    position: absolute;
    top: 10px;
    right: 0;

    ${TagWrapper} {
        /* IE */
        position: relative;
        top: auto;
        right: auto;

        /* Modern browsers */
        position: unset;
        top: unset;
        right: unset;
    }

    ${TagWrapper}:not(:last-child) {
        margin-bottom: 10px;
    }
`;

const Tag = ({
    color = 'transparent',
    content,
    style
}) => {

    return (
        <TagWrapper
            color={color}
            style={style}
        >
            <TagContent>
                {content}
            </TagContent>
        </TagWrapper>
    );
};

Tag.displayName = 'Tag';

const TagGroup = ({ children }) => {
    let tags = filterByType(children, 'Tag');

    return (
        <TagGroupWrapper>
            {tags}
        </TagGroupWrapper>
    );
};


const TagComponent = Tag;
TagComponent.Group = TagGroup;

export default TagComponent;