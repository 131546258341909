import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
    background-color: #FFFFFF;
    border-radius: 1.35rem;
    border: 1px solid #135CA9;
    box-sizing: border-box;
    padding: 0.55rem 1.6rem 0.7rem;
    color: #202020;

    display: inline-flex;
    justify-content: center;

    cursor: pointer;

    &:hover {
        background-color: #135CA9;
        color: #FFFFFF;
    }


    ${props => props.size === 'small' && css`
        padding: 0.5rem 1.2rem 0.6rem;
        ${Text} {
            font-size: 0.9rem;
        }
    `}

    ${props => props.size === 'large' && css`

        border: 2px solid #135CA9;
        padding: 0.55rem 1.8rem 0.6rem;
        color: #135CA9;

        ${Text} {
            font-size: 1rem;
            line-height: 27px;
            text-transform: uppercase;
        }
    `}

    ${props => props.error && css`
        border-color: #990202;

        &:hover {
            background-color: #990202;
            color: #FFFFFF;
        }
    `}

    ${props => props.disabled && css`
        pointer-events: none;
    `}

    ${props => props.noButton && css`
        padding:0;
        background: transparent !important;
        border: none;
        &:hover {
            background-color: #FFFFFF;
            color: #135CA9;
        }
    `}

    ${props => props.inverted && css`
        background-color: #5299EE;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;

        &:hover {
            background-color: #FFFFFF;
            color: black;
        }
    `}
`;

const Text = styled.span`
    font-size: 1rem;
    user-select: none;
`;

const TextButton = ({
    className = '',
    size = 'medium',
    content = '',
    children,
    disabled = false,
    error = false,
    onClick,
    noButton,
    style,
    inverted
}) => {
    return (
        <Wrapper
            className={className}
            onClick={onClick}
            size={size}
            error={error}
            disabled={disabled}
            noButton={noButton}
            style={style}
            inverted={inverted}
        >
            <Text>
                {content || children}
            </Text>
        </Wrapper>
    );
};

export default TextButton;