import React from 'react';
import { Tab } from 'semantic-ui-react';
import ActiveConsignorStats from "./tabs/ActiveConsignorStats";
import DcConsignorStats from "./tabs/DcConsignorStats";
import DcPercentConsignorStats from "./tabs/DcPercentConsignorStats";

export default class ConsignorStats extends React.Component {

    render() {
        const panes = [
            {
                menuItem: 'Паллеты', render: () => <Tab.Pane attached={false}>
                    <ActiveConsignorStats />
                </Tab.Pane>
            },
            {
                menuItem: 'РЦ', render: () => <Tab.Pane attached={false}>
                    <DcConsignorStats />
                </Tab.Pane>
            },
            {
                menuItem: 'РЦ (%)', render: () => <Tab.Pane attached={false}>
                    <DcPercentConsignorStats />
                </Tab.Pane>
            },
        ];

        return (
            <div className="container">
                <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
            </div>
        );
    }
};