import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'semantic-ui-react';

import {
    StyledDetailsTableHeader,
    StyledDetailsLine,
    StyledInputContainer,
    StyledDetailsLineButtons
} from './ReservationOrdersDetails.style';

import { t } from './utilities/utilities';

import {
    PalletsFromTo,
    OrderNumber,
    PackingList,
    TypeSelect,
    AmountInput
} from './components/Inputs';

const propTypes = {
    id: PropTypes.number.isRequired,
    palletFrom: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
    ]),
    palletTo: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
    ]),
    palletsAmount: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
    ]),
    palletsCost: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
    ]),
    boxCount: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
    ]),
    orderNumber: PropTypes.string.isRequired,
    packingList: PropTypes.string.isRequired,
    type: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
    ]),
    errors: PropTypes.object.isRequired,
    firstLine: PropTypes.bool,
    labeled: PropTypes.bool,
    showFieldsErrors: PropTypes.bool.isRequired,
    settings: PropTypes.shape({
        palletType: PropTypes.number,
        boxType: PropTypes.number,
        costType: PropTypes.number,
        weightType: PropTypes.number,
        orderType: PropTypes.number,
    }),
    mass: PropTypes.bool,
    onChangeErrors: PropTypes.object.isRequired,
    onLineItemChange: PropTypes.func.isRequired,
    onRemoveLine: PropTypes.func.isRequired,
    onAddLine: PropTypes.func.isRequired,
};

const ReservationOrdersLine = (props) => {
    const {
        showFieldsErrors,
        id,
        palletFrom,
        palletTo,
        weight,
        palletCount,
        cost,
        boxCount,
        orderNumber,
        packingList,
        type,
        errors,
        settings,
        labeled = false,
        firstLine = false,
        mass = false,
        onChangeErrors,
        onLineItemChange,
        onRemoveLine,
        onAddLine
    } = props;

    const handleInputChange = useCallback((e, { name, value }) => onLineItemChange(id, name, value), []);

    const handleInputPaste = useCallback((e) => {
        const value = e.clipboardData.getData('Text');
        const { name } = e.target;

        e.preventDefault();
        onLineItemChange(id, name, value.trim());
    }, []);

    return (
        <>
            {!mass && labeled && (
                <StyledDetailsTableHeader mass={mass}>
                    {settings.palletType === 0
                        ? (
                            < StyledInputContainer mods="palletsNumbers">
                                {t('reservation-pallets-details-pallets-amount')}
                            </StyledInputContainer>
                        ) : (
                            <StyledInputContainer size="s">
                                {t('reservation-pallets-details-pallets-mono-amount')}
                            </StyledInputContainer>
                        )
                    }
                    {settings.boxType === 2 && (
                        <StyledInputContainer size="s">
                            {t('reservation-pallets-details-pallets-boxes')}
                        </StyledInputContainer>
                    )}
                    {settings.costType === 1 && (
                        <StyledInputContainer size="m">
                            {t('reservation-pallets-details-pallets-cost')}
                        </StyledInputContainer>
                    )}
                    {settings.weightType === 1 && (
                        <StyledInputContainer size="m">
                            {t('reservation-pallets-details-pallets-weight')}
                        </StyledInputContainer>
                    )}

                    <StyledInputContainer size="l">
                        {t('reservation-pallets-details-order-number')}
                    </StyledInputContainer>

                    <StyledInputContainer size="l">
                        {t('reservation-pallets-details-invoice-number')}
                    </StyledInputContainer>

                    {settings.orderType === 1 && (
                        <StyledInputContainer size="l">
                            {t('reservation-pallets-details-type')}
                        </StyledInputContainer>
                    )}
                </StyledDetailsTableHeader>
            )}
            <StyledDetailsLine
                className="reservation-pallets-details-line"
                id={`reservation-pallets-details-line__${id}`}
                mass={mass}
            >
                {settings.palletType === 0
                    ? (
                        <PalletsFromTo
                            labeled={mass && labeled}
                            label={t('reservation-pallets-details-pallets-amount')}
                            error={
                                (palletFrom > palletTo)
                                || (onChangeErrors.palletFrom
                                    || onChangeErrors.palletTo
                                    || (showFieldsErrors && (errors.palletFrom || errors.palletTo)))
                            }
                            errorFrom={(palletFrom > palletTo) || (onChangeErrors.palletFrom || (showFieldsErrors && errors.palletFrom))}
                            errorTo={(palletFrom > palletTo) || (onChangeErrors.palletTo || (showFieldsErrors && errors.palletTo))}
                            palletFrom={palletFrom}
                            palletTo={palletTo}
                            onChange={handleInputChange}
                            onPaste={handleInputPaste}
                        />
                    )
                    : (
                        <AmountInput
                            size="s"
                            labeled={mass && labeled}
                            label={t('reservation-pallets-details-pallets-mono-amount')}
                            error={onChangeErrors.palletCount || (showFieldsErrors && errors.palletCount)}
                            name="palletCount"
                            value={palletCount}
                            onChange={handleInputChange}
                            onPaste={handleInputPaste}
                        />
                    )
                }

                {settings.boxType === 2 && (
                    <AmountInput
                        size="s"
                        labeled={mass && labeled}
                        label={t('reservation-pallets-details-pallets-boxes')}
                        error={onChangeErrors.boxCount || (showFieldsErrors && errors.boxCount)}
                        name="boxCount"
                        value={boxCount}
                        onChange={handleInputChange}
                        onPaste={handleInputPaste}
                    />
                )}

                {settings.costType === 1 && (
                    <AmountInput
                        size="m"
                        labeled={mass && labeled}
                        label={t('reservation-pallets-details-pallets-cost')}
                        error={onChangeErrors.cost || (showFieldsErrors && errors.cost)}
                        name="cost"
                        value={cost}
                        onChange={handleInputChange}
                        onPaste={handleInputPaste}
                    />
                )}

                {settings.weightType === 1 && (
                    <AmountInput
                        size="m"
                        labeled={mass && labeled}
                        label={t('reservation-pallets-details-pallets-weight')}
                        error={onChangeErrors.weight || (showFieldsErrors && errors.weight)}
                        name="weight"
                        value={weight}
                        onChange={handleInputChange}
                        onPaste={handleInputPaste}
                    />
                )}

                <OrderNumber
                    labeled={mass && labeled}
                    label={t('reservation-pallets-details-order-number')}
                    error={onChangeErrors.orderNumber || (showFieldsErrors && errors.orderNumber)}
                    value={orderNumber}
                    onChange={handleInputChange}
                    onPaste={handleInputPaste}
                />
                <PackingList
                    labeled={mass && labeled}
                    label={t('reservation-pallets-details-invoice-number')}
                    error={onChangeErrors.packingList || (showFieldsErrors && errors.packingList)}
                    value={packingList}
                    onChange={handleInputChange}
                    onPaste={handleInputPaste}
                />

                {settings.orderType === 1 && (
                    <TypeSelect
                        labeled={mass && labeled}
                        label={t('reservation-pallets-details-type')}
                        value={type}
                        onChange={handleInputChange}
                    />
                )}

                <StyledDetailsLineButtons
                    mass={mass}
                    labeled={labeled}
                >
                    <Button
                        primary
                        icon='minus'
                        onClick={onRemoveLine}
                    />
                    {firstLine && (
                        <Button
                            primary
                            icon='plus'
                            onClick={onAddLine}
                        />
                    )}
                </StyledDetailsLineButtons>
            </StyledDetailsLine>
        </>
    );
};

ReservationOrdersLine.propTypes = propTypes;
export default ReservationOrdersLine;