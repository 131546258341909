import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import axios from 'axios';
import saveAs from 'file-saver';
import { useInterval, callApiAsync } from '../../hooks';

const ExportButton = ({
    menuItem = false, disabled = false,
    name = 'Завантажити', params = [],
    reportType, fileName, style
}) => {
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [requestId, setRequestId] = useState('');

    const requestExport = async () => {
        if (loading)
            return;

        let data = await callApiAsync(`api/ReportEngine/request/${reportType}`, 'GET', undefined, params);

        if (!data) {
            console.error('Error occured when was requesting to generate your report. Update page and try again or contact support.');
            setLoading(false);
            return;
        }

        setLoading(true);
        setRequestId(data);
    };

    const checkExport = async () => {
        if (!requestId || processing)
            return;

        setProcessing(true);

        try {
            let response = await axios.get(`api/ReportEngine/response/${requestId}`, { responseType: 'blob' });

            if (response.status === 200) {
                let file = fileName ? `${fileName}.xlsx` : 'Звіт.xlsx';

                saveAs(new Blob([response.data]), file);
                setLoading(false);

                callApiAsync(`api/ReportEngine/response/${requestId}/downloaded`, 'PUT');
            } else if (response.status !== 204) {
                console.error('Error occured when was checking status of your report. Update page and try again or contact support.');
            }

            setProcessing(false);
        } catch (e) {
            setLoading(false);
            setProcessing(false);
            setRequestId('');

            console.error('Error occured when was checking status of your report. Update page and try again or contact support.');
        }
    };

    useInterval(() => {
        checkExport();
    }, loading ? 1500 : null);


    if (!reportType)
        return null;

    if (menuItem)
        return (
            <div
                onClick={requestExport}
                style={style}
            >
                {name}
            </div>
        );

    return (
        <Button primary
            disabled={disabled}
            loading={loading}
            content={name}
            onClick={requestExport}
            style={style}
        />
    );
};

export default ExportButton;
