import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { UserContext } from '../../context';

import Segment from '../../components/Segment/Segment';
import LoginModal from '../../modals/LoginForm/LoginForm';


const Wrapper = styled.div`
    width:100%;
    padding: 40px;
    height: 100%;

    & > div {
        margin-bottom: 20px;
    }
`;

const PageHeader = styled.p`
    font-size: 1.75rem;
    font-weight: bold;
    color: #135CA9;
    margin-bottom: 1em;
`;

const InvisibleLink = styled(Link)`
`;

const LoginLink = styled(({ className, ...rest }) => <LoginModal linkClass={className} {...rest} />)`
`;

const TimelyDelivery = () => <Segment divider inline dividerGrowsOnHover link>
    <Segment.Header>
        Показники своєчасності доставки
    </Segment.Header>
    <Segment.Body>
        Показники прибуття машин в строк по дням тижня
    </Segment.Body>
</Segment>;

const StatisticsPage = () => {
    const userData = useContext(UserContext);

    return (
        <Wrapper>
            <PageHeader>Статистика</PageHeader>
            <div>
                <InvisibleLink to='/statistics/results'>
                    <Segment
                        divider
                        dividerGrowsOnHover
                        inline link
                    >
                        <Segment.Header>
                            Результати
                        </Segment.Header>
                        <Segment.Body>
                            Доля pooliing-доставок серед всіх завантажених палет за вибраний період
                        </Segment.Body>
                    </Segment>
                </InvisibleLink>
            </div>
            {userData.isAuthorized()
                ? <InvisibleLink to='/statistics/timely-delivery'>
                    <TimelyDelivery />
                </InvisibleLink>
                : <LoginLink noButton>
                    <TimelyDelivery />
                </LoginLink>
            }


            {/*<Segment
                divider
                dividerGrowsOnHover
            >
                <Segment.Header>
                    <InvisibleLink to='/statistics/pooling-concentration'>
                        Дни концентрации pooling’а
                    </InvisibleLink>
                </Segment.Header>
                <Segment.Body>
                    Доля pooling-доставок по дням консолидации за последние 2 месяца
                </Segment.Body>
            </Segment>*/}

        </Wrapper>
    );
};

export default StatisticsPage;