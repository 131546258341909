import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popup, FormField, Input } from 'semantic-ui-react';

const propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    fluid: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    warning: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object,
};

const StyledInput = styled(Input)`
    & > input{
        padding: 0.678571em 1.6em !important;
    }
`;

const TimeInput = ({
    label,
    name,
    fluid = false,
    placeholder = '00:00',
    disabled,
    error,
    className,
    value = '',
    warning,
    onChange = () => { },
    style,
}) => {

    const autofillValue = (e, v) => {
        if (/^\d$/g.test(v) && Number(v) > 2) {
            validateValue(e, '0' + v + ':');
        } else if (/^$|^\d$|^\d:\d$|^\d:\d\d$|^\d\d$|^\d\d:$|^\d\d:\d$|^\d\d:\d\d$/g.test(v)) {
            validateValue(e, v);
        } else if (/^\d:$/g.test(v)) {
            validateValue(e, '0' + v);
        } else if (/^\d\d\d$/g.test(v)) {
            if (Number(v.slice(0, 2)) > 23) {
                validateValue(e, '0' + v.slice(0, 1) + ':' + v.slice(1, 3));
            }
            else if (Number(v.slice(1, 3)) > 59) {
                validateValue(e, v.slice(0, 2) + ':' + v.slice(2, 3));
            } else {
                validateValue(e, v);
            }
        } else if (/^\d\d\d\d$/g.test(v)) {
            validateValue(e, v.slice(0, 2) + ':' + v.slice(2, 4));
        }
    };

    const validateValue = (e, v) => {
        if (/^\d\d:$/g.test(v)) {
            if (Number(v.slice(0, 2)) < 24) {
                onChange(e, { name, value: v });
            }
        } else if (/^\d\d:\d\d$/g.test(v)) {
            if (Number(v.slice(0, 2)) < 24 && Number(v.slice(3, 5)) < 60) {
                onChange(e, { name, value: v });
            }
        } else {
            onChange(e, { name, value: v });
        }
    };

    const checkOnBlur = (e, value) => {
        if (/^\d\d\d$/g.test(value)) {
            const v = '0' + value.slice(0, 1) + ':' + value.slice(1, 3);
            onChange(e, { name, value: v });
        }
    };

    return (
        <Popup
            className="error"
            trigger={(
                <FormField style={style} className={`time-input ${className}`}>
                    {label && <label>{label}</label>}
                    <StyledInput
                        name={name}
                        fluid={fluid}
                        disabled={disabled}
                        error={error}
                        placeholder={placeholder}
                        value={value}
                        onChange={(e) => autofillValue(e, e.target.value)}
                        onBlur={(e) => checkOnBlur(e, e.target.value)}
                    />
                </FormField>
            )}
            position='top right'
            wide
            content={warning}
            open={!!warning}
        />
    );
};

TimeInput.propTypes = propTypes;
export default TimeInput;