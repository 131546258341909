import styled, { css } from 'styled-components';

export const Header = styled.div`
    height: 60px;
    display: flex;
    align-items: center;

    ${props => props.headerClickable && css`
        cursor: pointer !important;
    `}
`;

export const Title = styled.p`
    font-size: 22px;
    font-weight: bold;
    color: #135CA9 !important;
    margin: 0 15px 2px 25px !important;
    user-select: none;
`;

export const Content = styled.div`
    width: 100%;
    padding: 10px ${props => props.sidePadding} 30px;
`;

export const Arrow = styled.div`
    display: flex;
    padding-top: 4px;
`;

export const Wrapper = styled.div`
    background: #F0F5F8;
    display: block;
    border: 1px solid #D0DADD;

    &:not(:last-child) {
        margin-bottom: 30px;
    }

    ${props => props.mini && css`
        background: transparent;
        -ms-border-radius: 0px;
        border-radius: 0px;

        ${Header} {
            padding: 0 2px;
            margin: 15px 0;
            height: 28px;
            display: inline-flex;
            border-bottom: 0.5px solid #135CA9;
        }

        ${Title} {
            font-size: 19px;
            margin: 0 10px 0 0;
        }

        ${Content} {
            padding: 0;
        }

        ${Arrow} svg {
            width: 7px;
            height: 5px;
        }
    `}

    ${props => props.style && css`
        ${props.style}
    `}

    ${props => props.noBorderRadius || css`
        -ms-border-radius: 8px;
        border-radius: 8px;
    `}
`;