import React, { useState } from 'react';
import Svg from '../Svg';
import { Arrow, Content, Header, Title, Wrapper } from './Accordion.style';


const Accordion = ({
    style = undefined,
    defaultOpen = false, mini = false,
    showArrow = true, openOnClick = true,
    contentSidePadding = '45px',
    title, children, additionElement, noBorderRadius
}) => {
    const [open, setOpen] = useState(defaultOpen);

    const onHeaderClick = () => {
        if (openOnClick) {
            setOpen(prev => !prev);
        }
    };

    return (
        <Wrapper
            style={style}
            mini={mini}
            noBorderRadius={noBorderRadius}
        >
            <Header
                headerClickable={openOnClick}
                onClick={onHeaderClick}
            >
                <Title>{title}</Title>
                {showArrow && <Arrow>
                    <Svg
                        type='arrow'
                        direction={open ? 'up' : 'down'}
                    />
                </Arrow>}
                {additionElement}
            </Header>
            {open
                ? <Content sidePadding={contentSidePadding}>
                    {children}
                </Content>
                : null
            }
        </Wrapper>
    );
};

export default Accordion;