import React from 'react';
import styled, { keyframes } from 'styled-components';


const Wrapper = styled.svg`
`;

const BackCircle = styled.circle`
    fill: none;
    stroke: #C4D6E9;
    stroke-width: 7;
`;

const rotate = (props) => keyframes`
    from {
        stroke-dashoffset: ${2 * Math.PI * 46};
    }
    to {
        stroke-dashoffset: ${(1 - (props.percent / 100)) * 2 * Math.PI * 46};
    }
`;

const ProgressCircle = styled.circle`
    fill: none;
    stroke: #135CA9;
    stroke-width: 7;

    stroke-dasharray: ${2 * Math.PI * 46};
    /*-ms-stroke-dashoffset: ${props => (1 - (props.percent / 100)) * 2 * Math.PI * 46};*/
    animation: ${props => rotate(props)} 1.5s ease-out forwards;

    /* Hack for IE 11 */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        & {
            stroke-dashoffset: ${props => (1 - (props.percent / 100)) * 2 * Math.PI * 46};
        }
    }
`;

const Text = styled.tspan`
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    letter-spacing: -1px;
    color: #135CA9;
`;

const Percent = styled(Text)`
    font-size: 1rem;
`;

const CircularProgress = ({
    percent = 100,
    clockwise = true,
    /*color = '#135CA9'*/
}) => {
    return (
        <Wrapper
            width='100'
            height='100'
            viewBox='0 0 100 100'
        >
            <BackCircle
                cx="50"
                cy="50"
                r="46"
            />
            <ProgressCircle
                cx="50"
                cy="50"
                r="46"
                transform='rotate(-90 50 50)'
                percent={percent}
            />
            <text
                x='50%'
                y='60%'
                textAnchor='middle'
                fill='#135CA9'
            >
                <Text>{percent}</Text>
                <Percent> %</Percent>
            </text>
        </Wrapper>
    );
};

export default CircularProgress;