import React, { useState } from 'react';
import { callApiAsync } from '../../hooks';
import moment from 'moment';

import ReservationWarningModal from './ReservationWarningModal';

import Tooltip from '../../components/Tooltip';
import Svg from '../../components/Svg';
import Button from '../../components/Button';

/* SVG Icons */
import { ReactComponent as CargoIcon } from './cargo.svg';
import { ReactComponent as TruckIcon } from './truck.svg';
import { ReactComponent as PalletIcon } from './pallet.svg';

import {
    CustomModal,
    Header,
    HeaderTitle,
    HeaderCloseButton,
    HeaderFieldTooltip,
    HeaderField,
    HeaderLogoWrapper,
    HeaderLogo,
    HeaderCompanyName,
    HeaderTextsWrapper,
    HeaderInfo
} from './Reservation.style';
import ReservationForm from './ReservationForm';
import { Confirm } from 'semantic-ui-react';


const initialTrigger = (isEditing) => {
    return (
        <Button.Text inverted={isEditing} size="small">
            {isEditing ? 'Змінити' : 'Забронювати'}
        </Button.Text>
    );
};

const initialReservation = {
    id: '',
    number: '',

    palletCount: 0,
    cost: '',
    weight: '',
    warehouseId: 'tk',
    Date: '',
    timeFrom: '10:00',
    timeTo: '18:00',
    timeSlot: '',
    comment: '',
    orders: [{
        palletFrom: 1,
        palletTo: 1,
        orderNumber: '',
        packingList: '',
        type: 0
    }],

    overloadWarning: ''
};

const initialSlot = {
    consolidationDate: moment(),
    deliveryDate: moment(),
    distributionCenterName: '',
    carTypeName: '',
    productTypeName: '',
    carrierLogoName: '',
    carrierName: '',
    clientLogoName: '',
    clientName: '',
    palletLeft: 0,
    reservations: [initialReservation]
};


const leftWord = (count) => count % 10 === 1 && count !== 11 ? 'залишилось' : 'залишилось';

const palletWord = (count) => {
    let twoSymb = count % 100;

    if (twoSymb > 9 && twoSymb < 20)
        return 'палет';

    let last = count % 10;

    switch (last) {
        case 1:
            return 'палета';

        case 2:
        case 3:
        case 4:
            return 'палеты';

        default:
            return 'палет';
    }
};

const ReservationModal = ({
    trigger,
    slotId,
    isEditing = false,
    onClose,
}) => {
    const [warningOpen, setWarningOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [isDemo, setIsDemo] = useState(false);
    const [warehouses, setWarehouses] = useState([{ id: '', text: '' }]);
    const [slot, setSlot] = useState(initialSlot);
    const [showCloseConfirmation, setCloseConfirmation] = useState(false);
    const [actionConfirmation, setActionConfirmation] = useState({ open: false });
    const [isChangedArray, setIsChangedArray] = useState([]);
    const [addButton, setAddButton] = useState({});

    const openModal = async () => {
        let shouldOpen = await loadData();
        setOpen(shouldOpen);
        setWarningOpen(!shouldOpen);
    };

    const closeModal = () => {
        if (isChangedArray.includes(true))
            setCloseConfirmation(true);
        else
            closeForSure();
    };

    const closeForSure = () => {
        setOpen(false);
        resetData();
        setIsChangedArray([]);
        setCloseConfirmation(false);
        if (onClose)
            onClose();
    };

    const setItemChanged = (i) => (isChanged) => {
        setIsChangedArray(Object.assign([], isChangedArray, { [i]: isChanged }));
    };

    const onDeletion = (i) => () => {
        let newChangedArray = Object.assign([], isChangedArray, { [i]: null });
        setIsChangedArray(newChangedArray);
        if (!(newChangedArray).some(x => x !== null))
            closeForSure();
    };

    const toggleWarningModal = () => setWarningOpen(v => !v);

    const resetData = () => {
        setSlot(initialSlot);
    };

    const onAddClick = () =>
        setActionConfirmation(
            {
                content: "Створити додаткове бронювання?",
                onConfirm: addReservation,
                open: true
            }
        );

    const checkAddAvailability = (data) => {
        if (data.warehouses.length < 3 && !data.slot.reservations.every(x => moment(x.date).isSameOrBefore(moment(), "day")))
            setAddButton({ disabled: true, message: "Ви можете внести зміни в поточне бронювання" });
        else
            setAddButton({ message: 'Додати нове бронювання в даний РЦ' });
    };

    const addReservation = async () => {
        let data = await callApiAsync('api/reservations/empty', 'GET', undefined, { slotId });
        if (!data.slot.isAvailable) {
            return false;
        }
        let r = data.slot.reservations[0];
        r.date = moment(data.slot.reservations[0].date);
        r.cost = '';
        r.weight = '';

        setActionConfirmation({ open: false });
        setIsChangedArray([...isChangedArray, true]);
        setSlotFromApi({ ...data.slot, reservations: [...slot.reservations, r] });
        checkAddAvailability(data);
    };

    const setSlotFromApi = (slotFromApi, onlySlotData) => {
        setSlot({
            ...slotFromApi,
            productTypeName: slotFromApi.productTypeName.replace('Non-Food', 'Non-Food/Food'),
            consolidationDate: moment(slotFromApi.consolidationDate),
            deliveryDate: moment(slotFromApi.deliveryDate),
            reservations: onlySlotData ? slot.reservations : slotFromApi.reservations
        });
    };

    const loadData = async () => {
        let data = await callApiAsync('api/reservations', 'GET', undefined, { slotId });

        // Not opening modal if not allowed
        if (!data.slot.isAvailable) {
            return false;
        }

        let reservations = data.slot.reservations.map((r, i) => {
            setItemChanged(i)(false);
            r.isEditing = isEditing;
            r.date = moment(r.date);
            if (!isEditing) {
                r.cost = '';
                r.weight = '';
            }
            return r;
        });

        setIsDemo(data.isDemo);
        setWarehouses(data.warehouses);
        setSlotFromApi({ ...data.slot, reservations });
        checkAddAvailability(data);

        return true;
    };

    return (<>
        {warningOpen
            ? <ReservationWarningModal
                open={true}
                onToggleWarningModal={toggleWarningModal}
            />
            : null}
        <div style={{ display: "inline-block" }} onClick={openModal}>
            {trigger
                ? trigger
                : initialTrigger(isEditing)
            }
        </div>
        {open
            ? <>
                <CustomModal
                    open={true}
                    onClose={closeModal}
                >
                    <Header>
                        <HeaderTextsWrapper>
                            <HeaderTitle>
                                <span>Заявка на&nbsp;</span>
                                <Tooltip content='Такта доставки'>
                                    {slot.deliveryDate ? slot.deliveryDate.format('DD.MM') : null}
                                </Tooltip>
                                <span>, РЦ {slot.distributionCenterName}</span>
                            </HeaderTitle>
                            <HeaderInfo>
                                <HeaderField>
                                    <HeaderFieldTooltip content='Тип вантажу'>
                                        <CargoIcon />
                                    </HeaderFieldTooltip>
                                    <span>
                                        {slot.productTypeName}
                                    </span>
                                </HeaderField>
                                <HeaderField>
                                    <HeaderFieldTooltip content='Тип кузова'>
                                        <TruckIcon />
                                    </HeaderFieldTooltip>
                                    <span>
                                        {slot.carTypeName}
                                    </span>
                                </HeaderField>
                                <HeaderField>
                                    <HeaderFieldTooltip content='Палет'>
                                        <PalletIcon />
                                    </HeaderFieldTooltip>
                                    {slot.palletLeft > 0 ? (
                                        <span>
                                            До pooling-тарифа {leftWord(slot.palletLeft)}&nbsp;
                                            <b>{slot.palletLeft}</b>
                                            &nbsp;{palletWord(slot.palletLeft)}
                                        </span>
                                    ) :
                                        (
                                            <span>
                                                {'Набраний pooling-тариф'}
                                            </span>
                                        )}
                                </HeaderField>
                            </HeaderInfo>
                        </HeaderTextsWrapper>
                        <HeaderLogoWrapper>
                            {slot.carrierLogoName
                                ? <HeaderLogo src={slot.carrierLogoName} />
                                : <HeaderCompanyName>{slot.carrierName}</HeaderCompanyName>
                            }
                            {slot.clientLogoName
                                ? <HeaderLogo src={slot.clientLogoName} />
                                : <HeaderCompanyName>{slot.clientName}<i>0</i></HeaderCompanyName>
                            }
                        </HeaderLogoWrapper>
                        <HeaderCloseButton onClick={closeModal}>
                            <Svg width={30} height={30} type='cross' />
                        </HeaderCloseButton>
                    </Header>
                    {slot.reservations.map((reservation, i) => {
                        return <ReservationForm
                            key={i}
                            loadedReservation={reservation}
                            slot={slot}
                            deliveryDate={slot.deliveryDate ? slot.deliveryDate.format('DD.MM.YY') : ''}
                            slotId={slotId}
                            isDemo={isDemo}
                            warehouses={warehouses}
                            setSlot={setSlotFromApi}
                            defaultIsEditing={isEditing && reservation.isEditing}
                            setIsChanged={setItemChanged(i)}
                            onDeletion={onDeletion(i)}
                        />;
                    })}
                    <div>
                        <Tooltip style={{ margin: "auto", display: "flex" }} content={addButton.message}>
                            <Button.Image
                                disabled={addButton.disabled}
                                type='plus'
                                style={{ margin: "auto", display: "flex" }}
                                onClick={onAddClick}
                            />
                        </Tooltip>
                    </div>
                </CustomModal>
                <Confirm
                    className="confirmation-modal"
                    open={showCloseConfirmation}
                    onCancel={() => setCloseConfirmation(false)}
                    onConfirm={closeForSure}
                    content="Внесені зміни не будуть збережені. Ви впевнені, що хочете закрити вікно?"
                    cancelButton="Ні"
                    confirmButton="Так"
                />
                <Confirm
                    className="confirmation-modal"
                    open={actionConfirmation.open}
                    onCancel={() => setActionConfirmation({ open: false })}
                    onConfirm={actionConfirmation.onConfirm}
                    content={actionConfirmation.content}
                    cancelButton="Ні"
                    confirmButton="Так"
                />
            </>
            : null}
    </>);
};

export default ReservationModal;
