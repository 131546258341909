import React from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import Footer from './components/Footer';
import { withRouter } from 'react-router-dom';

const Layout = ({ children }) => {

    const Content = styled.div`
        background-color: #fff;
        width:100%;
        overflow-x:inherit;
        flex-grow: 1;
        padding-bottom: 40px;
    `;

    const Layout = styled.div`
        position: relative;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    `;

    const HeaderWithRouter = withRouter(props => <Header {...props} />);

    return <Layout>
        <HeaderWithRouter />
        <Content>
            {children}
        </Content>
        <Footer />
    </Layout>;
};
export default Layout;