import './BookingTab.style.css';

import React, { useState, useEffect, useContext } from 'react';

import Input from '../../../../components/NewInput/Input';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import Radio from '../../../../components/Radio/Radio';
import Button from '../../../../components/Button';
import Accordion from '../../../../components/Accordion/Accordion';
import { callApiAsync } from '../../../../hooks';
import { Modal } from 'semantic-ui-react';

import { UserContext as UserData } from '../../../../context';



const Rules = () => {
    return (<>
        <p>1. У святкові дні, розклад розміщення заявок може змінюватись. Дані зміни анонсуються окремо.</p>
        <p>2. Для дня консолідації в ПН, останній день розміщення заявки — ПТ.</p>
        <p
            className='blue-text'
            style={{ marginTop: '20px' }}
        >
            Food/Non-Food:
        </p>
        <p>Бронювання:</p>
        <br />
        &bull; До 11 годин дня консолідації, у випадку самостійної доставки вантажу на склад консолідації.
        <br />
        &bull; До 11 годин дня консолідації, у випадку забору вантажу Перевізником з вашого складу.
        <br />
        <br />
        Зміна броні: до 12 дня доставки на склад консолідації, до 11 дня отгрузки с вашего склада.
    </>);
};

const useRuleForm = () => {
    const [rule, setRule] = useState({
        id: '',
        consignor: '',
        bookingTill: '',
        forWeekend: '',
        forMonday: ''
    });
    const clearRule = () => {
        setRule({
            id: '',
            consignor: '',
            bookingTill: '',
            forWeekend: '',
            forMonday: ''
        });
    };

    const [validation, setValidation] = useState({
        consignor: true,
        bookingTill: true,
        forWeekend: true,
        forMonday: true
    });
    const validateField = (field, value) => {
        let valid = { ...validation };
        valid[field] = value !== '';
        setValidation(valid);
    };

    const validateOnSave = () => {
        let valid = { ...validation };

        for (let prop in valid) {
            valid[prop] = rule[prop] !== '';
        }

        valid.bookingTill = valid.bookingTill && /^\d\d:\d\d$/g.test(rule.bookingTill);
        setValidation(valid);

        return Object.values(valid).findIndex(v => !v) === -1;
    };

    const onChange = (field, value) => {
        validateField(field, value);

        setRule({
            ...rule,
            [field]: value
        });
    };

    const onSave = async () => {
        if (!validateOnSave())
            return /*false*/;

        await callApiAsync('/api/bookingRules', 'POST', rule);
        clearRule();
    };

    return [rule, validation, onChange, onSave];
};

const useRules = () => {
    const [rules, setRules] = useState([]);
    const onChange = async (index, field, value) => {
        let array = [...rules];
        let rule = array[index];

        rule[field] = value;

        // Validating bookingTill
        if (field === 'bookingTill') {
            rule.valid = /^\d\d:\d\d$/g.test(value);
        }

        if (rule.valid !== false) {
            await onSave(index);
        }

        setRules(array);
    };

    const loadAsync = async () => {
        let data = await callApiAsync('/api/bookingRules/', 'GET', undefined);
        setRules(data);
    };

    useEffect(() => { loadAsync(); }, []);

    const onDelete = async (id) => {
        await callApiAsync(`/api/bookingRules/${id}`, 'DELETE', undefined);
    };

    const onSave = async (index) => {
        await callApiAsync('/api/bookingRules', 'POST', rules[index]);
    };

    return [rules, loadAsync, onChange, onDelete];
};

const useConsignorOptions = (observable) => {
    const [options, setOptions] = useState([]);

    const loadData = async () => {
        let data = await callApiAsync('/api/bookingRules/consignors', 'GET');
        setOptions(data);
    };

    useEffect(() => { loadData(); }, [observable]);

    return [options];
};

const ViewForCarrier = () => {
    const [newRule, newRuleValid, onNewRuleChange, onNewRuleSave] = useRuleForm();
    const [rules, loadRules, onRuleChange, onRuleDelete] = useRules();
    const [consignors] = useConsignorOptions(rules.length);
    const [open, setOpen] = useState(false);
    const [deletingId, setDeletingId] = useState('');

    const onSave = async () => {
        await onNewRuleSave();
        loadRules();
    };

    const onDelete = async (id) => {
        await onRuleDelete(id);
        loadRules();
    };

    return (<div style={{ paddingLeft: '13px' }}>
        <p className='subhead-text'><b>Налаштування правил бронювання для постачальників</b></p>
        <table className='pooling__borderless-table'>
            <thead>
                <tr className='bottom-bordered'>
                    <th
                        className='left-aligned'
                        style={{ width: '250px' }}
                    >
                        Постачальник
                    </th>
                    <th>Вантаж</th>
                    <th>Бронювання до</th>
                    <th>Бронювання на СБ і НД</th>
                    <th>Бронювання на ПН</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                <tr className='bottom-bordered'>
                    <td className='left-aligned'>
                        <Dropdown
                            placeholder='Вибрати постачальника'
                            noResultsMessage="Нічого не знайдено"
                            search
                            clearable
                            error={!newRuleValid.consignor}
                            value={newRule.consignor}
                            onChange={(v) => onNewRuleChange('consignor', v)}
                            options={consignors.map(c => { return { value: c.id, text: c.name }; })}
                        />
                    </td>
                    <td>—</td>
                    <td>
                        <Input.Time
                            error={!newRuleValid.bookingTill}
                            value={newRule.bookingTill}
                            onChange={(v) => onNewRuleChange('bookingTill', v)}
                        />
                    </td>
                    <td>
                        <div style={{ display: 'inline-flex' }}>
                            <Radio
                                error={!newRuleValid.forWeekend}
                                value={'Thursday'}
                                checked={newRule.forWeekend === 'Thursday'}
                                onChange={(v) => onNewRuleChange('forWeekend', v)}
                                description='Чт'
                            />
                            <Radio
                                error={!newRuleValid.forWeekend}
                                value={'Friday'}
                                checked={newRule.forWeekend === 'Friday'}
                                onChange={(v) => onNewRuleChange('forWeekend', v)}
                                description='Пт'
                            />
                        </div>
                    </td>
                    <td>
                        <div style={{ display: 'inline-flex' }}>
                            <Radio
                                error={!newRuleValid.forMonday}
                                value={'Friday'}
                                checked={newRule.forMonday === 'Friday'}
                                onChange={(v) => onNewRuleChange('forMonday', v)}
                                description='Пт'
                            />
                            <Radio
                                error={!newRuleValid.forMonday}
                                value={'Saturday'}
                                checked={newRule.forMonday === 'Saturday'}
                                onChange={(v) => onNewRuleChange('forMonday', v)}
                                description='Сб'
                            />
                        </div>
                    </td>
                    <td>
                        <Button.Image
                            type='check'
                            onClick={onSave}
                        />
                    </td>
                </tr>
                {rules.map((rule, index) => {
                    return (
                        <tr key={rule.id}>
                            <td
                                className='left-aligned'
                                style={{ paddingLeft: '13px' }}
                            >
                                {rule.consignor}
                            </td>
                            <td>{rule.productType}</td>
                            <td>
                                <Input.Time
                                    error={rule.valid === false}
                                    value={rule.bookingTill}
                                    onChange={(v) => onRuleChange(index, 'bookingTill', v)}
                                />
                            </td>
                            <td>
                                <div style={{ display: 'inline-flex' }}>
                                    <Radio
                                        value={'Thursday'}
                                        checked={rule.forWeekend === 'Thursday'}
                                        onChange={(v) => onRuleChange(index, 'forWeekend', v)}
                                        description='Чт'
                                    />
                                    <Radio
                                        value={'Friday'}
                                        checked={rule.forWeekend === 'Friday'}
                                        onChange={(v) => onRuleChange(index, 'forWeekend', v)}
                                        description='Пт'
                                    />
                                </div>
                            </td>
                            <td>
                                <div style={{ display: 'inline-flex' }}>
                                    <Radio
                                        value={'Friday'}
                                        checked={rule.forMonday === 'Friday'}
                                        onChange={(v) => onRuleChange(index, 'forMonday', v)}
                                        description='Пт'
                                    />
                                    <Radio
                                        value={'Saturday'}
                                        checked={rule.forMonday === 'Saturday'}
                                        onChange={(v) => onRuleChange(index, 'forMonday', v)}
                                        description='Сб'
                                    />
                                </div>
                            </td>
                            <td>
                                <Button.Image
                                    type='cross'
                                    onClick={() => { setDeletingId(rule.id); setOpen(true); }}
                                />
                            </td>
                        </tr>
                    );
                })}
                <tr className='top-bordered'>
                    <td
                        className='left-aligned'
                        style={{ paddingLeft: '13px' }}
                    >
                        {rules.length > 0 ? 'Інші' : 'Все'} поставщики
                    </td>
                    <td>Non-Food/Food</td>
                    <td>16:30</td>
                    <td>Четвер</td>
                    <td>П'ятниця</td>
                    <td></td>
                </tr>
                <tr>
                    <td
                        className='left-aligned'
                        style={{ paddingLeft: '13px' }}
                    >
                        {rules.length > 0 ? 'Інші' : 'Все'} поставщики
                    </td>
                    <td>Alco</td>
                    <td>12:00</td>
                    <td>Четвер</td>
                    <td>П'ятниця</td>
                    <td></td>
                </tr>
            </tbody>
        </table>
        <Accordion
            mini
            title='Загальні правиа бронювання'
            style={{ paddingLeft: '13px' }}
        >
            <Rules />
        </Accordion>
        {/* ToDo: Replace with own modal*/}
        <Modal
            size='mini'
            open={open}
            onClose={() => setOpen(false)}
        >
            <Modal.Content>
                <div className='modal-headtext'>Видалити вибране правило?</div>
                <div className='modal-buttons'>
                    <Button.Text
                        size='small'
                        content='Видалиьт'
                        onClick={() => {
                            onDelete(deletingId);
                            setOpen(false);
                        }}
                    />
                    <Button.Text
                        size='small'
                        content='Відміна'
                        onClick={() => setOpen(false)}
                    />
                </div>
            </Modal.Content>
        </Modal>
    </div>);
};

const ViewForConsignor = () => {
    const [rules] = useRules();

    return (<div style={{ paddingLeft: '13px' }}>
        <p className='subhead-text'><b>Загальні правила</b></p>
        <Rules />
        {rules.map((r, index) => {
            return (<React.Fragment key={index}>
                <br />
                <p className='subhead-text'><b>Бронювання {r.carrier}</b></p>
                <p>Для днів консолідації СБ і НД останній день <span className='blue-text'>{r.forWeekend}</span>.</p>
                <p>Для консолідації ПН осстанній день розміщення заявки - <span className='blue-text'>{r.forMonday}</span>.</p>
                <p><span className='blue-text'>{r.productType}:</span>бронюванна до <span className='blue-text'>{r.bookingTill}</span>для, що передує дню консолідації</p>
            </React.Fragment>);
        })}
    </div>);
};

const BookingTab = () => {
    const userData = useContext(UserData);

    switch (userData.getRole()) {
        case 'Перевізник':
            return <ViewForCarrier />;
        case 'Вантажовідправник':
            return <ViewForConsignor />;
        default:
            return null;
    }
};

export default BookingTab;