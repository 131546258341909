import React from 'react';
import { Message } from 'semantic-ui-react';


const RegistrationForm = () => {

    return (
        <>
            <Message>
                <p>Для того щоб отримати доступ до пулін тарифів, Вам необхідно заповнити анкету.</p>
                <p>Післяя заповнення анкети на протязі доби на адресу вказаної Вами пошти будуть відправлені дані демо-доступу до площадки.</p>
            </Message>
            <iframe
                title='Registration form'
                width='700'
                height='520'
                frameborder='0'
                marginheight='0'
                marginwidth='0'
                src="https://docs.google.com/forms/d/12jYzMHlXJmQRN5LLbXIUjTWyRY7zDfJ8gIBNbWqUhlc/viewform?embedded=true"
            >
                Завантаження...
            </iframe>
        </>
    );
};

export default RegistrationForm;
