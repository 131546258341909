import React from 'react';
import { Popup, Grid } from 'semantic-ui-react';
import LoginForm from "../../../modals/LoginForm/LoginForm";
import ReservationModal from '../../../modals/ReservationModal/ReservationModal';
import MapModal from '../../../components/Map/MapModal';

const declOfNum = (number, titles) => {
    let cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
};

const Slot = ({ slot, className, isAuthorized, userRole, showedType, isPrevDay, onClose }) => {
    if (!slot)
        return <Grid.Column className={className}></Grid.Column>;

    //if (day.slot.length <= 1) {
    let isReserved = slot.reservations && slot.reservations.length > 0;
    let type = showedType === 'ref' ? 'Реф' : 'Тент';

    let greenCheck = slot.totalPalletPlaceCount >= 25 ? <i className="greencheck"></i> : '';

    if (slot.price === 0)
        return <Grid.Column className={className}></Grid.Column>;

    let bookedClassName = (isReserved ? 'booked' : '');

    let ftlReservation = isReserved ? slot.reservations.filter(r => r.type === 'FTL') : [];
    let ftlPalletLine = ftlReservation.length > 0 ? `FTL: ${ftlReservation.reduce((a, b) => a + b.userCount || 0, 0)} ваших` : null;
    let ltlReservation = isReserved ? slot.reservations.filter(r => r.type === 'LTL' || r.type === 'Pooling') : [];
    let ltlPalletCount = ltlReservation.length > 0 ? <>(ваших <b>{ltlReservation.reduce((a, b) => a + b.userCount || 0, 0)}</b>)</> : null;

    let reservationBtn =
        <div className='reservation-btn'>
            {slot.availableReservation
                ? isAuthorized
                    ? userRole === 'Вантажовідправник'
                        ? <ReservationModal
                            slotId={slot.id}
                            onClose={onClose}
                            isEditing={isReserved}
                        />
                        : null
                    : <LoginForm>
                        Забронювати
                    </LoginForm>
                : null}
        </div>;

    return <Grid.Column
        id={slot.id}
        className={`reservation text-align-center ${className} ${bookedClassName}`}
    >
        <div className={`reservation-inner ${isPrevDay ? 'is-prev-day' : ''}`}>
            <div className="logo-box">
                <i
                    className='logo-company'
                    style={{ background: `url(${slot.companyLogoName}) no-repeat center center` }}
                />
            </div>
            <div className="info inline-block">
                <div className="text-align-left">
                    <span className="amount info-inner-block">
                        <i className="ico icon-amount" data-toggle="tooltip" title="" data-original-title="Палет заявлено до перевезення"></i>
                        <b>{slot.totalPalletPlaceCount}</b> {declOfNum(slot.totalPalletPlaceCount, ['палета', 'палети', 'палет'])}
                        {ltlPalletCount ? <><br />{ltlPalletCount}</> : null}
                        {ftlPalletLine ? <><br />{ftlPalletLine}</> : null}
                    </span>
                </div>
                <div className="text-align-left">
                    <span className="home info-inner-block"><i className="ico icon-home" data-toggle="tooltip" title="" data-original-title="Склад консолідації транспортної компанії"></i>
                        <MapModal address={slot.warehouseAddress}>
                            {slot.warehouse.split(" ").map((a, i) => <React.Fragment key={i}>{a}<br /></React.Fragment>)}
                        </MapModal>

                    </span>
                </div>
                <div className="text-align-left">
                    <span className="date info-inner-block">
                        <i className="ico icon-date"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="дата доставки клієнту" />
                        {slot.dayForDelivery}
                    </span>
                </div>
                <div className="text-align-left">
                    <span className="delType info-inner-block">
                        <i className="ico icon-truck"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="тип" />
                        {type}
                    </span>
                </div>
            </div>
            <Popup mouseEnterDelay={500} wide='very' position="top center" hideOnScroll size='tiny' trigger={
                <div className="price">
                    <b>{slot.price.toLocaleString('uk-UA')} грн</b>{greenCheck}
                </div>
            }>
                Ціна діє при умові: сукупний обсяг палет > 24
            </Popup>
            {reservationBtn}
        </div>
    </Grid.Column>;
};
export default Slot;