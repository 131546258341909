import { strToNum } from "./utilities";

export const createErrorsObject = ({ values, isTander }) => {
    const validate = validateInput(isTander);

    let result = {};

    Object.keys(values).forEach(name => {
        const { error } = validate(name, values[name]);

        result[name] = error;
    });

    return result;
};

const validateInput = (isTander) => (name, value) => {
    const isValidForMagnit = (str) => {
        if (str.length < 10 || str.length > 18) return false;

        return /^([A-Z]{2}\d{2}\s\d+|\d+[A-Z]\d+|[A-Z]{2}\d+|[A-Z]{2}\d+[A-Z]\d+)(ACT|ACT-\d+|-\d+)?$/g.test(str);
    };

    let result;
    let unsafeInteger;

    switch (name) {
        case 'palletCount':
        case 'palletFrom':
        case 'palletTo':
            result = strToNum(value, 0);
            unsafeInteger = result && result >= Number.MAX_SAFE_INTEGER;

            return {
                value: unsafeInteger ? Number.MAX_SAFE_INTEGER : result,
                error: result === '' || +result <= 0 || +result > 100
            };

        case 'boxCount':
            result = strToNum(value, 0);
            unsafeInteger = result && result >= 2e9;

            return {
                value: unsafeInteger ? 2e9 : result,
                error: result === '' || +result <= 0 || unsafeInteger
            };

        case 'cost':
            result = strToNum(value, 2);
            unsafeInteger = result && result >= Number.MAX_SAFE_INTEGER;

            return {
                value: unsafeInteger ? Number.MAX_SAFE_INTEGER : result,
                error: result === '' || +result <= 0 || unsafeInteger
            };

        case 'weight':
            result = strToNum(value, 0);
            unsafeInteger = result && result >= Number.MAX_SAFE_INTEGER;

            return {
                value: unsafeInteger ? Number.MAX_SAFE_INTEGER : result,
                error: result === '' || +result <= 0 || +result > 60000
            };

        case 'orderNumber':
            const sliced = isTander ? value.slice(0, 18).toUpperCase() : value.slice(0, 25);

            return {
                value: sliced,
                error: !/\S/.test(sliced) || isTander ? !isValidForMagnit(sliced) : value === ''
            };

        case 'packingList':
            const trimmed = value.trim().slice(0, 25);

            return {
                value: trimmed,
                error: trimmed === ''
            };


        default:
            return {
                value,
                error: false
            };
    }
};

export default validateInput;