import React, { useState, useContext, useEffect } from 'react';
import { UserContext as UserData } from '../../../context';
import { callApiAsync } from '../../../hooks';
import { firstSymbolToUpper, sortClients } from '../../../utils';
import moment from 'moment';

import Tabs from '../../../components/Tabs';
import Dropdown from '../../../components/Dropdown/Dropdown';

import AdminView from './AdminView';
import UsualView from './UsualView';

import {
    Wrapper,
    Header,
    SubHeader,
    TabWrapper,
    FilterBlock,
    FilterName
} from './TimelyDeliveryPage.style';

//TODO add prop-types

const useTabPages = () => {
    const [page, setPage] = useState('');
    const [pages, setPages] = useState([]);

    useEffect(() => {
        let now = moment();

        let year = now.year();
        let month = now.month();
        let months = [];

        if (month > 0) {
            for (let i = 0; i < month; i++) {
                months.push({
                    key: `${year}.${moment().month(i).format('MM')}`,
                    caption: firstSymbolToUpper(moment().month(i).format('MMMM'))
                });
            }
        } else {
            for (let i = 0; i < 12; i++) {
                months.push({
                    key: `${year - 1}.${moment().month(i).format('MM')}`,
                    caption: firstSymbolToUpper(moment().month(i).format('MMMM'))
                });
            }
        }

        setPages(months);
        setPage(months[months.length - 1].key);
    }, []);

    return [page, setPage, pages];
};

const TimelyDeliveryPage = () => {
    const userData = useContext(UserData);

    const [page, setPage, pages] = useTabPages();

    const [carrierId, setCarrierId] = useState('');
    const [carriers, setCarriers] = useState([]);

    const [clients, setClients] = useState([]);
    const [clientId, setClientId] = useState('');

    const loadDictionaries = async () => {
        let carrierData = (await callApiAsync('api/definitions/carriers', 'GET')).map(d => ({ value: d.id, text: d.name }));
        setCarriers(carrierData);
        if (!carrierId)
            setCarrierId(carrierData && carrierData[0].value);

        const clientData = (await callApiAsync('api/definitions/clients', 'GET')).map(d => ({ value: d.id, text: d.name }));
        const sortedClientData = sortClients(clientData);

        setClients(sortedClientData);
        if (!clientId)
            setClientId(clientData && sortedClientData[0].value);
    };

    useEffect(() => {
        loadDictionaries();
    }, []);


    const [deliveryData, setDeliveryData] = useState({
        id: '',
        toDate: 0,
        toMinute: 0,
        clientApproved: false,
        clientLogoName: ''
    });

    const loadData = async () => {
        let [year, month] = page.split('.');

        setDeliveryData(await callApiAsync('/api/timely-delivery', 'GET', undefined, {
            year, month, carrierId, clientId
        }));
    };

    const onSave = async () => {
        await callApiAsync('/api/timely-delivery', 'POST', deliveryData);
        await loadData();
    };

    const onDelete = async () => {
        if (!deliveryData.id)
            return;

        await callApiAsync(`/api/timely-delivery/${deliveryData.id}`, 'DELETE', deliveryData.id);
    };

    useEffect(() => {
        if (page && carrierId && clientId)
            loadData();
    }, [page, carrierId, clientId]);

    let preText = `${page && page.substring(0, 4)} рік`;
    let selectedClient = clientId && clients.find(c => c.value === clientId).text;
    let selectedCarrier = carrierId && carriers.find(c => c.value === carrierId).text;

    return (
        <Wrapper>
            <Header>Статистика</Header>
            <SubHeader>Показники своєчасності доставки</SubHeader>
            <Tabs
                preText={preText}
                activeTab={page}
                onTabChange={setPage}
            >
                {pages.map(p => (
                    <Tabs.Pane
                        key={p.key}
                        {...p}
                    >
                        <TabWrapper>
                            <FilterBlock>
                                <FilterName>Транспортна компанія</FilterName>
                                <Dropdown
                                    noResultsMessage="Нічого не знайдено"
                                    fluid
                                    value={carrierId}
                                    onChange={setCarrierId}
                                    options={carriers}
                                />
                                <FilterName>Клієнт</FilterName>
                                <Dropdown
                                    noResultsMessage="Нічого не знайдено"
                                    fluid
                                    value={clientId}
                                    onChange={setClientId}
                                    options={clients}
                                />
                            </FilterBlock>
                            {userData.hasPermission('AdminTools')
                                ? <AdminView
                                    data={deliveryData}
                                    carrierName={selectedCarrier}
                                    onChange={setDeliveryData}
                                    onSave={onSave}
                                    onDelete={onDelete}
                                />
                                : <UsualView
                                    data={deliveryData}
                                    clientName={selectedClient}
                                    carrierName={selectedCarrier}
                                />
                            }
                        </TabWrapper>
                    </Tabs.Pane>
                ))}
            </Tabs>
        </Wrapper>
    );
};

export default TimelyDeliveryPage;