import React, { useState } from 'react';
import { Confirm } from 'semantic-ui-react';


const CancellationModal = ({
    onYes,
    onNo,
    children
}) => {
    const [open, setOpen] = useState(false);

    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    const onYesClick = () => {
        closeModal();

        if (onYes)
            onYes();
    };

    const onNoClick = () => {
        closeModal();

        if (onNo)
            onNo();
    };

    return (
        <Confirm
            className="confirmation-modal"
            open={open}
            trigger={<div style={{ marginRight: "auto" }} onClick={openModal}>
                {children}
            </div>}
            onCancel={onNoClick}
            onConfirm={onYesClick}
            content="Ви впевненi що бажаєте вiдмiнити бронювання?"
            cancelButton="Не відміняти"
            confirmButton="Відмінити бронювання"
        />
    );
};

export default CancellationModal;