import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect, Suspense, lazy } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Route, Switch } from 'react-router';
import * as authService from './utils/authService';
import PrivateRoute from "./PrivateRoute";

import Calendar from './pages/Calendar/Calendar';
import ConsignorStats from './pages/Stats/consignorStats/ConsignorStats.jsx';
import NonActiveConsignorStats from './pages/Stats/NonActiveConsignorStats.jsx';
import Prolongations from './pages/Prolongations/Prolongations.jsx';
import PersonalPage from './pages/Personal/PersonalPage';
import StatisticsPage from './pages/Statistics/StatisticsPage';
import TimelyDeliveryPage from './pages/Statistics/TimelyDelivery/TimelyDeliveryPage';
import ReservationModal from './modals/ReservationModal/ReservationModal';

import Svg from './components/Svg';

import { UserContext as UserData } from './context';
import Layout from './Layout/Layout';

function lazyWithPreload(factory) {
    const Component = factory();
    return lazy(() => Component);
}

// Lazy loading
const AdminUsers = lazy(() => import(/* webpackChunkName: "admin-users" */ './pages/Users/Users'));
const AdminCompaniesPage = lazy(() => import(/* webpackChunkName: "admin-companies" */ './pages/Companies/Companies'));
const AdminDCsPage = lazy(() => import(/* webpackChunkName: "admin-dcs" */ './pages/DCs/DCs'));
const AdminTariffsPage = lazy(() => import(/* webpackChunkName: "admin-tariffs" */ './pages/Tariffs/AdminTariffs'));
const AdminSchedulesPage = lazy(() => import(/* webpackChunkName: "admin-schedules" */ './pages/AdminSchedules/AdminSchedules'));
const AdminRegionsPage = lazy(() => import(/* webpackChunkName: "admin-regions" */ './pages/Regions/Regions'));
const AdminGlobalMessagesPage = lazy(() => import(/* webpackChunkName: "admin-global-mess" */ './pages/GlobalMessage/GlobalMessagesPage'));
const AdminSlots = lazy(() => import(/* webpackChunkName: "admin-slots" */ './pages/Slots/SlotsPage'));

const MassLoad = lazy(() => import(/* webpackChunkName: "mass-load" */ './pages/MassLoad/MassLoad'));

// Lazy loading with preload
const ClientReport = lazyWithPreload(() => import(/* webpackChunkName: "client-report" */ './pages/ClientReport/ClientReport'));
const CarrierReport = lazyWithPreload(() => import(/* webpackChunkName: "carrier-report" */ './pages/CarrierReport/CarrierReport'));
const ConsignorReport = lazyWithPreload(() => import(/* webpackChunkName: "consignor-report" */ './pages/ConsignorReport/ConsignorReport'));
const GeneralReport = lazyWithPreload(() => import(/* webpackChunkName: "general-report" */ './pages/GeneralReport/GeneralReport'));
const AdvisingReport = lazyWithPreload(() => import(/* webpackChunkName: "advising-report" */ './pages/AdvisingReport/AdvisingReport'));

const Tariffs = lazyWithPreload(() => import(/* webpackChunkName: "tariffs" */ './pages/Tariffs/TariffsPage'));
const Results = lazyWithPreload(() => import(/* webpackChunkName: "results" */ './pages/Results/Results'));
const Delivery = lazyWithPreload(() => import(/* webpackChunkName: "delivery" */ './pages/Schedules/Schedules'));

const App = () => {

    // Flag of user being checked from DB
    const [userChecked, setUserChecked] = useState(false);
    const [user, setUser] = useState(undefined);

    const isAuthorized = () => user !== undefined;
    const getName = () => user && user.name;
    const getRole = () => user && user.role;
    const getUserId = () => user && user.id;
    const getCompanyId = () => user && user.companyId;
    const getCompanyName = () => user && user.companyName;
    const hasPermission = (p) => user && user.permissions && user.permissions.includes(p);
    const updateAccessToken = async () => authService.updateAccessToken(setUser);
    const login = async (email, pass) => authService.login(email, pass, setUser);
    const logout = () => authService.logout(setUser);

    useEffect(() => {
        updateAccessToken().then(() => setUserChecked(true));
    }, []);

    // Rendering nothing till user data fetched from server
    if (!userChecked)
        return null;

    return (
        <UserData.Provider
            value={{
                isAuthorized: isAuthorized,
                onLogin: login,
                onLogout: logout,
                getName,
                getRole,
                getUserId,
                getCompanyId,
                getCompanyName,
                hasPermission
            }}
        >
            <Layout>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnBottom
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    pauseOnHover
                />
                <article>
                    <Suspense fallback={
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                            <Svg
                                type='loader'
                                width={80}
                                height={80}
                            />
                        </div>
                    }>
                        <Switch>
                            <Route path='/reservation' component={ReservationModal} />
                            {/** Pages open for not logged in */}
                            <Route path='/tariffs' component={Tariffs} />
                            <Route path='/schedule' component={Delivery} />

                            {/** Statistics Section */}
                            <Route path='/statistics/results/:weekNumberAndYearNumber?' component={Results} />
                            <PrivateRoute path='/statistics/timely-delivery' component={TimelyDeliveryPage} />
                            <Route path='/statistics' exact component={StatisticsPage} />

                            <PrivateRoute path='/stats/nonActiveConsignorStats' component={NonActiveConsignorStats} />
                            <PrivateRoute path='/consignorStats' component={ConsignorStats} />
                            <PrivateRoute path='/generalReport' component={GeneralReport} />
                            <PrivateRoute path='/carrierReport' component={CarrierReport} />
                            <PrivateRoute path='/clientReport' component={ClientReport} />
                            <PrivateRoute path='/consignorReport' component={ConsignorReport} />
                            <PrivateRoute path='/prolongation' component={Prolongations} />

                            <PrivateRoute path='/personal' component={PersonalPage} />

                            {/** Admin panel */}
                            <PrivateRoute path='/users' component={AdminUsers} />} />
                            <PrivateRoute path='/companies' component={AdminCompaniesPage} />} />
                            <PrivateRoute path='/DCs' component={AdminDCsPage} />
                            <PrivateRoute path='/admin/tariffs' component={AdminTariffsPage} />
                            <PrivateRoute path='/admin/schedules' component={AdminSchedulesPage} />
                            <PrivateRoute path='/regions' component={AdminRegionsPage} />
                            <PrivateRoute path='/admin/globalMessages' component={AdminGlobalMessagesPage} />
                            <PrivateRoute path='/admin/slots' component={AdminSlots} />

                            {/** Fallback route */}
                            <Route path='/' component={Calendar} />
                        </Switch>
                    </Suspense>
                </article>
            </Layout>
        </UserData.Provider>
    );
};

export default hot(App);