import './Checkbox.style.css';

import React from 'react';
import Svg from '../Svg';

const Checkbox = ({
    description = '',
    className = '',
    disabled = false,
    checked = false, error = false,
    value = '', onChange
}) => {

    const getClass = () => {
        let classes = ['pooling__checkbox'];

        if (checked) {
            classes.push('checked');
        }

        if (disabled) {
            classes.push('disabled');
        }

        if (error) {
            classes.push('error');
        }

        if (className) {
            classes.push(className);
        }

        return classes.join(' ');
    };

    const onToggle = () => {
        if (!disabled)
            onChange(value);
    };

    return (
        <div className={getClass()}>
            <div
                className='check'
                onClick={onToggle}
            >
                <i>
                    <Svg
                        type='checkbox-mark'
                    />
                </i>
            </div>
            <label onClick={onToggle}>
                {description}
            </label>
        </div>
    );
};


export default Checkbox;