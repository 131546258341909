import React from 'react';
import Mailchimp from 'react-mailchimp-form';

import { RightMenu, Logo, Block, ContactBlock, FooterWrapper, LogoBlock, Phone } from './Footer.style';

const Footer = () => {
    return <FooterWrapper>
        <ContactBlock>
            <Phone><a href="tel:+38 044 379 48 94">+38 044 379 48 94</a></Phone>
            <div><a href="mailto:contact@pooling.me">contact@pooling.me</a></div>
        </ContactBlock>
        <Block>
            <p>Щотижнева розсилка про розвиток Pooling.me</p>
            <div>
                <Mailchimp
                    action='https://artlogics.us17.list-manage.com/subscribe/post?u=5290cb262f24dd5d9d8126f2b&amp;id=2cf6217f13'
                    className='subscribe-form'

                    messages={
                        {
                            sending: "Вадправка",
                            success: "Дякую, що підписались",
                            error: "Виникла невідома помилка",
                            empty: "Введіть E-mail",
                            duplicate: "Пыдписка на цю адресу E-mail вже була здійснена",
                            button: "Підписатись"
                        }
                    }

                    fields={[
                        {
                            name: 'EMAIL',
                            placeholder: 'Email',
                            type: 'email',
                            required: true
                        }
                    ]}
                />
            </div>
        </Block>
        <RightMenu>
            <LogoBlock>
                <Logo src="/img/pooling_logo.svg" />
            </LogoBlock>
        </RightMenu>
    </FooterWrapper>;
};
export default Footer;