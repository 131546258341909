import { createContext } from 'react';

export const ProfileContext = createContext();

export const UserContext = createContext({
    isAuthorized: undefined,
    onLogin: undefined,
    onLogout: undefined,
    hasPermission: () => false,
    getRole: () => '',
    getName: () => '',
    getUserId: () => '',
    getCompanyId: () => '',
    getCompanyName: () => '',
});