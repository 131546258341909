import React from 'react';
import styled, { css } from 'styled-components';
import Svg from '../Svg';


const Wrapper = styled.div`
    /* Size */
    width: 32px;
    height: 32px;

    /* Common */
    background: #FFFFFF;
    border-radius: 16px;
    box-sizing: border-box;
    user-select: none;

    /* Border */
    border: 2px solid #135CA9;

    /* Displaying content */
    display: inline-flex;
    justify-content: center;
    align-items: center;

    /* Selecting on hover */
    &:hover {
        background-color: #135CA9;
        cursor: pointer;

        path {
            fill: #FFFFFF;
        }
    }

    ${props => props.alternate && css`
        background: #F0F5F8;
        border: 0;
    `}

    ${props => props.disabled && css`
        background: #F0F5F8 !important;
        opacity: 0.5;
        path {
            fill: gray !important;
        }
    `}
`;

const ImageContainer = styled.i`
    display: flex;
    align-items: center;

    ${Wrapper}:hover & path {
        fill: #FFFFFF;
    }
`;

const ImageButton = ({
    className = '',
    type = '',
    alternate = false,
    onClick,
    style,
    disabled
}) => {
    return (
        <Wrapper
            disabled={disabled}
            className={className}
            alternate={alternate}
            onClick={!disabled ? onClick : () => { }}
            style={style}
        >
            <ImageContainer>
                <Svg type={type} />
            </ImageContainer>
        </Wrapper>
    );
};

export default ImageButton;