import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

const Wrapper = styled.div`
    position: relative;
    display: inline-block;

    ${props => props.fluidContainer && css`
        width: 100%;
    `}
`;

const fadeIn = keyframes`
    from, 99% {
        visibility: hidden;
    }

    100% {
        visibility: visible;
    }
`;

const Popup = styled.div`
    /* Text properties */
    font-family: 'ClearSans';
    font-size: 11px;
    font-weight: normal;
    line-height: 14px;
    text-align: ${props => props.textAlign};
    white-space: nowrap;
    color: #000000;

    /* Common */
    visibility: hidden;
    padding: 4px;

    /* Border & Background*/
    border: 1px solid #C4D6E9;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #FFFFFF;

    /* Positioning */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);


    /* Changing visibility on click */
    ${props => props.showOnClick && css`
        & {
            visibility: ${props.active ? 'visible' : 'hidden'};
        }
    `}

    /* Always visible on fixed */
    ${props => props.fixed && css`
        visibility: visible;
    `}

    /* Changing visibility on hover with delay */
    ${props => !props.active && css`
        ${Wrapper}:hover & {
            animation: ${fadeIn} ${props.delay} linear forwards;
        }
    `}

    ${props => props.marginBottom && css`
        margin-bottom: ${props.marginBottom};
    `}


    /* Arrow */
    &:before, &:after {
        content: ' ';
        position: absolute;
        
        height: 0;
        width: 0;
        top: 100%;
        left: 50%;

        border-style: solid;
    }

    /* Arrow border */
    &:before {
        border-color: #C4D6E9 transparent transparent transparent;
        margin-left: -5px;
        border-width: 5px;
    }

    /* Arrow body */
    &:after {
        border-color: #FFFFFF transparent transparent transparent;
        margin-left: -4px;
        border-width: 4px;
    }

    ${props => props.error && css`
        border-color: #990202;

        &:before {
            border-color: #990202 transparent transparent transparent;
        }
    `}
`;


const Tooltip = ({
    className = '',
    showOnClick = false,
    fluidContainer = false,
    fixed = false,
    error = false,
    textAlign = 'center',
    marginBottom = '',
    delay = '.5s',
    content,
    children,
    style
}) => {
    const [active, setActive] = useState(false);

    const changeActive = (value) => () => setActive(value);


    return (
        <>
            <Wrapper
                className={className}
                onClick={changeActive(true)}
                onMouseLeave={changeActive(false)}
                fluidContainer={fluidContainer}
                style={style}
            >
                {children}
                <Popup
                    active={active}
                    fixed={fixed}
                    error={error}
                    delay={delay}
                    showOnClick={showOnClick}
                    textAlign={textAlign}
                    marginBottom={marginBottom}
                >
                    {content}
                </Popup>
            </Wrapper>
        </>
    );
};

export default Tooltip;