import './Dropdown.style.css';

import React, { useState, useRef } from 'react';
import { useClickOutside } from '../../hooks';
import Svg from '../Svg';


const Single = ({
    placeholder = null,
    disabled = false,
    fluid = false,
    search = false,
    error = false,
    clearable = false,
    width = '',
    className = '',
    notFoundText = 'Нічого не знайдено',
    options = [],
    value = '', onChange
}) => {
    // Searching
    const [searchValue, setSearchValue] = useState('');
    const onSearch = (value) => {
        setSearchValue(value);
    };
    let filteredOptions = options.filter(o => new RegExp('^' + searchValue + '.*?$', 'i').test(o.text || o.value));

    // Menu
    const [menuOpen, setMenuOpen] = useState(false);
    const onOptionSelect = (value) => {
        onChange(value);
        setMenuOpen(false);
    };

    // Ref for clicking outside
    let dropdownRef = useClickOutside(() => setMenuOpen(false));

    const getClass = () => {
        let classes = ['pooling__dropdown'];

        if (disabled) {
            classes.push('disabled');
        }

        if (fluid) {
            classes.push('fluid');
        }

        if (error) {
            classes.push('error');
        }

        if (menuOpen) {
            classes.push('open');
        }

        if (className) {
            classes.push(className);
        }

        return classes.join(' ');
    };

    const iconClick = () => {
        // If clearable and value selected
        if (clearable && value)
            onChange('');
        else
            setMenuOpen(!menuOpen);
    };

    let foundOption = filteredOptions.find(o => o.value === value);

    return (
        <div
            ref={dropdownRef}
            className={getClass()}
            style={(width ? { width } : {})}
            onClick={() => setMenuOpen(!menuOpen)}
        >
            <div className='field'>
                {search
                    ? <input
                        placeholder={placeholder}
                        disabled={disabled}
                        value={(foundOption && foundOption.text) || searchValue}
                        onChange={({ target }) => onSearch(target.value)}
                    />
                    : <div
                        className={(value ? '' : 'default ') + 'text'}
                    >
                        {foundOption ? foundOption.text : placeholder}
                    </div>
                }
                <i
                    className='dropdown-icon'
                    onClick={iconClick}
                >
                    {foundOption && clearable
                        ? <Svg type='dropdown-cross' />
                        : <Svg
                            type='dropdown-arrow'
                            direction={menuOpen ? 'up' : 'down'}
                        />
                    }
                </i>
            </div>
            <div className='menu'>
                {filteredOptions.map((o, index) => (
                    <div
                        className='item'
                        key={o.value || index}
                        onClick={() => onOptionSelect(o.value)}
                    >
                        {o.text || o.value}
                    </div>
                ))}
                {filteredOptions.length < 1
                    ? <div className='not-found item'>{notFoundText}</div>
                    : null
                }
            </div>
        </div>
    );
};

const Multiple = ({
    placeholder = null, disabled = false, fluid = false, search = false, error = false,
    className = '', notFoundText = 'Нічого не знайдено',
    options = [],
    values = [], onChange
}) => {
    // Searching
    const [searchValue, setSearchValue] = useState('');
    const onSearch = (value) => {
        setSearchValue(value);
    };
    const searchInput = useRef();

    let notSelectedOptions = options.filter(o => values.findIndex(v => v === o.value) === -1);
    let filteredOptions = notSelectedOptions.filter(o => new RegExp('^' + searchValue + '.*?$', 'i').test(o.text || o.value));

    // Menu
    const [menuOpen, setMenuOpen] = useState(false);
    const onOptionSelect = (value) => () => {
        let array = [...values];
        array.push(value);
        onChange(array);

        if (search) {
            setSearchValue('');

            if (searchInput.current)
                searchInput.current.focus();
        }
    };

    const onOptionUnselect = (value) => () => {
        let array = [...values];
        array.splice(array.indexOf(value), 1);
        onChange(array);
    };

    // Ref for clicking outside
    let dropdownRef = useClickOutside(() => setMenuOpen(false));

    const getClass = () => {
        let classes = ['pooling__dropdown', 'multiple'];

        if (disabled) {
            classes.push('disabled');
        }

        if (fluid) {
            classes.push('fluid');
        }

        if (error) {
            classes.push('error');
        }

        if (values.length === 0) {
            classes.push('empty');
        }

        if (menuOpen) {
            classes.push('open');
        }

        if (className) {
            classes.push(className);
        }

        return classes.join(' ');
    };

    //let foundOption = filteredOptions.find(o => o.value === value);
    let empty = values.length === 0;

    return (
        <div className={getClass()} ref={dropdownRef}>
            <div
                className='field'
                onClick={() => setMenuOpen(true)}
            >
                <div className='tags'>
                    {values.map(v => {
                        let option = options.find(o => v === (o.value));

                        if (option) {
                            return (
                                <div
                                    key={option.value}
                                    className='tag'
                                    value={v}
                                >
                                    <span>
                                        {option.text || option.value}
                                    </span>
                                    <i onClick={onOptionUnselect(option.value)}>
                                        <Svg
                                            type='dropdown-cross'
                                        />
                                    </i>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                    {search
                        ? <input
                            ref={searchInput}
                            placeholder={empty ? placeholder : ''}
                            disabled={disabled}
                            value={searchValue}
                            onChange={({ target }) => onSearch(target.value)}
                            onFocus={() => setMenuOpen(true)}
                        />
                        : <div
                            className={(empty ? 'default ' : '') + 'text'}
                            onClick={() => setMenuOpen(!menuOpen)}
                        >
                            {empty ? placeholder : ''}
                        </div>
                    }
                </div>
                <i
                    className='dropdown-icon'
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    <Svg
                        type='dropdown-arrow'
                        direction={menuOpen ? 'up' : 'down'}
                    />
                </i>
            </div>
            <div className='menu'>
                {filteredOptions.map(o => (
                    <div
                        className='item'
                        key={o.value}
                        onClick={onOptionSelect(o.value)}
                    >
                        {o.text || o.value}
                    </div>
                ))}
                {filteredOptions.length < 1
                    ? <div className='not-found item'>{notFoundText}</div>
                    : null
                }
            </div>
        </div>
    );
};


const Dropdown = ({
    placeholder = null,
    disabled = false,
    fluid = false,
    search = false,
    error = false,
    multiple = false,
    clearable = false,
    className = '',
    width = '',
    notFoundText = 'Нічого не знайдено',
    options = [],
    value, onChange
}) => {
    if (multiple) {
        return <Multiple
            placeholder={placeholder}
            disabled={disabled}
            fluid={fluid}
            search={search}
            error={error}
            className={className}
            notFoundText={notFoundText}
            options={options}
            values={value}
            onChange={onChange}
        />;
    }
    else {
        return <Single
            width={width}
            placeholder={placeholder}
            disabled={disabled}
            fluid={fluid}
            search={search}
            clearable={clearable}
            error={error}
            className={className}
            notFoundText={notFoundText}
            options={options}
            value={value}
            onChange={onChange}
        />;
    }
};


export default Dropdown;