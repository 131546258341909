import './Radio.style.css';

import React from 'react';
import Svg from '../Svg';

const Radio = ({
    description = '',
    className = '',
    checked = false, error = false,
    value = '', onChange
}) => {

    const getClass = () => {
        let classes = ['pooling__radio'];

        if (checked) {
            classes.push('checked');
        }

        if (error) {
            classes.push('error');
        }

        if (className) {
            classes.push(className);
        }

        return classes.join(' ');
    };

    return (
        <div className={getClass()}>
            <div
                className='check'
                onClick={() => onChange(value)}
            >
                <i>
                    <Svg
                        type='dot'
                    />
                </i>
            </div>
            <label onClick={() => onChange(value)}>
                {description}
            </label>
        </div>
    );
};


export default Radio;