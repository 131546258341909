import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Button, Modal } from 'semantic-ui-react';

import LoginForm from '../../modals/LoginForm/LoginForm';
import ImportAdvisingData from '../../modals/ImportAdvisingData/ImportAdvisingData';
import DateManageModal from '../../modals/DateManageModal/DateManageModal';
import PrintFormModal from '../../modals/PrintFormModal/PrintFormModal';
import ExportButton from '../../components/Button/ExportButton';

import { UserContext as UserData } from '../../context';
import { useHiddenMenuRef, callApiAsync } from '../../hooks';

import {
    ExitButton, HeaderWrapper, Logo, MenuIcon, MenuItem, MenuItemExternal, MessageButton,
    MessageText, MessageWrapper, RightMenu, SubMenu, SubMenuBody, UserMenuHeader, UserName,
    UserRole, Flex,
    Text, ModalHeader, ModalContent
} from './Header.style';

import { Carousel } from '../../components/Carousel';
import localStorageService from '../../utils/localStorageService';
import { isTander } from '../../utils';

const confirm = window.confirm;

// Check if Internet Explorer
const isIE = () => {
    let ua = window.navigator.userAgent;
    let msie = ua.indexOf("MSIE ");
    return (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./));
};

const Header = ({ location }) => {
    const userData = useContext(UserData);

    let pages = [
        { name: "Календар", href: "/" },
        { name: "Тарифи", href: "/tariffs" },
        { name: "Статистика", href: "/statistics" },
        { name: "Графік доставки", href: "/schedule" },
    ];

    const logout = (e) => {
        e.preventDefault();
        console.log('clicked logout');
        if (confirm('Ви впечнені, що хочете вийти?'))
            userData.onLogout();
    };

    const [message, setMessage] = useState(null);
    const userMenuRef = useHiddenMenuRef(false);
    const settingsMenuRef = useHiddenMenuRef(false);

    const closedMessages = localStorageService.getObject("closedMessages") || [];

    const getMessage = async () => {
        const globalMessage = await callApiAsync("/api/definitions/message", "GET");
        if (globalMessage && !closedMessages.includes(globalMessage.id)) {
            setMessage(globalMessage);
        }
    };

    const closeMessage = () => {
        if (message && message.id) {
            closedMessages.push(message.id);
            localStorageService.setObject("closedMessages", closedMessages);
        }
        setMessage(null);
    };

    useEffect(() => {
        if (isIE()) {
            setMessage({ text: "Ми помітили, що ви використовуєте Internet Explorer. На жаль, оскільки даний браузер застарів і може працювати некоректно, ми в найближчий час відмовимось від його підтримки." });
            callApiAsync("/api/definitions/ie");
        }
        else getMessage();
    }, []);

    pages = pages.map(page => ({
        ...page,
        isActive: (page.href === "/" && location.pathname === "/")
            || (page.href !== "/" && location.pathname.startsWith(page.href))
    }));

    const LinkMenuItem = props => <MenuItem as={
        ({ isActive, ...rest }) => <Link {...rest} />
    } {...props} ></MenuItem>;

    const UserMenu = () => <>
        <UserMenuHeader>
            <UserName>{userData.getName()}</UserName>
            <UserRole>{userData.getRole()}</UserRole>
            <ExitButton onClick={logout}>Вихід</ExitButton>
        </UserMenuHeader>
        <SubMenuBody>
            <LinkMenuItem to="/personal">Особистий кабінет</LinkMenuItem>
            {userData.getRole() === 'Торгівельна мережа'
                ? <>
                    <LinkMenuItem to='/clientReport'>Звіт</LinkMenuItem>
                </>
                : null}
            {userData.getRole() === 'Перевізник'
                ? <>
                    <LinkMenuItem to='/advisingReport'>Звіт для авізації</LinkMenuItem>
                    <LinkMenuItem to='/carrierReport'>Звіт ТК</LinkMenuItem>
                    <LinkMenuItem to='/prolongation'>Управління бронями</LinkMenuItem>
                </>
                : null}
            {userData.getRole() === 'Вантажовідправник'
                ? <>
                    <MenuItemExternal>
                        <PrintFormModal style={{ padding: "10px 15px" }} />
                    </MenuItemExternal>
                    <LinkMenuItem to='/consignorReport'>Звіт</LinkMenuItem>
                </>
                : null}
        </SubMenuBody>
    </>;

    const AdminMenu = () => <SubMenuBody>
        {userData.hasPermission('AdminTools')
            ? <>
                <MenuItemExternal>
                    <DateManageModal style={{ padding: "10px 15px" }} />
                </MenuItemExternal>
                <LinkMenuItem to='/admin/slots'>Слоты</LinkMenuItem>
                <LinkMenuItem to='/users'>Пользователи</LinkMenuItem>
                <LinkMenuItem to='/companies'>Компании</LinkMenuItem>
                <LinkMenuItem to='/regions'>Регионы отправки</LinkMenuItem>
                <LinkMenuItem to='/DCs'>Распределительные центры</LinkMenuItem>
                <LinkMenuItem to='/admin/tariffs'>Тарифи</LinkMenuItem>
                <LinkMenuItem to='/admin/schedules'>Графики</LinkMenuItem>
                <LinkMenuItem to='/admin/globalMessages'>Глобальные сообщения</LinkMenuItem>
            </>
            : null}
        {userData.hasPermission('GeneralReport')
            ? <>
                <LinkMenuItem to='/generalReport'>Общий отчет</LinkMenuItem>
                <LinkMenuItem to='/consignorStats'>Статистика активности поставщиков</LinkMenuItem>
                <LinkMenuItem to='/stats/nonActiveConsignorStats'>Звіт про неактивних постачальників</LinkMenuItem>
            </>
            : null}
    </SubMenuBody>;

    return <>
        <HeaderWrapper>
            <MenuItem notHoverable><a href="http://ua.pooling.me/"><Logo src="/img/pooling_logo.svg" /></a></MenuItem>
            {pages.map(page => (
                <LinkMenuItem
                    key={page.name}
                    to={page.href}
                    isActive={page.isActive}
                >{page.name}</LinkMenuItem>
            ))}
            <RightMenu>
                {userData.isAuthorized()
                    ? <>
                        {userData.hasPermission('AdminTools') || userData.hasPermission('GeneralReport')
                            ? <>
                                <MenuItem ref={settingsMenuRef}>
                                    <MenuIcon img="/img/settings.svg" hoverImg="/img/settings-hover.svg" />
                                    <SubMenu className="sub-menu">
                                        <AdminMenu />
                                    </SubMenu>
                                </MenuItem>
                            </>
                            : null}
                        <MenuItem ref={userMenuRef}>
                            <MenuIcon img="/img/user.svg" hoverImg="/img/user-hover.svg" />
                            <SubMenu className="sub-menu">
                                <UserMenu />
                            </SubMenu>
                        </MenuItem>

                    </>
                    : <MenuItem>
                        <LoginForm buttonStyle={{ marginTop: "-0.3rem", padding: "0.35rem 2rem 0.55rem" }}>Вхід</LoginForm>
                    </MenuItem>
                }
            </RightMenu>
        </HeaderWrapper>
        {message
            ? <MessageWrapper>
                {
                    !message.isClickable
                        ? <MessageText>{message.text}</MessageText>
                        : <Modal size="small"
                            trigger={
                                <Flex>
                                    <MessageText>
                                        <Button>{message.text}</Button>
                                    </MessageText>
                                    <MessageButton>
                                        <Button>Подивитись</Button>
                                    </MessageButton>
                                </Flex>
                            }>
                            <Modal.Content>
                                <Text align="center">
                                    <ModalHeader>{message.header}</ModalHeader>
                                </Text>
                                <Carousel>
                                    {message.pages.map(x => <ModalContent dangerouslySetInnerHTML={{ __html: x }} />)}
                                </Carousel>

                            </Modal.Content>
                        </Modal>
                }
                <MessageButton>
                    <Button onClick={closeMessage}>Закрити</Button>
                </MessageButton>
            </MessageWrapper>
            : null
        }
    </>;
};

export default Header;