import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { filterByType, measureText } from '../utils';

import Button from './Button';


/* Constants block */
const TABS_STEP = 3;
const LINK_OFFSET = 30;
const BUTTON_WIDTH = 32;

const Wrapper = styled.div`
    font-family: 'ClearSans';
`;

const Menu = styled.div`
    border-bottom-width: 1px;
    border-bottom-color: #F0F5F8;
    border-bottom-style: solid;

    margin-bottom: 30px;

    position: relative;
    user-select: none;

    ${props => props.preText && css`
        display: inline-flex;
        width: 100%;
    `}
`;

const PreText = styled.div`
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
    letter-spacing: -1px;
    color: #000000;
    padding: 0 5px 10px;
    margin: 0;
`;

const MenuWrapper = styled.div`
    padding: 0 0 10px 0;
    width: 100%;
    
    ${props => props.leftBorder && css`
        border-left: white ${BUTTON_WIDTH}px solid;
    `}
    
    ${props => props.rightBorder && css`
        border-right: white ${BUTTON_WIDTH}px solid;
    `}

`;

const MenuItem = styled.a`
    color: #8A8A8A !important;
    font-size: 20px;
    text-align: center;
    margin: 0 13px;
    padding: 0 2px 9px;
    user-select: none;

    &:hover {
        color: #000000 !important;
    }

    ${props => props.active && css`
        color: #000000 !important;
        border-bottom-width: 2px;
        border-bottom-color: #135CA9;
        border-bottom-style: solid;
    `}
`;

const SideButton = styled(Button.Image)`
    position: absolute;

    top: 0;
    ${props => props.position}: 0;
    z-index: 2;
`;

const Tab = styled.div``;

const TabPane = ({ children }) => {
    return (
        <Tab>
            {children}
        </Tab>
    );
};

TabPane.displayName = 'TabPane';

const measureTabCaption = (text) => {
    return measureText({
        text: text,
        fontFamily: 'ClearSans',
        fontSize: '20px',
        fontWeight: 400,
    });
};

const useElementScroll = (ref) => {
    const [scrollLeft, setScrollLeft] = useState(0);
    const [scrollWidth, setScrollWidth] = useState(0);
    const [elementWidth, setElementWidth] = useState(0);

    const onScrollChange = (value) => {
        if (ref.current) {
            let node = ReactDOM.findDOMNode(ref.current);

            node.scrollLeft = value;

            setScrollLeft(node.scrollLeft);
            setScrollWidth(node.scrollWidth);
            setElementWidth(node.clientWidth);
        }
    };

    const observe = () => {
        let node = ReactDOM.findDOMNode(ref.current);

        setScrollLeft(node.scrollLeft);
        setScrollWidth(node.scrollWidth);
        setElementWidth(node.clientWidth);
    };

    useEffect(() => {
        if (ref.current) {
            // Observe the values first
            observe();

            window.addEventListener('resize', observe);
        }

        return () => {
            window.removeEventListener('resize', observe);
        };
    }, [ref && ref.current]);

    return [scrollLeft, onScrollChange, scrollWidth, elementWidth];
};

const Tabs = ({
    children,
    preText = '',
    style = {},
    activeTab, onTabChange
}) => {
    const ref = useRef();
    const [scrollLeft, onScrollChange, scrollWidth, elementWidth] = useElementScroll(ref);

    // Measuring each pane's width
    let tabPanes = filterByType(children, 'TabPane')
        .map(pane => ({
            ...pane,
            width: measureTabCaption(pane.props.caption) + LINK_OFFSET
        }));

    let activePane = tabPanes.find(p => p.key === activeTab);

    const onLeftClick = () => {
        let firstShown = 0,
            sum = -scrollLeft;

        for (let i = 0; i < tabPanes.length; i++) {
            let newSum = sum + tabPanes[i].width;

            if (sum < 0 && newSum >= 0) {
                firstShown = i;
                break;
            }

            sum = newSum;
        }

        let newIndex = firstShown >= TABS_STEP ? firstShown - TABS_STEP : 0;

        let offset = Math.ceil(tabPanes.slice(0, newIndex).map(p => p.width).reduce((a, v) => a + v, 0));

        onScrollChange(offset);
    };

    const onRightClick = () => {
        let firstShown = 0,
            sum = -scrollLeft;

        for (let i = 0; i < tabPanes.length; i++) {
            let newSum = sum + tabPanes[i].width;

            if (sum < 0 && newSum >= 0) {
                firstShown = i;
                break;
            }

            sum = newSum;
        }

        let newIndex = firstShown + TABS_STEP <= tabPanes.length ? firstShown + TABS_STEP : tabPanes.length;

        let offset = Math.ceil(tabPanes.slice(0, newIndex).map(p => p.width).reduce((a, v) => a + v));

        onScrollChange(offset);
    };

    const onTabClick = (key) => () => {
        // Pre-checking if tab's on the edge
        let tabIndex = tabPanes.findIndex(p => p.key === key);

        let tabLeftEdge = tabPanes.slice(0, tabIndex).map(p => p.width).reduce((a, v) => a + v, 0),
            tabRightEdge = tabLeftEdge + tabPanes[tabIndex].width;

        let overwhelmsLeft = scrollLeft - tabLeftEdge > 10,
            overwhelmsRight = (scrollLeft + elementWidth) - tabRightEdge < 10;

        if (overwhelmsLeft) {
            onScrollChange(tabLeftEdge);
        } else if (overwhelmsRight) {
            let fromLeftToEdge = elementWidth - (tabLeftEdge - scrollLeft);
            onScrollChange(scrollLeft + (tabPanes[tabIndex].width - fromLeftToEdge));
        }

        onTabChange(key);
    };

    let showLeft = scrollLeft > BUTTON_WIDTH,
        showRight = scrollWidth - scrollLeft - elementWidth > BUTTON_WIDTH;

    return (
        <Wrapper style={style}>
            <Menu preText={preText}>
                {preText && <PreText>{preText}</PreText>}
                <MenuWrapper
                    ref={ref}
                    leftBorder={showLeft}
                    rightBorder={showRight}
                >
                    {showLeft
                        && <SideButton
                            alternate
                            type='button-arrow-left'
                            position='left'
                            onClick={onLeftClick}
                        />
                    }
                    {tabPanes.map(p => (
                        <MenuItem
                            key={p.key}
                            active={activeTab === p.key}
                            onClick={onTabClick(p.key)}
                        >
                            {p.props.caption || null}
                        </MenuItem>
                    ))}
                    {showRight
                        && <SideButton
                            alternate
                            type='button-arrow-right'
                            position='right'
                            onClick={onRightClick}
                        />
                    }
                </MenuWrapper>
            </Menu>
            {activePane}
        </Wrapper>
    );
};

Tabs.Pane = TabPane;


export default Tabs;