import React from 'react';

export const filterByType = (children, type) => {
    const results = [];

    React.Children.forEach(children, child => {
        const childType =
            child && child.type && (child.type.displayName || child.type.name);

        if (childType === type) {
            results.push(child);
        }
    });

    return results;
};

export const firstByType = (children, type) => {
    let elements = filterByType(children, type);

    return elements.length > 0
        ? elements[0]
        : null;
};

export function measureText({
    text,
    fontFamily = 'ClearSans',
    fontSize = '16px',
    fontWeight = 400,
    fontStyle = 'normal'
}) {
    // re-use canvas object for better performance
    let canvas = measureText.canvas || (measureText.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = `${fontWeight} ${fontStyle} ${fontSize} ${fontFamily}`;

    return context.measureText(text).width;
}

export const firstSymbolToUpper = (str) => {
    return str && (str[0].toUpperCase() + str.substring(1, str.length));
};

export const isTander = ({ clientName, clientId }) => clientName === 'АО «Тандер»' || clientId === '5b06c5b70c7e6f6764d50d0e';

export const splitMultiline = str => str.split(/\\t|\\n|[,;]/);

// UA
export const sortClients = (arr, flat) => {
    if (!(arr && Array.isArray(arr) && arr.length)) return arr;

    const result = [];
    const rest = [];
    const order = ["АТБ-Маркет", "РУШ ЕВА", "МЕТРО", "АШАН"];

    arr.forEach(item => {
        const i = flat
            ? order.indexOf(item)
            : order.indexOf(item.name || item.text);

        if (i === -1) {
            rest.push(item);
        } else {
            result[i] = item;
        }
    });

    return result.filter(item => !!item).concat(rest);
};