import axios from 'axios';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import qs from 'qs';

const formatUrl = (url) => {
    return url;
};

export async function callApiAsync(url, method = 'get', data = undefined, params = undefined) {
    let headers = {
        'Content-Type': 'application/json; charset=utf-8',
        'Pragma': 'no-cache',
        'AppVersion': window.version,
    };

    if (typeof data === 'string' || data instanceof String) {
        data = JSON.stringify(data);
    }

    let config = {
        method,
        url: formatUrl(url),
        params,
        paramsSerializer: params => qs.stringify(params),
        headers,
        data
    };
    try {
        let response = await axios(config);
        if (response) {
            return response.data;
        }
        return undefined;
    } catch (err) {
        if (err && err.response && err.response.status === 426) {
            console.error('Your application is out of date');

            // Reloading page to update client
            window.location.reload();
        }
        if (err && err.response && err.response.data)
            return err.response.data;
        else
            throw err;
    }
}

export function useClickOutside(handle, deps = []) {
    const ref = useRef();
    const handleClickOutside = (event) => {
        if (ref) {
            const domNode = ReactDOM.findDOMNode(ref.current);

            if (domNode && !domNode.contains(event.target) && handle) {
                handle();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, deps);

    return ref;
}


export function useHiddenMenuRef(initialIsVisible) {
    const ref = useRef();
    const handleHideDropdown = (event) => {
        if (!ref.current)
            return;
        if (ref.current.classList.contains('open-sub-menu') && event.key === 'Escape') {
            ref.current.classList.remove('open-sub-menu');
        }
    };
    const handleClickOutside = (event) => {
        if (!ref.current)
            return;

        if (ref.current.classList.contains('open-sub-menu')) {
            ref.current.classList.remove('open-sub-menu');
        }
        else if (ref.current.contains(event.target) && !ref.current.classList.contains('open-sub-menu'))
            ref.current.classList.add('open-sub-menu');

    };

    useEffect(() => {
        if (initialIsVisible)
            ref.current.classList.add('open-sub-menu');
        document.addEventListener('keydown', handleHideDropdown, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return ref;
}

export function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}