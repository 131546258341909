import React from 'react';
import { Dropdown, Dimmer, Loader } from 'semantic-ui-react';
import ExportButton from '../../../../components/Button/ExportButton';
import { callApiAsync } from '../../../../hooks';
import { sortClients } from '../../../../utils';

export default class ActiveConsignorStats extends React.Component {
    state = {
        isLoading: false,

        stats: [],
        totalCount: 0,

        clients: [],
        years: [],
        weekNumbers: [],
        weekHeader: [],

        filter: {
            clientId: '',
            year: 0,
            weekNumberFrom: 0,
            weekNumberTo: 0
        },

        exportEnabled: false
    };

    componentDidMount() {
        this.init();
    }

    init = async () => {
        const clients = await callApiAsync('/api/definitions/clients', 'GET');
        const sortedClients = sortClients(clients);

        const filter = {
            clientId: ((sortedClients || [])[0] || {}).id || '',
            year: 0,
            weekNumberFrom: 0,
            weekNumberTo: 0
        };

        this.setState({ filter }, this.reload);
    }

    reload = async () => {
        this.setState({ exportEnabled: false });
        let model = {
            clientId: this.state.filter.clientId,
            year: this.state.filter.year,
            weekNumberFrom: this.state.filter.weekNumberFrom,
            weekNumberTo: this.state.filter.weekNumberTo
        };

        let data = await callApiAsync('api/stats/activeConsignorList', 'POST', model);
        this.setState({
            ...data, 
            exportEnabled: true,
            clients: sortClients(data.clients)
        });
    }

    updateFilterValue(value) {
        const updatedFilter = Object.assign({}, this.state.filter, value);

        this.setState({
            filter: updatedFilter
        }, this.reload);
    }

    handleFilterChange(obj) {
        let field = obj.name;
        let value = obj.value;

        if (this.state.filter[field] === value)
            return;

        this.updateFilterValue({ [field]: value });
    }

    stats() {
        let zero = <span className="text-danger">0</span>;

        return this.state.stats.map((stat, index) => {

            return (
                <tr key={index}>
                    <td>
                        {stat.consignor}
                    </td>
                    {this.state.weekHeader.map((week, index) => {

                        return (
                            <td>
                                {stat.palletCountByWeekNumber && stat.palletCountByWeekNumber.hasOwnProperty(week) ? stat.palletCountByWeekNumber[week] : zero}
                            </td>
                        );
                    })}
                </tr>
            );
        });
    }

    render() {
        return (
            <div>
                <Dimmer inverted active={this.state.isLoading}>
                    <Loader inverted />
                </Dimmer>
                <div className="row">

                    <div className="col-3">
                        <label>Клиент</label>
                        <Dropdown fluid selection name='clientId'
                            noResultsMessage="Ничего не найдено"
                            onChange={(e, obj) => this.handleFilterChange(obj)} value={this.state.filter.clientId}
                            options={(this.state.clients.map(x => {
                                return { text: x.name, value: x.id };
                            }))} />
                    </div>

                    <div className="col-3">
                        <label>Год</label>
                        <Dropdown fluid selection name='year'
                            noResultsMessage="Ничего не найдено"
                            onChange={(e, obj) => this.handleFilterChange(obj)} value={this.state.filter.year}
                            options={(this.state.years.map(x => {
                                return { text: x, value: x };
                            }))} />
                    </div>

                    <div className="col-3">
                        <label>Неделя с</label>
                        <Dropdown fluid selection name='weekNumberFrom'
                            noResultsMessage="Ничего не найдено"
                            onChange={(e, obj) => this.handleFilterChange(obj)} value={this.state.filter.weekNumberFrom}
                            options={(this.state.weekNumbers.map(x => {
                                return { text: x, value: x };
                            }))} />
                    </div>

                    <div className="col-3">
                        <label>по</label>
                        <Dropdown fluid selection name='weekNumberTo'
                            noResultsMessage="Ничего не найдено"
                            onChange={(e, obj) => this.handleFilterChange(obj)} value={this.state.filter.weekNumberTo}
                            options={(this.state.weekNumbers.map(x => {
                                return { text: x, value: x };
                            }))} />
                    </div>
                    <div className="col-6">
                        <div className="float-right">
                            <ExportButton
                                disabled={!this.state.exportEnabled}
                                reportType='consignorPalletStat'
                                style={{ marginTop: 26 }}
                                params={{
                                    weekFrom: this.state.filter.weekNumberFrom,
                                    weekTo: this.state.filter.weekNumberTo,
                                    year: this.state.filter.year,
                                    clientId: this.state.filter.clientId
                                }}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className="container-fluid pb-3" style={{ 'text-align': 'center' }}>
                    <h3>Недели</h3>
                </div>
                <div className="table table-responsive table-bordered table-sm">
                    <table>
                        <thead>
                            <tr>
                                <th className="w-10">Поставщик</th>
                                {this.state.weekHeader.map((week, index) => {
                                    return (
                                        <th>
                                            {week}
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.stats()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};