import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';
import moment from 'moment';

import { StyledDatepicker, IconWrapper } from './ReactDatepicker.styles';

import Svg from '../Svg';

const propTypes = {
    className: PropTypes.string,
    width: PropTypes.string,
    momentFallback: PropTypes.bool,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.instanceOf(Date).isRequired,
        PropTypes.string,
        PropTypes.oneOf([null]),
    ]),
    fluid: PropTypes.bool,
    returnFormat: PropTypes.string,
    dateFormat: PropTypes.string,
    placeholderText: PropTypes.string,
    filterDate: PropTypes.func,
    onChange: PropTypes.func.isRequired,
};

const ReactDatepicker = ({
    className = '',
    momentFallback,
    width = '145px',
    name,
    value,
    onChange,
    fluid,
    returnFormat,
    isClearable,
    error,
    dateFormat = 'dd, DD.MM.YY',
    placeholderText = 'пн, дд.мм.рр',
    filterDate,
    showTimeSelect,
    minDate,
    maxDate,
    ...rest
}) => {

    const dpRef = useRef(null);

    const handleChange = momentFallback
        ? onChange
        : (moment, e) => onChange(e, {
            name,
            value: moment ? (!returnFormat ? moment.toDate() : moment.format(returnFormat)) : null
        });

    const selected = momentFallback ? value : value && moment(value);

    const handleIconClick = () => {
        dpRef.current.input.focus();
    };

    const handleFilterDate = moment => {
        if (!filterDate) return true;

        return filterDate(moment.toDate());
    };

    return (
        <StyledDatepicker
            style={{ width: `${!fluid ? width : "100%"}` }}
            className={
                `datepicker-container ${className} ${error ? "error" : ""}` +
                `${showTimeSelect ? ' datepicker-container--with-time' : ''}`
            }
        >
            <DatePicker
                {...(filterDate ? { filterDate: handleFilterDate } : {})}
                dateFormat={dateFormat}
                placeholderText={placeholderText}
                locale='uk-UA'
                showTimeSelect={showTimeSelect}
                {...(minDate ? { minDate: moment(minDate) } : {})}
                {...(maxDate ? { maxDate: moment(maxDate) } : {})}
                {...rest}
                ref={dpRef}
                selected={selected}
                onChange={handleChange}
                isClearable={isClearable}
            />
            {isClearable && selected
                ? null
                : <IconWrapper onClick={handleIconClick}>
                    <Svg type='calendar' />
                </IconWrapper>
            }
        </StyledDatepicker>
    );
};

ReactDatepicker.propTypes = propTypes;
export default ReactDatepicker;