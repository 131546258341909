import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    font-family: 'ClearSans';
    line-height: normal;
    font-size: 1rem;
    padding: 0.5rem 0.9rem;
    background: #fff;
    // border: 1px solid #696969;
    border: 1px solid #aaa;
    border-radius: 1.35rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-flex;

    ${props => props.width && css`
        width: ${props.width};
    `}

    ${props => props.error && css`
        border-color: #990202;

        &:focus {
            border-color: #990202;
        }
    `}

    ${props => props.size === "small" && css`
        font-size: 0.9rem;
    `}
`;

export const Input = styled.input`
    margin: 0;
    max-width: 100%;
    background-color: transparent;
    color: #202020;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    outline: none;
    text-align: ${props => props.textAlign};
    width: 100%;

    &::-ms-clear {
        display: none;
    }

    &::placeholder {
        color: #C4C4C4;
        text-align: ${props => props.textAlign};
        user-select: none;
    }

    &:focus {
        border-color: #135CA9;
    }

    ${props => props.disabled && css`
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-color: #C4C4C4;
        color: #C4C4C4;
    `}
`;

export const PreText = styled.div`
    font-family: 'ClearSans';
    font-size: 1rem;
    line-height: normal;
    color: #C4C4C4;
    margin-right: 4px;
    user-select: none;

    ${props => props.active && css`
        color: #202020;
    `}
`;