import styled, { css } from 'styled-components';
import Input from '../../../components/NewInput/Input';


export const Wrapper = styled.div`
    padding: 40px 40px;
    height: 100%;
`;

export const Header = styled.h1`
    font-family: 'ClearSans';
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 38px;
    color: #135CA9;

    margin-bottom: 30px;
`;

export const SubHeader = styled.h2`
    font-family: "ClearSans";
    font-size: 22px;
    font-weight: bold;
    line-height: 30px;
    color: #000000;

    margin-bottom: 30px;
`;

export const TabWrapper = styled.div`
    display: flex;
    padding: 0 5px;
`;

export const FilterBlock = styled.div`
    width: 250px;
    margin-right: 85px;
    display: block;
`;

export const FilterName = styled.p`
    font-family: 'ClearSans';
    font-size: 1rem;
    line-height: 22px;
    color: #000000;
    margin-bottom: 8px;

    &:not(:first-child) {
        margin-top: 15px;
    }
`;

export const Block = styled.div`
    display: block;

    &:not(:first-child) {
        margin-right: 80px;

        @media (max-width: 1300px) {
            margin-right: 40px;
        }
    }
`;

export const BlockName = styled.p`
    font-family: 'ClearSans';
    font-size: 1rem;
    line-height: 24px;
    color: #000000;
    margin-bottom: 15px;
`;

export const BlockRow = styled.div`
    display: flex;

    ${props => props.minHeight && css`
        min-height: ${props.minHeight};
    `}

    &:not(:last-child) {
        margin-bottom: 30px;
    }
`;

export const BlockColumn = styled.div`
    display: block;

    ${props => props.marginRight && css`
        margin-right: ${props.marginRight};
    `}

    ${props => props.width && css`
        width: ${props.width};
    `}
`;

export const ProgressWrapper = styled.div`
    margin-right: 85px;

    & > :not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const ProgressBar = styled.div`
    display: flex;
    justify-content: center;
`;

export const ProgressName = styled.p`
    font-family: 'ClearSans';
    font-size: 1rem;
    line-height: 24px;
    color: #000000;
    margin-bottom: 8px;
`;

export const NumberInput = styled(Input.Integer)`
    width: 100px;
`;