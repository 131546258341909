import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import Tooltip from '../../components/Tooltip';

export const CustomModal = styled(Modal)`
    &.ui.modal {
        width: auto;
        max-width: 90%;
        min-width: min-content;
        top: auto;
        transform: none;
    }

    border-radius: 15px !important;

    padding-bottom: 50px;

    .pooling__dropdown {
        border-color: #aaa;
    }
`;

export const Header = styled.div`
    padding: 25px;
    padding-right: 60px;
    position: relative;
    background-color: #FFFFFF;
    min-height: 150px;

    display: flex;
`;

export const HeaderTextsWrapper = styled.div`
    flex-grow: 1;
    margin-right: 2rem;
`;

export const HeaderTitle = styled.h3`
    margin-bottom: 1.5rem;
    font-family: 'ClearSans';
    font-size: 1.75rem;
    font-weight: bold;
    padding: 0.5rem 0;
    color: #135CA9;
`;

export const HeaderInfo = styled.div`
    display: flex;
`;

export const HeaderCloseButton = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
`;

export const HeaderField = styled.div`
    font-family: 'ClearSans';
    font-size: 1rem;
    font-weight: normal;
    color: #202020;

    margin-right: 2rem;

    &:last-child {
        margin-right: 0;
    }

    /* Align */
    display: inline-flex;
    align-items: center;

    & svg {
        margin-right: 0.5rem;
    }
`;

export const HeaderFieldTooltip = styled(Tooltip)`
    display: inline-flex;
    height: 100%;
    align-items: center;
`;

export const HeaderLogoWrapper = styled.div`
    flex-shrink: 0;
    height: 100px;
    width: 140px;
`;

export const HeaderLogo = styled.i`
    display: block;
    height: 45px;

    background: url("${props => props.src}") center center no-repeat;
    background-size: contain;
`;

export const HeaderCompanyName = styled.p`
    font-family: 'ClearSans', Arial;
    font-weight: bold;

    font-size: 1.142857rem;
    line-height: 1.7142857rem;

    margin: 0.5rem 0;
    direction: rtl;

    color: #135CA9;

    & > i {
        font-size: 0px;
        user-select: none;
    }
`;

export const ContentRow = styled.div`
    margin-bottom: 20px;
`;

export const FlexContentRow = styled(ContentRow)`
    display: flex;
`;

export const ButtonRow = styled.div`
    display: flex;
    justify-content: center;
    margin: 40px 0 10px;

    & >:not(:last-child) {
        margin-right: 20px;
    }
`;

export const ContentField = styled.div`

    &:not(:last-child) {
        margin-right: 30px;
    }
`;

export const TimeField = styled(ContentField)`
    & >:not(:last-child) {
        margin-right: 10px;
    }
`;

export const DateTimeField = styled(ContentField)`
    & > :not(:last-child) {
        margin-right: 30px;
    }

    & > div {
        display: inline-block;
    }
`;

export const TimeWarningTooltip = styled(Tooltip)`
    & > :last-child {
        bottom: 115%;
    }
`;

export const ContentFieldLabel = styled.label`
    display: block;

    font-family: 'ClearSans', Arial;
    font-size: 1rem;
    color: #202020;
`;

export const TableWrapper = styled.div`
    border-radius: 15px;
    border: 1px solid #C4D6E9;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
`;

export const Table = styled.table`
    width: 100%;

`;

export const TableHeader = styled.thead`
    border-bottom: 1px solid #C4D6E9;
`;

export const TableHeaderCell = styled.th`
    font-family: 'ClearSans', Arial;
    font-size: 1rem;
    line-height: 38px;
    color: #202020;
    padding: 0 10px;

    text-align: left;

    ${prop => prop.align === "center" && css`
        text-align: center;
    `}

    tr > &:first-child{
        padding-left: 15px;
    }
`;

export const TableBodyCell = styled.td`
    padding: 5px 10px;
    text-align: left;

    & > :not(:last-child) {
        margin-right: 10px;
    }
    ${prop => prop.align === "center" && css`
        text-align: center;
    `}
    tbody > tr:first-child > &{
        padding-top: 15px;
    }
    tbody > tr:last-child > &{
        padding-bottom: 15px;
    }
`;

export const StyledDeliveryDate = styled.span`
    display: block;
    padding: 0.5714285rem 0;
    font-weight: 700;
    line-height: normal;
`;

export const StyledTextareaAutosize = styled.div`
    overflow: hidden;
    display: flex;
    border-radius: calc(1.35rem - 1px);
    box-shadow: 0em 0em 0em 0em transparent inset;
    transition: color 0.1s ease, border-color 0.1s ease;
    vertical-align: top;
    width: 100%;
    
    textarea {
        width: 100%;
        margin: 0;
        -webkit-appearance: none;
        tap-highlight-color: rgba(255, 255, 255, 0);
        padding: 0.78571429em 1em;
        background: #FFFFFF;
        outline: none;
        color: rgba(0, 0, 0, 0.87);
        font-size: 1em;
        line-height: 1.2857;
        border: 1px solid #aaa;
        border-radius: 1.35rem;
        box-shadow: none;
        outline: none;
    
        height: 12em;
    
        &:not([rows]) {
            min-height: 38px;
        }

        resize: none;
        padding: 0.42857143em 1em;
        line-height: 1.5em;

        &:hover,
        &:focus {
            border-color: #135CA9;
            color: rgba(0, 0, 0, 0.8);
            box-shadow: none;
        }
    }

    textarea::-webkit-scrollbar {
        width: 14px !important;
        cursor: default;
    }

    textarea::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-right: 5px solid transparent !important;
        border-left: 5px solid transparent !important;
        background-color: #135CA9 !important;
        background-clip: padding-box !important;
    }

    textarea::-webkit-scrollbar-track {
        background-color: transparent !important;
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        margin-top: 15px;
        margin-bottom: 15px;
        cursor: default;
    }
`;