import React, { useState, useCallback, useEffect } from 'react';

import { Table, Form, Radio, Popup } from 'semantic-ui-react';

import _debounce from 'lodash/debounce';

import Svg from '../../../../components/Svg';

import { StyledReservationFormTab, StyledSavedLabel, StyledResetButton } from './ReservationFormTab.style.js';
import ReservationOrdersDetails from '../../../ReservationOrdersDetails/ReservationOrdersDetails.jsx';
import { loadSettings, saveSettings, resetSettings } from './fetchers';

const settingsFields = {
    palletType: {
        title: 'Палети',
        values: [{
            id: 0,
            label: 'Мікс-палети',
            tooltip: 'Вводяться номери палет за замовленнями і загальна кількість палет'
        }, {
            id: 1,
            label: 'Моно-палети',
            tooltip: 'Вводиться кількість палет по замовленнях. Загальна кількість палет підсумовується автоматично'
        }]
    },
    boxType: {
        title: 'Короба',
        values: [{
            id: 0,
            label: 'Не вводити кількість коробів'
        }, {
            id: 1,
            label: 'Загальна кількість коробів',
        }, {
            id: 2,
            label: 'Кількість коробів на замовлення ',
            tooltip: 'Вводиться кількість коробів в окремих замовленнях. Загальна кількість коробів підсумовується автоматично'
        }]
    },
    costType: {
        title: 'Вартість',
        values: [
            {
                id: 0,
                label: 'Загальна вартість',
                tooltip: ''
            }, {
                id: 1,
                label: 'Вартість замовлення',
                tooltip: 'Вводиться вартість окремих замовлень. Загальна вартість підсумовується автоматично'
            }
        ]
    },
    weightType: {
        title: 'Вага',
        values: [
            {
                id: 0,
                label: 'Загальна вага',
                tooltip: ''
            }, {
                id: 1,
                label: 'Вага на замовлення',
                tooltip: 'Вводиться вага окремих замовлень. Загальна вага підсумовується автоматично'
            }
        ]
    },
    orderType: {
        title: 'Тип замовлення',
        values: [
            {
                id: 0,
                label: 'Не вводити тип замовлення'
            }, {
                id: 1,
                label: 'Вводити тип замовлення'
            }
        ]
    }
};


const defaultSettings = {
    palletType: 0,
    boxType: 0,
    costType: 0,
    weightType: 0,
    orderType: 1,
};

let savedSettings;

const ReservationFormTab = () => {
    const [settings, updateSettings] = useState(null);
    const [loading, setLoading] = useState(true);
    const [resetButtonActive, setResetButtonActive] = useState(false);
    const [showSavedLabelAt, setSavedLabelPosition] = useState(null);
    const [showSavedLabelErrorAt, setSavedLabelErrorPosition] = useState(null);

    const init = useCallback(async () => {
        const settings = await loadSettings();

        savedSettings = settings;
        updateSettings(settings);
        setLoading(false);
    }, []);

    const hideLabel = _debounce(() => {
        setSavedLabelPosition('');
    }, 3000);

    const handleSave = useCallback(async (settings, updatedKey) => {
        const result = await saveSettings(settings);

        if (!result.error) {
            savedSettings = settings;
            setSavedLabelPosition(updatedKey);

            hideLabel();
        } else {
            setSavedLabelErrorPosition(updatedKey);

            updateSettings(savedSettings);
        }
    }, []);

    const handleReset = useCallback(async () => {
        const settings = await resetSettings();

        if (settings && !settings.error) {
            savedSettings = settings;

            updateSettings(settings);
            setSavedLabelPosition(null);
            setSavedLabelErrorPosition(null);
        } else {
            setSavedLabelErrorPosition('resetButton');

            updateSettings(savedSettings);
        }
    }, []);

    const handleSelect = (e, { name, value }) => {
        const newSettings = {
            ...settings,
            [name]: value
        };

        handleSave(newSettings, name);
        updateSettings(newSettings);
    };

    useEffect(() => {
        const show = settings && Object.keys(defaultSettings).some(k => defaultSettings[k] !== settings[k]);

        setResetButtonActive(show);
    }, [settings]);

    useEffect(() => {
        init();
    }, []);

    if (loading) return (
        <div className="loader">
            <Svg
                type='loader'
                width={80}
                height={80}
            />
        </div>
    );

    const fields = Object.keys(settingsFields);

    return (
        <StyledReservationFormTab className="reservation-form-tab">
            <h2 className="reservation-form-tab__title">
                {'Налаштування полів на формі бронювання'}
            </h2>
            <p className="reservation-form-tab__subtitle">
                {'Вид форми бронювання'}
            </p>
            <div className="reservation-form-tab__form-demo">
                <ReservationOrdersDetails
                    mode="demo"
                    settings={settings}
                />
            </div>
            <div className="reservation-form-tab__reset">
                <StyledResetButton
                    className="underlined"
                    hidden={!resetButtonActive}
                    onClick={handleReset}
                >
                    {'Скинути всі налаштування форми до значень за замовчуванням'}
                </StyledResetButton>
                <StyledSavedLabel
                    show={showSavedLabelErrorAt && showSavedLabelErrorAt === 'resetButton'}
                    error
                    inline
                >
                    {'Не вдалося скинути налаштування до значень за замовчуванням. Спробуйте пізніше'}
                </StyledSavedLabel>
            </div>
            <Table>
                <Table.Header>
                    <Table.Row>
                        {fields.map((k, index) => (
                            <Table.HeaderCell key={index}>
                                {settingsFields[k].title}
                            </Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        {fields.map((k, index) => (
                            <Table.Cell key={index}>
                                {settingsFields[k].values.map(item => (
                                    <Form.Field key={item.id}>
                                        {item.tooltip
                                            ? (
                                                <Popup
                                                    className="warning"
                                                    mouseEnterDelay={1000}
                                                    trigger={(
                                                        <Radio
                                                            label={item.label}
                                                            name={k}
                                                            value={item.id}
                                                            checked={settings[k] === item.id}
                                                            onChange={handleSelect}
                                                        />
                                                    )}
                                                    position="right center"
                                                    wide
                                                    content={item.tooltip}
                                                />
                                            ) : (
                                                <Radio
                                                    label={item.label}
                                                    name={k}
                                                    value={item.id}
                                                    checked={settings[k] === item.id}
                                                    onChange={handleSelect}
                                                />
                                            )
                                        }
                                    </Form.Field>
                                ))}
                                <StyledSavedLabel
                                    show={showSavedLabelAt && showSavedLabelAt === k}
                                >
                                    {'Зміни збережені'}
                                </StyledSavedLabel>
                                <StyledSavedLabel
                                    show={showSavedLabelErrorAt && showSavedLabelErrorAt === k}
                                    error
                                >
                                    {'Не вдалося внести зміни. Спробуйте пізніше'}
                                </StyledSavedLabel>
                            </Table.Cell>
                        ))}
                    </Table.Row>
                </Table.Body>
            </Table>
        </StyledReservationFormTab >
    );
};

export default ReservationFormTab;