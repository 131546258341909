import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { UserContext as UserData } from './context';

const PrivateRoute = ({ component: Component, permissionName, userRole, ...rest }) => {
    const data = useContext(UserData);
    const isAvailable = data.isAuthorized()
        && (!permissionName || data.hasPermission(permissionName))
        && (!userRole || data.getRole() === userRole);

    return (
        <Route
            {...rest}
            render={props =>
                isAvailable
                    ? <Component {...props} />
                    : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            }
        />
    );
};

export default PrivateRoute;
