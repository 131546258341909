import styled from 'styled-components';

export const CarouselWrapper = styled.div`
    display: flex;
    flex-direction: row;
    user-select: none;
`;

export const ArrowContainer = styled.div`
    display: flex;
    align-items: center;
    min-width: 22px;

    & div{
        cursor: pointer;
        margin-bottom: 50px;
    }
`;

export const TabContainer = styled.div`
    flex-grow: 1;
    margin: 0 15px;
    overflow-x: hidden;

    & .ui.segment {
        border: none !important;
        box-shadow: none !important;
    }
    & .attached.menu {
        border: none !important;
        box-shadow: none !important;
        justify-content: center;
    }
`;