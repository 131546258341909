import styled from 'styled-components';

const primeColor = '#135CA9';
const navigationDefault = '#475566';
const dayHover = '#f0f0f0';

export const StyledDatepicker = styled.div`
    position: relative;

    &.datepicker-container--with-time {
        .react-datepicker {
            min-width: 320px;
        }

        .react-datepicker__month-container {
            width: 248px;
        }

        .react-datepicker__time-container {
            width: 70px;
        }
    }

    .react-datepicker__input-container > input {
        max-width: 100%;
        margin: 0;
        flex: 1 0 auto;
        outline: none;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        line-height: 1.21428571em;
        font-family: "ClearSans", Arial, sans-serif;
        padding: 0.67857143em 1.2em !important;
        padding-right: 2.5em !important;
        background: #FFFFFF;
        border: 1px solid #aaa;
        color: rgba(0, 0, 0, 0.87);
        border-radius: 1.35rem;
        transition: box-shadow 0.1s ease, border-color 0.1s ease;
        box-shadow: none;

        &:focus {
            border-color: ${primeColor};
            background: #FFFFFF;
            color: rgba(0, 0, 0, 0.8);
            box-shadow: none;
        }
    }

    .react-datepicker-wrapper {
        display: block !important;
    }

    .react-datepicker__input-container {
        width: 100%;
    }
    .react-datepicker-popper {
        z-index: 5;
    }

    .react-datepicker__input-container > input{
        min-width: 7.5rem;
    }

    .react-datepicker__close-icon {
        height: unset;
        width: 18px;
        right: 7px;
        top: 0;
        bottom: 0;
        display: inline-flex;
        align-items: center;
    }

    .react-datepicker__close-icon::after {
        background-color: transparent ;
        color: #333 ;
        font-size: 1.3em ;
        height: unset ;
        width: unset ;
        line-height: unset ;
        margin: 0 ;
        padding: 0 ;
        position: inherit ;
        font-weight: bold;
        text-align: center;
        bottom: unset ;
        right: unset ;
    }

    .react-datepicker__close-icon:hover::after{
        color: ${primeColor};
    }

    .react-datepicker {
        border-radius: 1.35rem;
        border: 1px solid ${primeColor};
        font-size: 0.9em;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
        font-size: 1.1em;
        font-weight: normal;
    }

    .react-datepicker__header {
        background: #fff;
        border-bottom-color: ${primeColor};
        border-top-left-radius: 1.3rem;
        border-top-right-radius: 1.3rem;
        padding-top: 0.8rem;
    }

    .react-datepicker__time-container {
        border-color: ${primeColor};
        border-bottom-right-radius: 1.35rem;
        border-top-right-radius: 1.35rem;
        overflow: hidden;
    }

    .react-datepicker__current-month {
        text-transform: capitalize;
        padding: 0 3rem 0.2rem;
    }

    .react-datepicker__navigation {
        border: 2px solid ${navigationDefault};
        transform: rotate(-45deg);
        width: 8px;
        height: 8px;
        top: 17px;

        &:hover,
        &:focus {
            border-color: ${primeColor};
        }

        &:focus {
            outline: none;
        }
    }

    .react-datepicker__navigation--previous {
        left: 20px;
        border-bottom: none;
        border-right: none;
    }

    .react-datepicker__navigation--next {
        &:not(.react-datepicker__navigation--next--with-time) {
            right: 20px;
        }

        border-top: none;
        border-left: none;
    }

    .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle,
    .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
        border-bottom-color: #fff;
    }

    .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
        border-bottom-color: ${primeColor};
    }

    .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
        border-top-color: ${primeColor};
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        margin: 0 0.1rem;
        width: 2.2rem;
        line-height: 2.2rem;
    }

    .react-datepicker__day,
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
        border-radius: 50%;
    }

    .react-datepicker__day {
        &:hover,
        &:focus {
            background-color: ${dayHover};
            outline: none;
        }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__day--keyboard-selected {
        background-color: ${primeColor};
        color: #fff;

        &:hover,
        &:focus {
            background-color: ${primeColor};
        }
    }

    .react-datepicker__day--disabled {
        &:hover,
        &:focus {
            background-color: #fff;
            outline: none;
        }
    }

    .react-datepicker__day--outside-month:not(.react-datepicker__day--disabled){
        color: #777;
    }

    &.error input {
        background: #FFF6F6 !important;
        border-color: #E0B4B4 !important;
        color: #9F3A38 !important;
    }
`;

export const IconWrapper = styled.span`
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 1.143em;
    transform: translateY(-50%);

    &:hover path {
        fill: ${primeColor};
    }

    & > svg {
        display: block;
    }
`;