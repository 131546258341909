exports.Exclusions = [
    {
        address: '416130, Астраханская обл, Наримановский р-н, дом № в 6 км. северо-западнее с. Солянка, в 1,5 км. северо-восточнее п. Тинаки',
        pos: '46.418186 47.946383'
    },
    {
        address: '650515, Кемеровская обл, Кемеровский р-н, Ягуново с, 300 метров по направлению на восток',
        pos: '55.245022 86.033592'
    },
    {
        address: '445130, Самарская обл, Ставропольский р-н, Васильевка с, Поля № 28.29.34-38.86-91 земельный участок сельхозугодий Борзова С',
        pos: '53.552534 49.529984'
    },
    {
        address: '141800, Московская обл, Дмитровский р-н, Дмитров г, территория 4-й км автодороги &quot;Дмитров - Орудьево - Жуковка&quot;, владение№3, стр 1',
        pos: '56.337538 37.511004'
    },
    {
        address: 'Республика Адыгея , г. Адыгейск , км27+150м, слева, западнее г. Адыгей, ск. уч.2',
        pos: '44.872034 39.181544'
    },
    {
        address: 'Российская Федерация 396002 Рамонский р-н,с Айдарово Промышленная зона 2 тер,зона 4,учас РЦ Рамонь',
        pos: '51.800328 39.199898'
    },
    {
        address: 'Российская Федерация 396002 Рамонский р-н,с Айдарово Промышленная зона 2 тер,зона 4,учас РЦ Черноземье',
        pos: '51.800328 39.199898'
    },
    {
        address: 'РФ, 607684, Нижегородская обл., д. Опалиха, Кстовский индустриальный парк',
        pos: '56.197847 44.123800'
    },
    {
        address: 'Российская Федерация 346703 Аксайский р-н, х. Ленина ул. Логопарк, 1, к.1',
        pos: '47.128525 39.899026'
    },
    {
        address: 'Российская Федерация 141031 Московская обл, Мытищинский р-н, Мытищи г.,&nbsp;&nbsp;ТПЗ Алтуфьево тер,&nbsp;Липкинское ш, 2-й км, вл.1, стр.1',
        pos: '55.917912 37.562184'
    },
    {
        address: 'Российская Федерация 396002 Рамонский р-н,с Айдарово Промышленная зона 2 тер,зона 4,учас РЦ Рамонь',
        pos: ' 51.800328 39.199898'
    },
    {
        address: 'Российская Федерация 396002 Рамонский р-н,с Айдарово Промышленная зона 2 тер,зона 4,учас РЦ Черноземье',
        pos: ' 51.800328 39.199898'
    },
    {
        address: 'РФ, 607684, Нижегородская обл., д. Опалиха, Кстовский индустриальный парк',
        pos: '56.197847 44.123800'
    },
    {
        address: 'Российская Федерация 346703 Аксайский р-н, х. Ленина ул. Логопарк, 1, к.1',
        pos: '47.128525 39.899026'
    },
    {
        address: 'Кольцовский тракт, д.13/1, тер.Логопарк Кольцовский',
        pos: '56.758040 60.782603'
    },
    {
        address: 'ул. Центральная,29',
        pos: '51.706128 55.109592'
    },
    {
        address: 'р-н Южная промзона',
        pos: '61.787374 34.354325'
    },
    {
        address: 'Солнечногорский р-н, с.Пешковское, д.Шелепаново, РЦ &quot;Север&quot;, стр.152/2',
        pos: '56.113871 37.180421'
    },
    {
        address: 'ул.Гвардейская, д.57/1, складскойкомплекс "Сигма"',
        pos: '54.756492 56.249109'
    },
    {
        address: 'Костромское шоссе, земельный участок №3',
        pos: '57.547283 39.907207'
    },
    {
        address: 'с.Айдарово, тер.Промышленная зона 2,стр.13, логистический склад, литер А',
        pos: '55.652557 48.176873'
    },
    {
        address: '58-й км. Горьковского ш. автомагистрали Москва-Нижний Новгород, промплощадка №1',
        pos: '55.829117 38.444202'
    },
    {
        address: 'Белоярский р-н, 28 км. Тюменскоготракта, Логопарк "Кольцевая-Восток"',
        pos: '56.742688 60.994263'
    },
    {
        address: 'Баранцевский с.о., д. Новоселки',
        pos: '55.129408 37.546836'
    },
];