import "./DateManageModal.style.css";
import React from "react";
import { Modal, Button, Message, Form } from "semantic-ui-react";

import { format } from '../../utils/dateUtils';

import DatePicker from '../../components/ReactDatepicker/ReactDatepicker';
import { callApiAsync } from '../../hooks';

export default class DateManageModal extends React.Component {

    state = {
        modal: false,
        error: '',
        time: new Date()
    };

    toggle = () => {
        if (!this.state.modal) {
            this.setState({ error: null });
            this.getTime();
        }

        this.setState({ modal: !this.state.modal });
    }

    getTime = async () => {
        const data = await callApiAsync('/api/date', 'GET');

        this.setState({
            error: null,
            time: new Date(data)
        });
    }

    setTime = async (value) => {
        await callApiAsync('/api/date', 'POST', value ? format(value, "yyyy-MM-dd'T'HH:mm") : null);

        this.setState({
            error: null,
            time: value
        });
    }

    resetTime = async () => {
        const data = await callApiAsync('/api/date/reset', 'GET');

        this.setState({
            error: null,
            time: new Date(data)
        });
    }

    render() {
        if (window.isProduction)
            return null;

        return (
            <Modal open={this.state.modal} size="small"
                closeOnDimmerClick onClose={this.toggle}
                trigger={<div style={this.props.style} onClick={this.toggle}>Настройка даты сервера</div>}
            >
                <Modal.Header>Дата на сервере</Modal.Header>
                <Modal.Content>
                    <Form className="date-manage-picker">
                        <Form.Field>
                            <DatePicker
                                width="200px"
                                name="time"
                                value={this.state.time}
                                showTimeSelect
                                timeIntervals={10}
                                dateFormat="lll"
                                timeFormat="HH:mm"
                                timeCaption="Время"
                                popperPlacement="bottom"
                                onChange={(e, { name, value }) => this.setTime(value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Button content='Сбросить время' icon='refresh' labelPosition='left'
                                onClick={this.resetTime} />
                        </Form.Field>
                    </Form>
                    {this.state.error
                        ? <Message error
                            content={this.state.error}
                        />
                        : null}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.toggle}>Закрыть</Button>
                </Modal.Actions>
            </Modal>
        );
    }
};