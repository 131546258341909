import './Filter.style.css';
import React from 'react';
import { Grid, Dropdown, Button, Icon } from 'semantic-ui-react';

import { UserContext as UserData } from '../../../context';
import { sortClients } from '../../../utils';

export default class Filter extends React.Component {
    handleChange = (e, { name, value }) => {
        if (name === 'currentType') {
            if (value === this.props.carType)
                return;
        }

        if (name === 'currentClientId') {
            if (value === this.props.currentClientId)
                return;
        }

        this.props.onChange(name, value);
    }

    render() {
        return (
            <UserData.Consumer>
                {userData => (
                    <div className="container no-top calendar-filter filter">
                        <Grid columns={5} className="filter-row" style={{ margin: 0 }}>
                            <Grid.Row>
                                <Grid.Column className="select-date centered-height" width={4}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Button primary icon circular size='large' onClick={this.props.onToLeft}>
                                                <Icon name="angle left" />
                                            </Button>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <h3>{this.props.weekDescription}</h3>
                                            <a className={this.props.isCurrentWeek ? "current-week" : null} onClick={() => { this.props.onToCurrentWeek(); }}>
                                                <p>На поточний тиждень</p>
                                            </a>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button primary icon circular size='large' onClick={this.props.onToRight}>
                                                <Icon name="angle right" />
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid.Column>
                                <Grid.Column className="centered-height" width={3}>
                                    <Button.Group className="circular ingrid">
                                        <Button className={this.props.carType === 'tent' ? 'active' : ''}
                                            name='currentType' value='tent' onClick={this.handleChange}>
                                            Тент
                                        </Button>
                                        <Button className={this.props.carType === 'ref' ? 'active' : ''}
                                            name='currentType' value='ref' onClick={this.handleChange}>
                                            Реф
                                        </Button>
                                    </Button.Group>
                                </Grid.Column>
                                <Grid.Column className='filter-col' width={3}>
                                    {!userData.isAuthorized() || userData.getRole !== 'Торгівельна мережа'
                                        ? <>
                                            <h4>Клієнт</h4>
                                            <Dropdown
                                                noResultsMessage="Нічого не знайдено"
                                                className='circular'
                                                selection
                                                fluid
                                                name='currentClientId'
                                                value={this.props.currentClientId}
                                                onChange={this.handleChange}
                                                options={sortClients(this.props.clients).map(c => ({ value: c.id, text: c.name }))}
                                            />
                                        </>
                                        : null
                                    }
                                </Grid.Column>
                                <Grid.Column className='filter-col' width={3}>
                                    <h4>Регіон відвантаження</h4>
                                    <Dropdown fluid search selection className='circular'
                                        noResultsMessage='Нічого не знайдено' name='currentShippingRegionId'
                                        value={this.props.currentShippingRegionId} onChange={this.handleChange}
                                        options={this.props.regions.map(reg => {
                                            return {
                                                value: reg.id,
                                                text: reg.name
                                            };
                                        })}
                                    />
                                </Grid.Column>
                                <Grid.Column className='filter-col' width={3}>
                                    <h4>Тип вантажу</h4>
                                    <Dropdown fluid selection className='circular' value={this.props.currentProductType}
                                        noResultsMessage="Нічого не знайдено"
                                        name='currentProductType' onChange={this.handleChange}
                                        options={this.props.productTypes.map((productType, index) => {
                                            return {
                                                key: index,
                                                value: productType,
                                                text: productType.replace('Non-Food', 'Non-Food/Food')
                                            };
                                        }
                                        )}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={4} />
                                <Grid.Column width={3} />
                                <Grid.Column className='filter-col' width={6}>
                                    <h4>Фільтр РЦ</h4>
                                    <Dropdown placeholder='Введіть РЦ'
                                        fluid multiple search selection
                                        className='circular' value={this.props.currentDistributionCenterIds}
                                        noResultsMessage='Нічого не знайдено'
                                        name='currentDistributionCenterIds' onChange={this.handleChange}
                                        options={this.props.distributionCenters.map((row) => {
                                            return { key: row.id, text: row.name, value: row.id, selected: false };
                                        })}
                                    />
                                </Grid.Column>
                                <Grid.Column className='filter-col' width={3}>
                                    <h4>Фільтр ТК</h4>
                                    <Dropdown fluid selection className='circular' value={this.props.currentTransporterId}
                                        noResultsMessage="Нічого не знайдено"
                                        name='currentTransporterId' onChange={this.handleChange}
                                        options={this.props.transporters.map(t => {
                                            return {
                                                value: t.id,
                                                text: t.name
                                            };
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                )}
            </UserData.Consumer>
        );
    }
};